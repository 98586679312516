import { uploadButtonLabel } from '@constants'
import { Box, Button, Typography, styled } from '@mui/joy'
import { colors, globalStyles } from '@styles/styles'
import React from 'react'

interface Props {
  onChange: (image: File[] | null) => void
  multiple?: boolean
  disabled?: boolean
  acceptedTypes: string
}

const styles = {
  dropArea: {
    color: globalStyles.secondaryTextColor,
    border: globalStyles.dashedBorder,
    borderRadius: 8,
    p: 3,
    textAlign: 'center',
    my: '0.5rem',
    text: { color: colors.secondaryText },
  },
  button: { mt: 1 },
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`

const FileUploadInput = ({ onChange, acceptedTypes, multiple, disabled }: Props) => {
  const handleFileUpload = (e: any) => {
    const files = e?.target?.files || null
    onChange(files && files.length ? Array.from(files as File[]) : null)
  }
  return (
    <>
      <Box
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault()
          handleFileUpload({ target: { files: Array.from(e.dataTransfer.items).map((item) => item.getAsFile()) } })
        }}
        sx={styles.dropArea}
      >
        <Typography sx={styles.dropArea.text}>Arrastre los archivos aquí</Typography>
        <Typography sx={styles.dropArea.text}>o</Typography>
        <UploadButton
          handleFileUpload={handleFileUpload}
          acceptedTypes={acceptedTypes}
          multiple={multiple}
          disabled={disabled}
        />
      </Box>
    </>
  )
}

interface UploadButtonProps {
  handleFileUpload: (e: any) => void
  acceptedTypes: string
  multiple?: boolean
  disabled?: boolean
}

const UploadButton = ({ handleFileUpload, acceptedTypes, multiple, disabled }: UploadButtonProps) => (
  <Button
    component="label"
    tabIndex={-1}
    sx={styles.button}
    disabled={disabled}
  >
    {uploadButtonLabel}
    <VisuallyHiddenInput
      type="file"
      onChange={handleFileUpload}
      accept={acceptedTypes}
      multiple={multiple}
    />
  </Button>
)

export default FileUploadInput
