import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import FormControlStyled from './FormControlStyled'

interface ModalProps {
  header: string
  body: string
  show: boolean
  onAccept: (title?: string) => void | string
  error?: string
}

interface Props {
  modal: ModalProps
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  defaultInputValue?: string
}

const InputModal = ({ modal, setModal, defaultInputValue = '' }: Props) => {
  const [inputText, setInputText] = useState(defaultInputValue)
  useEffect(() => {
    if (modal.show) setInputText(defaultInputValue)
  }, [modal.show])

  return (
    <Modal
      show={modal.show}
      onHide={() => setModal((prevValues) => ({ ...prevValues, show: false }))}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{modal.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Text>{modal.body}</Form.Text>
            <FormControlStyled
              onKeyDown={(e) =>
                !inputText
                  ? null
                  : e.key === 'Enter' &&
                    (() => {
                      if (modal.onAccept(inputText) === 'doNotClose') return
                      setInputText(() => '')
                      setModal((prevValues) => ({ ...prevValues, show: false }))
                    })()
              }
              value={inputText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)}
            />
            {Boolean(modal.error) && <Form.Text className="text-danger">{modal.error}</Form.Text>}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setInputText(() => '')
            setModal((prevValues) => ({ ...prevValues, show: false }))
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (modal.onAccept(inputText) === 'doNotClose') return
            setInputText(() => '')
            setModal((prevValues) => ({ ...prevValues, show: false }))
          }}
          variant="primary"
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default InputModal
