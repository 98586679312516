import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { UserContext } from '@components/Auth'
import { useContext } from 'react'
import SpinnerWrapper from '@components/SpinnerWrapper'
import { User } from '@types'
import { auth } from '@firebaseUtils/firebase'
import { useUpdateUserMutation } from '@endpoints/usersEndpoint'
import { AlertContext } from '@components/AlertContextWrapper'
import { navigate } from 'gatsby'
import { useUploadFileMutation } from '@apis/mediacoreApi'
import { updateProfile } from 'firebase/auth'
import ChangePassword from './ChangePassword'
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from '@mui/joy'
import { EditOutlined, FitScreenOutlined } from '@mui/icons-material'
import { globalStyles } from '@styles/styles'
import { useAppSelector } from '@redux/reduxHooks'
import { StaticImage } from 'gatsby-plugin-image'

const baseIconStyle = {
  position: 'absolute',
  p: 0.5,
  m: 1.5,
  borderRadius: '50%',
  bottom: 0,
}

const styles = {
  imgPlaceholder: {
    borderRadius: '50%',
    height: '175px',
    minHeight: '175px',
    width: '175px',
    minWidth: ' 175px',
    position: 'relative',
    mx: 'auto',
  },
  fitScreenIcon: {
    ...baseIconStyle,
    left: 0,
  },
  uploadImageIcon: {
    ...baseIconStyle,
    right: 0,
  },
  img: {
    borderRadius: '50%',
    minWidth: '175px',
    minHeight: '175px',
    maxWidth: '175px',
    maxHeight: '175px',
    fontSize: '52px',
  },
  propagateWaitCursor: {
    cursor: 'wait',
    '& > *': {
      cursor: 'wait',
      '& > *': {
        cursor: 'wait',
      },
    },
  },
}

const MyProfile = () => {
  const { userData, getByUid } = useContext(UserContext)
  const { toast } = useContext(AlertContext)
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation()
  const [user, setUser] = useState<Partial<User>>({})
  const [avatar, setAvatar] = useState<File>()
  const userState = useAppSelector((state) => state.user)

  useEffect(() => {
    if (userData) setUser(userData.data)
  }, [userData])

  const [uploadFile, { isLoading: isLoadingFile }] = useUploadFileMutation()

  const textInput = (
    name: keyof Omit<
      User,
      'is_active' | 'is_staff' | 'is_superuser' | 'role' | 'clients' | 'permissions' | 'allowed_clients'
    >,
    label?: string,
    disabled?: boolean,
  ) => (
    <>
      <FormLabel>{label ?? ''}</FormLabel>
      <Input
        disabled={disabled}
        name={name}
        value={user?.[name] ?? ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          e.persist()
          setUser((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
          }))
        }}
        sx={{ mb: 1 }}
      />
    </>
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const isBusy = isUpdating || isLoadingFile
  const handleUpdateUser = () => {
    toast
      .promise(
        updateUser({
          id: userData?.data.id,
          data: { first_name: user.first_name, last_name: user.last_name, avatar_fit: user.avatar_fit ?? 'contain' },
        }).unwrap(),
        {
          pending: `Guardando perfil...`,
          success: `Perfil guardado con éxito`,
          error: `No se pudo guardar el perfil`,
        },
      )
      .then(() => {
        const uid = auth.currentUser?.uid ?? ''
        getByUid({ uid })
        navigate('/')
      })
      .catch(() => {})
  }

  return (
    <SpinnerWrapper
      error={userData?.isError}
      errorMessage="Error cargando datos del usuario"
    >
      <Container sx={isBusy ? styles.propagateWaitCursor : {}}>
        <fieldset disabled={isBusy}>
          <Box
            my={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={styles.imgPlaceholder}
          >
            <input
              type="file"
              ref={inputRef}
              hidden
              accept=".jpg,.png"
              onChange={(e) => {
                e.persist()
                if (e.target.files?.[0]) setAvatar(e.target.files[0])
              }}
            ></input>
            {avatar ? (
              <img
                style={{ ...styles.img, objectFit: user?.avatar_fit ?? 'contain' }}
                src={URL.createObjectURL(avatar)}
              />
            ) : auth.currentUser?.photoURL ? (
              <img
                style={{ ...styles.img, objectFit: user?.avatar_fit ?? 'contain' }}
                src={auth.currentUser?.photoURL}
              />
            ) : (
              <Avatar
                sx={{
                  ...styles.img,
                  color: 'white',
                  background: userState?.gradientComponents
                    ? globalStyles.gradientColor()
                    : 'var(--joy-palette-primary-solidBg)',
                }}
              >
                {user?.first_name || user?.last_name ? (
                  `${user?.first_name?.[0]?.toUpperCase() ?? ''}${user?.last_name?.[0]?.toUpperCase() ?? ''}`
                ) : (
                  <StaticImage
                    src="../images/avatar_default.png"
                    alt=""
                    style={styles.img}
                  />
                )}
              </Avatar>
            )}
            <Tooltip title="Ajustar">
              <IconButton
                variant="soft"
                onClick={() =>
                  setUser((prevData) => ({
                    ...prevData,
                    avatar_fit: prevData.avatar_fit === 'contain' ? 'cover' : 'contain',
                  }))
                }
                sx={styles.fitScreenIcon}
              >
                <FitScreenOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Subir imagen">
              <IconButton
                variant="soft"
                onClick={() => inputRef.current?.click()}
                sx={styles.uploadImageIcon}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container>
            <Grid
              md={12}
              sx={{ px: 3 }}
            >
              <Divider sx={{ mb: 3 }} />
              <Typography
                level="h3"
                sx={{ mb: 1 }}
              >
                Información personal
              </Typography>
              {textInput('first_name', 'Nombre')}
              {textInput('last_name', 'Apellido')}
              {textInput('email', 'Email', true)}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ px: 3, justifyContent: 'flex-end' }}
          >
            <Grid>
              <Button
                disabled={isUpdating}
                onClick={() => {
                  avatar
                    ? toast
                        .promise(uploadFile({ data: { file: avatar! } }).unwrap(), {
                          pending: `Subiendo imagen...`,
                          success: `Imagen subida con éxito`,
                          error: `No se pudo subir la imagen`,
                        })
                        .then((uploadedAvatar: { url: string }) => {
                          toast
                            .promise(updateProfile(auth.currentUser!, { photoURL: uploadedAvatar.url }), {
                              pending: `Guardando avatar...`,
                              success: `Avatar guardado con éxito`,
                              error: `No se pudo guardar el avatar`,
                            })
                            .then(() => {
                              handleUpdateUser()
                            })
                            .catch((e: any) => console.log(e))
                        })
                        .catch((e: any) => console.log(e))
                    : handleUpdateUser()
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
          <ChangePassword />
        </fieldset>
      </Container>
    </SpinnerWrapper>
  )
}

export default MyProfile
