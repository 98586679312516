import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getByIdAndClientIdQuery,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { SupportTicket } from '@types'

const supportTicketsEndpointURL = 'core/soporte'

const supportTicketsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupportTicketById: builder.query<SupportTicket, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(supportTicketsEndpointURL),
      providesTags: (_result, _error, { id }) => [{ type: 'SupportTicket', id }],
    }),
    deleteSupportTicket: builder.mutation<SupportTicket, { id: number; clientId: number }>({
      query: deleteByIdMutation(supportTicketsEndpointURL),
      invalidatesTags: ['TicketTable'],
    }),
    createSupportTicket: builder.mutation<SupportTicket, { data: Partial<SupportTicket> }>({
      query: createOneMutation(supportTicketsEndpointURL),
      invalidatesTags: ['TicketTable'],
    }),
    updateSupportTicket: builder.mutation<SupportTicket, { id: number; data: Partial<SupportTicket> }>({
      query: updateByIdMutation(supportTicketsEndpointURL),
      invalidatesTags: ['TicketTable', 'SupportTicket'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSupportTicketByIdQuery,
  useDeleteGroupMutation,
  useCreateSupportTicketMutation,
  useUpdateSupportTicketMutation,
} = supportTicketsApi
