import {
  OwnerNews,
  Report,
  Property,
  SiteDevelopment,
  PropertiesGroup,
  Grouping,
  ActiveAction,
} from '@types'
import { Id, toast } from 'react-toastify'

export const hexColorToHSL = (H: string) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0
  if (H.length == 4) {
    r = Number('0x' + H[1] + H[1])
    g = Number('0x' + H[2] + H[2])
    b = Number('0x' + H[3] + H[3])
  } else if (H.length == 7) {
    r = Number('0x' + H[1] + H[2])
    g = Number('0x' + H[3] + H[4])
    b = Number('0x' + H[5] + H[6])
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0

  if (delta == 0) h = 0
  else if (cmax == r) h = ((g - b) / delta) % 6
  else if (cmax == g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)
  return { h, s, l, r: r * 255, g: g * 255, b: b * 255 }
}

export const hexColorToRGB = (H: string) => {
  let r = 0,
    g = 0,
    b = 0
  if (H.length == 4) {
    r = Number('0x' + H[1] + H[1])
    g = Number('0x' + H[2] + H[2])
    b = Number('0x' + H[3] + H[3])
  } else if (H.length == 7) {
    r = Number('0x' + H[1] + H[2])
    g = Number('0x' + H[3] + H[4])
    b = Number('0x' + H[5] + H[6])
  }
  return { r, g, b }
}

export const getLuminance = (hexColor: string) =>
  (299 * hexColorToRGB(hexColor).r + 587 * hexColorToRGB(hexColor).g + 114 * hexColorToRGB(hexColor).b) / 1000

export const isContentEmpty = (content: string) => {
  if (typeof content === 'undefined') {
    return true
  }
  const sanitizedContent = content
    ?.trim()
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\s/g, '')
  return sanitizedContent.length === 0
}

export const reportCompletedPercentage = (report: Report) => {
  const isStepVisualized = (report: Report, step: number) => !!report.content.visualizedSteps?.includes(step)
  const stepsCompleted = (report: Report) => {
    const {
      title,
      tokko_property,
      content: {},
    } = report
    return [
      isStepVisualized(report, 0) && !!tokko_property && !!title,
      isStepVisualized(report, 1),
      isStepVisualized(report, 2),
      isStepVisualized(report, 3),
      isStepVisualized(report, 4),
    ]
  }
  return Math.round((stepsCompleted(report).filter((step) => step).length / stepsCompleted(report).length) * 100)
}

export const ownerNewsCompletedPercentage = (ownerNews: OwnerNews) => {
  const isStepVisualized = (ownerNews: OwnerNews, step: number) =>
    !!ownerNews.extra_info.visualizedSteps?.includes(step)
  const stepsCompleted = (ownerNews: OwnerNews) => {
    const {
      title,
      tokko_property,
      available_to_all,
      headline,
      extra_info: {},
    } = ownerNews
    return [
      isStepVisualized(ownerNews, 0) && !!title && (!!tokko_property?.length || !!available_to_all),
      isStepVisualized(ownerNews, 1) && !!headline,
      isStepVisualized(ownerNews, 2),
    ]
  }
  return Math.round((stepsCompleted(ownerNews).filter((step) => step).length / stepsCompleted(ownerNews).length) * 100)
}

export const realAddressComponents = (real_address?: string) => {
  if (!real_address?.length) return undefined
  const parsedAddress = real_address?.split('-').map((component) => component.trim())
  return {
    address: parsedAddress[parsedAddress.length - 1],
    floor: parsedAddress.length > 1 ? parsedAddress[0] : '',
    apartment: parsedAddress.length > 2 ? parsedAddress[1] : '',
  }
}

export const pendingToast = (t: typeof toast, message: string) => {
  return t.loading(message)
}

export const updateSuccessToast = (t: typeof toast, toastId: Id, message: string) => {
  t.update(toastId, {
    render: message,
    type: toast.TYPE.SUCCESS,
    isLoading: false,
    autoClose: 3000,
  })
}

export const updateErrorToast = (t: typeof toast, toastId: Id, message: string) => {
  t.update(toastId, {
    render: message,
    type: toast.TYPE.ERROR,
    isLoading: false,
    autoClose: 3000,
  })
}

export const onlyUnique = (value: any, index: number, array: Array<any>) => array.indexOf(value) === index

export const propertyCompletedPercentage = (property: Property) => {
  const isStepVisualized = (property: Property, step: number) => !!property.extra_info?.visualizedSteps?.includes(step)
  const stepsCompleted = (property: Property) => {
    return [
      isStepVisualized(property, 0) && !!property.operations?.length && !!property?.property_type?.length,
      isStepVisualized(property, 1) && !isContentEmpty(property.description ?? ''),
      isStepVisualized(property, 2) &&
        !!property?.address &&
        !!property?.city &&
        !!property?.state &&
        !!property?.country,
      isStepVisualized(property, 3),
      isStepVisualized(property, 4),
      isStepVisualized(property, 5) && !!property?.images_data?.length,
      isStepVisualized(property, 6),
      isStepVisualized(property, 7),
      isStepVisualized(property, 8) &&
        (property?.operations?.includes('Venta')
          ? Number(property?.valuation_price) > 0 && !!property?.valuation_currency
          : true) &&
        (property?.operations?.includes('Alquiler')
          ? Number(property?.valuation_price_rent) > 0 &&
            !!property?.valuation_period_rent &&
            !!property?.valuation_currency_rent
          : true) &&
        (property?.operations?.includes('Temporario')
          ? Number(property?.valuation_price_temporary_rent) > 0 &&
            !!property?.valuation_period_temporary_rent &&
            !!property?.valuation_currency_temporary_rent
          : true),
      isStepVisualized(property, 9),
    ]
  }
  return Math.round((stepsCompleted(property).filter((step) => step).length / stepsCompleted(property).length) * 100)
}

export const developmentCompletedPercentage = (development: SiteDevelopment) => {
  const isStepVisualized = (development: SiteDevelopment, step: number) =>
    !!development.extra_info.visualizedSteps?.includes(step)
  const stepsCompleted = (development: SiteDevelopment) => {
    return [
      isStepVisualized(development, 0) &&
        !!development.name &&
        !!development?.property_type?.length &&
        !!development.construction_status,
      isStepVisualized(development, 1) && !isContentEmpty(development.description ?? ''),
      isStepVisualized(development, 2) &&
        !!development?.address &&
        !!development?.city &&
        !!development?.state &&
        !!development?.country,
      isStepVisualized(development, 3),
      isStepVisualized(development, 4) && !!development?.images_data.length,
      isStepVisualized(development, 5),
      isStepVisualized(development, 6),
      isStepVisualized(development, 7),
      isStepVisualized(development, 8),
    ]
  }
  return Math.round(
    (stepsCompleted(development).filter((step) => step).length / stepsCompleted(development).length) * 100,
  )
}

export const groupCompletedPercentage = (group: PropertiesGroup) => {
  const isStepVisualized = (group: PropertiesGroup, step: number) => !!group.extra_info.visualizedSteps?.includes(step)
  const stepsCompleted = (group: PropertiesGroup) => {
    return [
      isStepVisualized(group, 0) &&
        !!group.name &&
        (!!group.image || !!group.images_data?.length) &&
        (!group.enable_location ||
          (!!group.enable_location && !!group?.address && !!group?.city && !!group?.state && !!group?.country)),
      isStepVisualized(group, 1),
      isStepVisualized(group, 2),
    ]
  }
  return Math.round((stepsCompleted(group).filter((step) => step).length / stepsCompleted(group).length) * 100)
}

export const groupingCompletedPercentage = (grouping: Grouping) => {
  const isStepVisualized = (grouping: Grouping, step: number) => !!grouping.extra_info.visualizedSteps?.includes(step)
  const stepsCompleted = (grouping: Grouping) => {
    return [
      isStepVisualized(grouping, 0) &&
        !!grouping.name &&
        !!grouping.image &&
        (!grouping.enable_location ||
          (!!grouping.enable_location &&
            !!grouping?.address &&
            !!grouping?.city &&
            !!grouping?.state &&
            !!grouping?.country)),
      isStepVisualized(grouping, 1),
    ]
  }
  return Math.round((stepsCompleted(grouping).filter((step) => step).length / stepsCompleted(grouping).length) * 100)
}

export const removeKeyFromArray = (objectArray: any[], key: string) => {
  return objectArray.map((object) => {
    let newObject = { ...object }
    delete newObject[key as keyof typeof newObject]
    return newObject
  })
}

export const cleanString = (value: string) => {
  return value.normalize('NFC').replace(/[^\w\sÑñáéíóúÁÉÍÓÚüÜ]/g, '')
}

export const filteredActions = (
  active_units: 'active_properties' | 'active_developments',
  filterField: 'properties' | 'developments',
  availableToAll: 'isAvailableToAllProperties' | 'isAvailableToAllDevelopments',
  result: ActiveAction,
) => {
  if (result?.actions) {
    return result[active_units].length > 0
      ? result?.actions
          .filter((action) => action[filterField].length > 0)
          .map((action) => action.name)
          .join(',')
      : result.actions.some((action) => action[availableToAll] === true)
      ? result.actions
          .filter((action) => action[availableToAll] === true)
          .map((action) => action.name)
          .join(',')
      : result.actions.map((action) => action.name).join(',')
  }
}

export const reOrder = (array: Array<any>, from: number, to: number) => array.splice(to, 0, array.splice(from, 1)[0])

export const isValidURI = (uri: string) => {
  try {
    new URL(uri)
    return true
  } catch (error) {
    return false
  }
}

export const isValidUUID = (uuid: string) => {
  const uuidExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return uuidExp.test(uuid)
}

export const getAspectRatio = (image: File | string) => {
  return new Promise<string>((resolve, reject) => {
    const imgSrc = typeof image === 'string' ? image : URL.createObjectURL(image)
    const img = new Image()
    img.src = imgSrc
    img.onload = () => {
      const greatestCommonDivisor = (a: number, b: number): number => (b ? greatestCommonDivisor(b, a % b) : a)
      resolve(
        `${img.width / greatestCommonDivisor(img.width, img.height)}:${
          img.height / greatestCommonDivisor(img.width, img.height)
        }`,
      )
      URL.revokeObjectURL(img.src)
    }
    img.onerror = () => {
      reject('Error loading image')
    }
  })
}

export const getVideoAspectRatio = (video: File | string) => {
  return new Promise<string>((resolve, reject) => {
    const videoSrc = typeof video === 'string' ? video : URL.createObjectURL(video)
    const videoElement = document.createElement('video')
    videoElement.src = videoSrc
    videoElement.onloadedmetadata = () => {
      const greatestCommonDivisor = (a: number, b: number): number => (b ? greatestCommonDivisor(b, a % b) : a)
      resolve(
        `${videoElement.videoWidth / greatestCommonDivisor(videoElement.videoWidth, videoElement.videoHeight)}:${
          videoElement.videoHeight / greatestCommonDivisor(videoElement.videoWidth, videoElement.videoHeight)
        }`,
      )
      URL.revokeObjectURL(videoElement.src)
    }
    videoElement.onerror = () => {
      reject('Error loading video')
    }
  })
}

export const getNumericAspectRatio = (aspectRatio?: string) => {
  if (!aspectRatio?.match(/^\d+(:|\/)\d+$/)) return 1
  const [width, height] = aspectRatio.replace('/', ':').split(':').map(Number)
  if (!width || !height) return 1
  return width / height
}

export const isValidAspectRatio = ({
  aspectRatio,
  validAspectRatio,
  tolerance = 0.2,
}: {
  aspectRatio: string
  validAspectRatio: string
  tolerance?: number
}) => {
  const validAspectRatioExp = /^\d+(:|\/)\d+$/
  if (!aspectRatio.match(validAspectRatioExp) || !validAspectRatio.match(validAspectRatioExp)) return false
  const [width, height] = aspectRatio.replace('/', ':').split(':').map(Number)
  const [validWidth, validHeight] = validAspectRatio.replace('/', ':').split(':').map(Number)
  if (!width || !height || !validWidth || !validHeight) return false
  const validRatio = validWidth / validHeight
  const ratio = width / height
  return ratio < validRatio * (1 + tolerance) && ratio > validRatio * (1 - tolerance)
}

export const sizeInMB = (size: number, digits?: number) => {
  return (size / (1024 * 1024)).toFixed(digits ?? 2)
}
