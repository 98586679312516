import React from 'react'
import Button, { ButtonTypeMap, ExtendButton } from '@mui/joy/Button'
import { Add } from '@mui/icons-material'

export const AddNewButton: ExtendButton<ButtonTypeMap<{}, 'button'>> = ({ ...rest }) => {
  return (
    <Button
      endDecorator={<Add fontSize="small" />}
      {...rest}
    >
      Nuevo
    </Button>
  )
}
