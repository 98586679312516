import CircularProgress from '@mui/joy/CircularProgress'
import React from 'react'

interface Props {
  size?: 'sm'
}

const SpinnerCenter = ({
  size,
  className,
  ...rest
}: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className={className}
    >
      <CircularProgress size={size} />
    </div>
  )
}

export default SpinnerCenter
