import * as React from 'react'
import TopAndBottomNavBar from '@components/TopAndBottomNavBar'
import SideNavBar from '@components/SideNavBar'
import type { PageProps } from 'gatsby'
import { useState } from 'react'
import { MainSpinner } from '@components/MainSpinner'
import { useSession } from '@hooks/useSession'

const NavBarsWrapper = ({ children }: PageProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { user } = useSession()

  return (
    <>
      <MainSpinner show={!user} />
      <TopAndBottomNavBar setShowMobileMenu={setShowMobileMenu} />
      <SideNavBar
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
      {children}
    </>
  )
}

export default NavBarsWrapper
