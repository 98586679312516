import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface ModalProps {
  header: string
  body: React.ReactNode
  show: boolean
  onAccept: () => void
  onCancel?: () => void
}

interface Props {
  modal: ModalProps
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  acceptText?: string
  loading?: boolean
  cancelText?: string
}

const ConfirmationModal = ({ modal, setModal, acceptText = 'Aceptar', cancelText = 'Cancelar', loading }: Props) => {
  return (
    <Modal
      show={modal.show}
      onHide={() => setModal((prevState) => ({ ...prevState, show: false }))}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{modal.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.body}</Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loading}
          variant="secondary"
          onClick={() => {
            setModal((prevState) => ({ ...prevState, show: false }))
            if (modal.onCancel) modal.onCancel()
          }}
        >
          {cancelText}
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            setModal((prevState) => ({ ...prevState, show: false }))
            modal.onAccept()
          }}
          variant="primary"
        >
          {acceptText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
