import { Tenant } from '@types';
import React, { createContext, useState } from 'react'

interface Props {
    children: JSX.Element;
}

export const ClientContext = createContext<any>({ selectedClient: '', setSelectedClient: () => null })

const ClientContextWrapper = ({ children }: Props) => {

    const [selectedClient, setSelectedClient] = useState<number | string>('')
    const [selectedClientDetails, setSelectedClientDetails] = useState<Tenant | null>(null)

    return (
        <ClientContext.Provider value={{ selectedClient, setSelectedClient, selectedClientDetails, setSelectedClientDetails }}>
            {children}
        </ClientContext.Provider>
    )
}

export default ClientContextWrapper