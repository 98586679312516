import React, { useState } from 'react'
import { Button, List, ListItem, Typography } from '@mui/joy'
import { Link } from 'gatsby'
import { SxProps } from '@mui/material'

interface Props {
  label: string
  items: { label: string; path: string; onClick?: () => void }[]
  icon: React.ReactNode
  menuIconsStyle?: SxProps
}

const DropdownMenuItem = ({ label, items, icon, menuIconsStyle }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        variant="plain"
        color="neutral"
        sx={menuIconsStyle}
        onClick={() => setOpen(!open)}
      >
        <Typography
          startDecorator={icon}
          level="title-lg-light"
        >
          {label}
        </Typography>
      </Button>
      {open && (
        <List>
          {items.map((item, index) => (
            <ListItem key={index}>
              <Link
                to={item.path}
                style={{ cursor: 'pointer', textDecoration: 'none', display: 'block' }}
                onClick={item.onClick}
              >
                <Typography
                  level="body-md-light"
                  color="neutral"
                  sx={{ ml: 3 }}
                >
                  {item.label}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}

export default DropdownMenuItem
