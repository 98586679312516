import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { Report } from '@types'

const reportsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<Report[], { clientId: number }>({
      query: getAllByClientIdQuery('owner/reportes'),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Report' as const, id })), 'Report'] : ['Report'],
    }),
    getReportById: builder.query<Report, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('owner/reportes'),
      providesTags: (_result, _error, { id }) => [{ type: 'Report', id }],
    }),
    deleteReport: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation('owner/reportes'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted({ id, clientId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            reportsApi.util.updateQueryData('getReports', { clientId }, (draftReports) => {
              return draftReports.filter((draftReport) => draftReport.id !== id)
            }),
          )
        } catch {}
      },
    }),
    createReport: builder.mutation<Report, { data: Partial<Report> }>({
      query: createOneMutation('owner/reportes'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdReport } = await queryFulfilled
          dispatch(
            reportsApi.util.updateQueryData('getReports', { clientId: createdReport.client }, (draftReports) => {
              draftReports.unshift(createdReport)
              return draftReports
            }),
          )
        } catch {}
      },
    }),
    updateReport: builder.mutation<Report, { id: number; data: Partial<Report> }>({
      query: updateByIdMutation('owner/reportes'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedReport } = await queryFulfilled
          dispatch(
            reportsApi.util.updateQueryData('getReportById', { id, clientId: updatedReport.client }, (draft) => {
              Object.assign(draft, updatedReport)
            }),
          )
          dispatch(
            reportsApi.util.updateQueryData('getReports', { clientId: updatedReport.client }, (draftReports) => {
              return draftReports.map((draftReport) => (draftReport.id !== id ? draftReport : updatedReport))
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetReportsQuery,
  useGetReportByIdQuery,
  useDeleteReportMutation,
  useCreateReportMutation,
  useUpdateReportMutation,
  usePrefetch: usePrefetchReports,
} = reportsApi
