import React from 'react'
import { pendingToast, updateErrorToast, updateSuccessToast } from '@utils'
import { toast } from 'react-toastify'
import { useUpdateSupportTicketMutation } from '@endpoints/supportTicketsEndpoint'
import { DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy'
import { Rating } from '@mui/material'

interface ModalProps {
  show: boolean
  title: string
}
interface Props {
  modal: ModalProps
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  value: number
  handleRating: (rating: number) => void
  ticketId: number
}
const RatingModal = ({ modal, setModal, value, handleRating, ticketId }: Props) => {
  const [updateSupportTicket] = useUpdateSupportTicketMutation()
  return (
    <>
      <Modal
        open={modal.show}
        onClose={() => setModal((prevState) => ({ ...prevState, show: false }))}
      >
        <ModalDialog>
          <ModalClose />
          <DialogTitle sx={{ px: 3 }}>{modal.title}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Rating
              sx={{ fontSize: '4rem', my: 1 }}
              value={value}
              onChange={(_e, rating) => {
                handleRating(rating ?? 0)
                setTimeout(() => {
                  setModal((prevState) => ({ ...prevState, show: false }))
                }, 500)
                const toastId = pendingToast(toast, 'Guardando calificación...')
                updateSupportTicket({ id: ticketId, data: { rating: Number(rating) } })
                  .unwrap()
                  .then(() => updateSuccessToast(toast, toastId, 'Calificación guardada con éxito'))
                  .catch((error) => {
                    updateErrorToast(
                      toast,
                      toastId,
                      error.status === 403 ? error?.data?.detail : `No se pudo guardar la calificación`,
                    )
                  })
              }}
            />
            <Typography
              level="body-md-light"
              color="neutral"
            >
              ¡Gracias! tu calificación nos sirve para mejorar y darte un mejor servicio.
            </Typography>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default RatingModal
