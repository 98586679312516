import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './src/styles/styles.scss'
import '@fontsource/inter'
import { wrapRootElement as wrapRootElementBrowser } from './src/wrapElements'
import { wrapPageElement as wrapPageElementBrowser} from './src/wrapElements'

export const wrapRootElement = wrapRootElementBrowser
export const wrapPageElement = wrapPageElementBrowser

// export const onInitialClientRender = () => {
//   window.addEventListener('popstate', () => (window.location.href = window.location.href))
// }
