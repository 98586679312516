import { useGetHasPermissionQuery } from '@apis/mediacoreApi'
import { UserContext } from '@components/Auth'
import ConfirmationModal from '@components/ConfirmationModal'
import FileUploadInput from '@components/FileUploadInput'
import InformationModal from '@components/InformationModal'
import InputModal from '@components/InputModal'
import RatingModal from '@components/RatingModal'
import SpinnerCenter from '@components/SpinnerCenter'
import SpinnerWrapper from '@components/SpinnerWrapper'
import Stepper, { StepperAccordion } from '@components/Stepper'
import {
  chartsColors,
  support_ticket_areas,
  support_ticket_priority_colors,
  support_ticket_status_colors,
} from '@constants'
import {
  useCreateTextTemplateMutation,
  useDeleteTextTemplateMutation,
  useGetTextTemplatesQuery,
  useUpdateTextTemplateMutation,
} from '@endpoints/textTemplatesEndpoint'
import { auth } from '@firebaseUtils/firebase'
// import { useGetSupportTicketByIdQuery } from '@endpoints/supportTicketsEndpoint'
import { useSupportTicket } from '@hooks/useSupportTicket'
import {
  Circle,
  DeleteOutline,
  Download,
  DriveFileRenameOutline,
  Flag,
  InfoOutlined,
  KeyboardArrowDown,
  OpenWithOutlined,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  DialogTitle,
  Divider,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Stack,
  Switch,
  Textarea,
  Tooltip,
  Typography,
  accordionClasses,
} from '@mui/joy'
import { IconButton, Rating } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { colors, globalStyles } from '@styles/styles'
import { ConfirmationModalProps, SupportMessage, SupportTicket, Tenant, TextTemplate, User } from '@types'
import moment from 'moment'
import 'moment/locale/es'
import React, { ChangeEvent, useContext, useState } from 'react'
import ImageGallery from 'react-image-gallery'

export interface Props {
  id?: number
  clientId: number
  onClose?: () => void
}
export interface InputModal {
  header: string
  body: string
  show: boolean
  onAccept: (title?: string) => void | string
  error?: string
}
const borderColor = 'var(--joy-palette-primary-plainColor)'

const styles = {
  accordionGroup: {
    [`& .${accordionClasses.root}`]: {
      paddingY: 0.5,
    },
    [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
      border: globalStyles.border,
      borderRadius: globalStyles.card.borderRadius,
    },
  },
  divider: {
    '--Divider-childPosition': `3%`,
    color: borderColor,
    '&::before, &::after': {
      background: borderColor,
    },
  },
  accordionItemContent: { padding: 3 },
  accordionDetailsContent: { paddingInline: 0, paddingTop: 3 },
  sizeAlertModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    container: {
      maxWidth: 500,
      borderRadius: 'md',
      p: 3,
      boxShadow: 'lg',
    },
    closeButton: { mt: 3, float: 'right' },
  },
  deleteImageIcon: {
    position: 'absolute',
    top: '5px',
    right: '6px',
    cursor: 'pointer',
    color: colors.lightGrey,
    ':hover': {
      color: colors.blue,
    },
  },
  grabbingIcon: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    cursor: 'grabbing',
    color: colors.lightGrey,
    ':hover': {
      color: colors.blue,
    },
  },
  filesBox: {
    border: globalStyles.border,
    borderRadius: '8px',
    p: 1,
    width: 'fit-content',
    deleteFileIcon: {
      verticalAlign: 'top',
      marginLeft: '0.5rem',
      cursor: 'pointer',
      color: colors.lightGrey,
      ':hover': {
        color: colors.blue,
      },
    },
  },
  formContainer: {
    px: 3,
    overflowY: 'auto',
    height: `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
  },
  title: {
    paddingX: 3,
    height: globalStyles.navBarHeight,
    borderBottom: globalStyles.border,
  },
  modalClose: {
    position: 'relative',
    right: 0,
    top: 0,
  },
  stepNavigation: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    px: 3,
    pb: 2,
    pt: 1,
    height: globalStyles.navBarHeight,
    borderTop: globalStyles.border,
    textAlign: 'right',
  },
}

const SupportTicketForm = ({ id, clientId, onClose }: Props) => {
  const {
    handleChange,
    stepsCompleted,
    markVisualizedStep,
    supportTicket,
    setSupportTicket,
    saveSupportTicket,
    handleFileInputChange,
    handleMessageContentChange,
    messagesToShow,
    lastMessageId,
    isTicketClosed,
    handleDateChange,
    isSuperAdmin,
    isClientAdmin,
    isBusy,
    isErrorTicket,
    handleChangeGalleryFiles,
    handleReorderGallery,
    handleGalleryAltChange,
    deleteGalleryImage,
    deleteFile,
  } = useSupportTicket({
    id,
    clientId,
  })

  const { userData } = useContext(UserContext)

  const isBrowser = typeof window !== 'undefined'

  const getPriorityColor = (priority: SupportTicket['priority'] | undefined) =>
    priority ? support_ticket_priority_colors[priority] : '#6c757d'

  const getStatusColor = (status: SupportTicket['status'] | undefined) => status && support_ticket_status_colors[status]

  const getResolutionDateColor = (resolution_date: SupportTicket['resolution_date'] | undefined) =>
    moment(resolution_date).isBefore(moment(), 'day') ? '#FF0000' : '#000000'

  const [editDate, setEditDate] = useState(false)

  const [backModal, setBackModal] = useState<ConfirmationModalProps>({
    header: '',
    body: '',
    show: false,
    onAccept: () => {},
    onCancel: () => {},
  })

  const [filesSizeModal, setFilesSizeModal] = useState<{ header: string; body: string; show: boolean }>({
    header: '',
    body: '',
    show: false,
  })
  const [showSizeAlertModal, setShowSizeAlertModal] = useState(false)
  const [deletedFilesNames, setDeletedFilesNames] = useState<string[]>([])

  const { data: allUsersTextTemplates, isLoading: isLoadingTextTemplates } = useGetTextTemplatesQuery()

  const supportTicketTextTemplates = allUsersTextTemplates?.filter(
    (tt) => tt.user === userData?.data?.id && tt.section === 'support_ticket_message',
  )

  const [textTemplateId, setTextTemplateId] = useState<number>()
  const [textTemplateTitle, setTextTemplateTitle] = useState('')
  const [duplicatedTextTemplate, setDuplicatedTextTemplate] = useState(false)
  const [inputModal, setInputModal] = useState<InputModal>({ header: '', body: '', show: false, onAccept: () => {} })
  const [textTemplateModal, setTextTemplateModal] = useState<boolean>(false)
  const [ratingModal, setRatingModal] = useState<{ title: string; show: boolean }>({
    title: '',
    show: false,
  })

  const [createTextTemplate, { isLoading: isCreatingTextTemplate }] = useCreateTextTemplateMutation()
  const [updateTextTemplate, { isLoading: isUpdatingTextTemplate }] = useUpdateTextTemplateMutation()
  const [deleteTextTemplate, { isLoading: isDeletingTextTemplate }] = useDeleteTextTemplateMutation()

  const isBusyTextTemplate =
    isLoadingTextTemplates || isCreatingTextTemplate || isDeletingTextTemplate || isUpdatingTextTemplate

  const skip = userData.status !== 'fulfilled' || !clientId

  const { data: hasCreatePermission } = useGetHasPermissionQuery(
    {
      client: clientId,
      module: 'CORE',
      method: 'POST',
      endpoint: 'support-tickets',
    },
    {
      skip,
    },
  )

  const { data: hasUpdatePermission } = useGetHasPermissionQuery(
    {
      client: clientId,
      module: 'CORE',
      method: 'PATCH',
      endpoint: 'support-tickets',
    },
    {
      skip,
    },
  )

  const customSelect = (
    label: string,
    options: string[],
    name: keyof Pick<SupportTicket, 'area' | 'priority'>,
    required?: boolean,
  ) => {
    return (
      <>
        <FormLabel sx={{ mb: 0.5, ...(required && globalStyles.requiredFieldLabel) }}>{label}</FormLabel>
        <Select
          onChange={(_e, value) =>
            handleChange({ target: { value: value, name } } as React.ChangeEvent<HTMLInputElement>)
          }
          value={supportTicket[name]}
          startDecorator={
            name === 'priority' && <Flag sx={{ color: getPriorityColor(supportTicket[name]), fontSize: '1rem' }} />
          }
        >
          {options.map((option, index) => (
            <Option
              key={index}
              value={option}
            >
              {name === 'priority' && (
                <ListItemDecorator>
                  <Flag sx={{ color: getPriorityColor(option as SupportTicket['priority']), fontSize: '1rem' }} />
                </ListItemDecorator>
              )}
              {option}
            </Option>
          ))}
        </Select>
      </>
    )
  }

  const images = (
    <>
      <Stack sx={styles.accordionItemContent}>
        <Grid
          container
          spacing={2}
        >
          <Grid xs={6}>
            <FileUploadInput
              onChange={(newFiles) => {
                const filteredFiles = newFiles?.filter((newFile) => isValidFile(newFile, MAX_IMAGE_SIZE))
                const deletedFiles = newFiles
                  ?.filter((newFile) => !isValidFile(newFile, MAX_IMAGE_SIZE))
                  .map((file) => file.name)
                newFiles?.map((newFile) => {
                  if (!isValidFile(newFile, MAX_IMAGE_SIZE)) {
                    setShowSizeAlertModal(true)
                    setDeletedFilesNames(deletedFiles ?? [])
                  }
                })
                handleChangeGalleryFiles(filteredFiles, lastMessageId)
              }}
              multiple
              acceptedTypes=".jpg, .png"
            />
          </Grid>
          <Grid xs={6}>
            <Typography level="body-md-bold">Requerimientos:</Typography>
            <Typography level="body-sm-light">
              Formatos: *.jpg, *.png.
              <br></br>
              Dimensiones de la imagen: 1920x1080px
              <br></br>
              Márgenes seguros: 1536x864px (20% menos que la dimensión original)
              <br></br>
              Modo color: RGB.
              <br></br>
              Peso máximo permitido por imágen: 2MB
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ my: 3 }}
        >
          {supportTicket?.images_data
            ?.filter((file) => file.message_id === lastMessageId)
            ?.map((file, index) => (
              <Grid
                xs={3}
                key={`carouselImagesPreviews-${index}`}
                draggable
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault()
                  e.persist()
                  handleReorderGallery(e, index)
                }}
                onDragStart={(e) => {
                  e.dataTransfer.setData('index', index.toString())
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    backgroundColor: '#d2d2d2',
                    margin: '0.5rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '0.5rem',
                  }}
                >
                  <img
                    draggable={false}
                    src={
                      typeof file.file === 'string'
                        ? file.file
                        : file.file instanceof File
                        ? URL.createObjectURL(file.file)
                        : ''
                    }
                    style={{
                      cursor: 'grabbing',
                      height: '150px',
                      objectFit: 'contain',
                      maxWidth: '100%',
                    }}
                  />
                  <OpenWithOutlined sx={styles.grabbingIcon} />
                  <DeleteOutline
                    onClick={() => deleteGalleryImage(index)}
                    sx={styles.deleteImageIcon}
                  />
                </div>

                <Input
                  name={`altText-${index}`}
                  placeholder="Texto Alt (opcional)"
                  value={supportTicket?.images_data?.[index]?.text_alt ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.persist()
                    handleGalleryAltChange(e, index)
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Stack>
    </>
  )

  const videos = (
    <>
      <Stack sx={styles.accordionItemContent}>
        <Grid
          container
          spacing={2}
        >
          <Grid xs={6}>
            <FileUploadInput
              onChange={(newFiles) => {
                if (!newFiles) return
                if (
                  supportTicket.files_data &&
                  supportTicket?.files_data?.filter((file) => file.message_id === lastMessageId).length +
                    newFiles.length >
                    3
                ) {
                  setFilesSizeModal({
                    show: true,
                    header: 'Error al cargar el archivo',
                    body: 'Solo se permiten hasta 3 archivos.',
                  })
                  return
                }
                const filteredFiles = newFiles?.filter((newFile) => isValidVideoFile(newFile))
                const deletedFiles = newFiles?.filter((newFile) => !isValidVideoFile(newFile)).map((file) => file.name)
                newFiles?.map((newFile) => {
                  if (!isValidVideoFile(newFile)) {
                    setFilesSizeModal({
                      show: true,
                      header: 'Error al cargar el archivo',
                      body: 'Recuerde que los archivos deben cumplir con los requerimientos: Formatos: *.mov, *.avi, *MP4, *WMV. Peso máximo por archivo: 3MB',
                    })
                    setDeletedFilesNames(deletedFiles ?? [])
                  }
                })
                handleFileInputChange(filteredFiles, !id ? 0 : lastMessageId)
              }}
              multiple
              acceptedTypes=".mov, .avi, .MP4, .WMV"
              disabled={
                supportTicket.files_data &&
                supportTicket?.files_data?.filter((file) => file.message_id === lastMessageId)?.length >= 3
              }
            />
            <Stack
              spacing={1}
              sx={{ mt: 1 }}
            >
              {supportTicket?.files_data
                ?.filter((file) => file.message_id === lastMessageId)
                ?.map((fd, index) => (
                  <Box
                    key={index}
                    sx={styles.filesBox}
                  >
                    <Typography
                      level="body-md-light"
                      endDecorator={
                        <DeleteOutline
                          onClick={() => deleteFile(index)}
                          sx={styles.filesBox.deleteFileIcon}
                        />
                      }
                    >
                      {typeof fd.file === 'string'
                        ? fd.file.substring(fd.file.indexOf('news/'), fd.file.length).substring(5)
                        : fd.file_name}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Typography level="body-md-bold">Requerimientos:</Typography>
            <Typography level="body-sm-light">
              Formatos: *.mov, *.avi, *MP4, *WMV., *.pdf.
              <br></br>
              Peso máximo permitido por archivo: 3MB
              <br></br>
              Cantidad de videos: Máximo 3.
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
  const description = () => {
    return (
      <>
        <Grid
          container
          sx={{ mb: 1 }}
        >
          <Grid md={12}>
            <Typography level="body-md-light">
              Describa lo más detalladamente posible su consulta o inconveniente para un mejor entendimiento por parte
              de nuestro equipo de soporte.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ mb: 1 }}
          spacing={1}
        >
          <Grid md={4}>{customSelect('Área', support_ticket_areas, 'area', true)}</Grid>
          <Grid md={4}>{customSelect('Prioridad', Object.keys(support_ticket_priority_colors), 'priority')}</Grid>
        </Grid>
        <Grid
          container
          sx={{ mb: 1 }}
          spacing={1}
        >
          <Grid md={4}>
            <FormLabel sx={{ mb: 0.5, ...globalStyles.requiredFieldLabel }}>Asunto</FormLabel>
            <Input
              name="subject"
              placeholder="Ejemplo: Consulta técnica"
              onChange={handleChange}
              value={supportTicket?.subject ?? ''}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ mb: 1 }}
          spacing={1}
        >
          <Grid xs={8}>
            <FormLabel sx={{ mb: 0.5, ...globalStyles.requiredFieldLabel }}>Mensaje / Consulta</FormLabel>
            <Textarea
              name="content"
              minRows={12}
              value={supportTicket?.messages?.[0].content}
              onChange={(e) => handleMessageContentChange(e as unknown as React.ChangeEvent<HTMLInputElement>, 0)}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const dataCard = (
    key: keyof Pick<SupportTicket, 'area' | 'priority' | 'id' | 'client' | 'user' | 'subject'>,
    title: string,
  ) => (
    <Grid md={3}>
      <Box sx={{ border: globalStyles.border, borderRadius: '8px', py: 1, px: 2 }}>
        <Typography
          level="body-md-bold"
          sx={{ mb: 0.5 }}
        >
          {title}
        </Typography>

        <Tooltip
          placement="top"
          title={
            key === 'client'
              ? (supportTicket?.client as Tenant)?.name
              : key === 'user'
              ? (supportTicket?.user as User)?.email
              : supportTicket?.[key]
          }
          open={!!((supportTicket?.[key] ?? '').toString().length < 25) ? false : undefined}
        >
          <Typography
            level="body-sm-light"
            sx={key === 'priority' ? { color: getPriorityColor(supportTicket?.priority) } : {}}
          >{`${
            !!((supportTicket?.[key] ?? '').toString().length < 25)
              ? key === 'client'
                ? (supportTicket?.client as Tenant)?.name
                : key === 'user'
                ? (supportTicket?.user as User)?.email
                : supportTicket?.[key]
              : (supportTicket?.[key] ?? '').toString().slice(0, 20) + '\u2026'
          }`}</Typography>
        </Tooltip>
      </Box>
    </Grid>
  )

  const dateCard = () => (
    <Grid md={3}>
      <Box sx={{ border: globalStyles.border, borderRadius: '8px', py: 1, px: 2 }}>
        <div className="d-flex justify-content-between">
          <Typography
            level="body-md-bold"
            sx={{ mb: 0.5 }}
          >
            Fecha de resolución
          </Typography>
          {isSuperAdmin && supportTicket.status !== 'Cerrado' && (
            <DriveFileRenameOutline
              onClick={() => {
                setEditDate(true)
                !supportTicket.resolution_date &&
                  setSupportTicket((prevData) => ({
                    ...prevData,
                    resolution_date: moment().format('YYYY-MM-DDTHH:mm'),
                  }))
              }}
              sx={{ cursor: 'pointer' }}
            />
          )}
        </div>

        {editDate ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateField
              value={
                moment(supportTicket?.resolution_date).isValid() ? moment(supportTicket?.resolution_date) : moment()
              }
              onChange={handleDateChange}
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '0.5rem 1rem',
                },
              }}
            />
          </LocalizationProvider>
        ) : (
          <Typography
            level="body-sm-light"
            sx={{
              color: getResolutionDateColor(supportTicket?.resolution_date),
            }}
          >
            {moment(supportTicket?.resolution_date).isValid()
              ? moment(supportTicket?.resolution_date).format('DD-MM-YYYY')
              : '-'}
          </Typography>
        )}
      </Box>
    </Grid>
  )

  const ratingCard = () => (
    <Grid md={3}>
      <Box sx={{ border: globalStyles.border, borderRadius: '8px', pt: 1, px: 2 }}>
        <Typography
          level="body-md-bold"
          sx={{ mb: 0.5 }}
        >
          Calificación
        </Typography>
        <Rating
          value={supportTicket.rating ?? 0}
          readOnly
        />
      </Box>
    </Grid>
  )

  const getInitials = (name: string) => {
    if (name && name.trim().length > 0) {
      const words = name.split(' ')

      const initials = words
        .map((word) => word.slice(0, 1).toUpperCase())
        .slice(0, 2)
        .join('')

      return initials
    } else {
      return '-'
    }
  }
  const hashString = (name: string) => {
    return name.split('').reduce((hash, char) => (hash << 5) - hash + char.charCodeAt(0), 0)
  }

  const randomColor = (name: string) => chartsColors[Math.abs(hashString(name ?? '')) % chartsColors.length]

  const contactAvatar = (name: string) => (
    <Avatar
      sx={{ backgroundColor: randomColor(name), width: '55px', height: '55px', fontSize: '20px', color: 'white' }}
    >
      {getInitials(name ?? '')}
    </Avatar>
  )

  const messageFiles = (message_id: number) =>
    supportTicket?.files_data?.filter((file) => file.message_id === message_id)

  const messageImages = (message_id: number) => {
    return (
      supportTicket?.images_data
        ?.filter((file) => file.message_id === message_id)
        ?.map((image) => ({
          original: image.file as string,
          thumbnail: image.file as string,
          description: image.text_alt,
          text_alt: image.text_alt,
        })) ?? []
    )
  }

  const messageCard = (message: SupportMessage) => {
    return (
      <Box sx={{ border: globalStyles.border, borderRadius: '8px', py: 1, px: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid
            md={1}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {message.avatar ? (
              <img
                src={message.avatar ?? ''}
                referrerPolicy="no-referrer"
                className="avatar"
                alt="user_avatar"
                style={{ width: '5rem', height: '5rem' }}
              />
            ) : (
              contactAvatar(message.user)
            )}
          </Grid>

          <Grid md={isBrowser && window.innerWidth < 1300 ? 6 : 10}>
            <Typography
              level="h3"
              sx={{ mb: 1 }}
            >
              {message.user}
            </Typography>
            <Typography level="body-md-light">{message.content}</Typography>
          </Grid>
        </Grid>
        {(!!messageImages(message.id)?.length || !!messageFiles(message.id)?.length) && (
          <Grid
            container
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid md={1} />
            <Grid md={isBrowser && window.innerWidth < 1300 ? 6 : 10}>
              <AccordionGroup
                size="lg"
                sx={styles.accordionGroup}
              >
                <Accordion sx={styles.accordionGroup}>
                  <AccordionSummary
                    sx={{
                      '&:not(.Mui-expanded)': {
                        border: globalStyles.border,
                        borderRadius: globalStyles.borderRadius,
                        paddingY: 1,
                      },
                    }}
                  >
                    <Typography
                      level="body-md-bold"
                      variant="plain"
                    >
                      Adjuntos
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails slotProps={{ content: { sx: styles.accordionDetailsContent } }}>
                    {!!messageImages(message.id)?.length && (
                      <>
                        <Stack spacing={2}>
                          <Divider sx={styles.divider}>
                            <Typography
                              color="primary"
                              level="body-md-bold"
                            >
                              Imágenes
                            </Typography>
                          </Divider>
                          <Box sx={{ px: 1 }}>
                            <ImageGallery
                              showPlayButton={false}
                              showIndex
                              showBullets
                              autoPlay
                              items={messageImages(message.id)}
                            />
                          </Box>
                        </Stack>
                      </>
                    )}
                    {!!messageFiles(message.id)?.length && (
                      <>
                        {' '}
                        <Stack spacing={2}>
                          <Divider sx={styles.divider}>
                            <Typography
                              color="primary"
                              level="body-md-bold"
                            >
                              Videos
                            </Typography>
                          </Divider>
                          <Stack
                            direction="row"
                            gap={2}
                            sx={{ px: 1 }}
                          >
                            {supportTicket?.files_data
                              ?.filter((file) => file.message_id === message.id)
                              ?.map((file, index) => (
                                <>
                                  <Box
                                    key={index}
                                    sx={{ ...styles.filesBox, cursor: 'pointer' }}
                                  >
                                    <Typography
                                      level="body-md-light"
                                      startDecorator={<Download />}
                                    >
                                      <a
                                        href={file.file as string}
                                        style={{ color: 'black', textDecoration: 'none' }}
                                      >
                                        {typeof file.file === 'string'
                                          ? file.file
                                              .substring(file.file.indexOf('core/'), file.file.length)
                                              .substring(5)
                                          : file.file_name}
                                      </a>
                                    </Typography>
                                  </Box>
                                </>
                              ))}
                          </Stack>
                        </Stack>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          className="mt-2 pe-2 text-end text-muted"
          style={{ fontSize: '0.9rem' }}
        >
          <div>{moment(message.created_at ?? '', 'D/M/YYYY, HH:mm').format('D/M/YYYY, HH:mm')}</div>
        </Grid>
      </Box>
    )
  }

  const contentHasChanged = (textTemplateId: number) =>
    supportTicket?.messages?.[lastMessageId]?.content?.length !==
      supportTicketTextTemplates?.find((tt) => tt.id === textTemplateId)?.text.length ||
    supportTicket?.messages?.[lastMessageId]?.content !==
      supportTicketTextTemplates?.find((tt) => tt.id === textTemplateId)?.text

  const createTextTemplatesModal = (
    <>
      <Modal
        open={textTemplateModal}
        onClose={() => setTextTemplateModal(false)}
      >
        <ModalDialog>
          <DialogTitle>Título</DialogTitle>
          <ModalClose />
          <Input
            name="name"
            error={duplicatedTextTemplate}
            placeholder="Ingresar texto"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTextTemplateTitle(e.target.value)}
          />
          {duplicatedTextTemplate && (
            <FormHelperText>
              <InfoOutlined />
              Ya existe un texto base con ese nombre
            </FormHelperText>
          )}

          <Box>
            <Button
              sx={{ float: 'left', mt: 2 }}
              disabled={!textTemplateTitle}
              onClick={() => {
                const existingTextTemplate = supportTicketTextTemplates?.find((tt) => tt.title === textTemplateTitle)
                existingTextTemplate
                  ? setDuplicatedTextTemplate(true)
                  : createTextTemplate({
                      data: {
                        text: supportTicket.messages?.[lastMessageId].content ?? '',
                        title: textTemplateTitle,
                        section: 'support_ticket_message',
                        user: userData.data.id,
                      },
                    })
                      .unwrap()
                      .then((savedTt) => {
                        setTextTemplateId(savedTt.id)
                        setTextTemplateModal(false)
                        setDuplicatedTextTemplate(false)
                      })
              }}
              loading={isBusyTextTemplate}
            >
              Guardar en biblioteca
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  )

  const MAX_IMAGE_SIZE = 2000000
  const MAX_VIDEO_SIZE = 3000000

  const isValidFile = (file: File, maxSize: number) =>
    file.size <= maxSize && (file.type === 'image/jpeg' || file.type === 'image/png')
  const isValidVideoFile = (file: File) => file.size <= MAX_VIDEO_SIZE

  const sizeAlertModal = (
    <Modal
      open={showSizeAlertModal}
      onClose={() => setShowSizeAlertModal(false)}
      sx={styles.sizeAlertModal}
    >
      <Sheet
        variant="outlined"
        sx={styles.sizeAlertModal.container}
      >
        <ModalClose />
        <Typography
          level="h4"
          sx={{ mb: 1 }}
        >
          Los siguientes archivos no pudieron ser cargados:
        </Typography>
        {deletedFilesNames.map((fileName) => (
          <Typography
            sx={{ mb: 1 }}
            key={`file ${fileName}`}
          >
            {fileName}
          </Typography>
        ))}
        <Typography style={{ fontWeight: 'bold' }}>
          Recuerde que los archivos deben cumplir con los requerimientos:
        </Typography>
        <Typography>
          Formatos: *.jpg, *.png.
          <br></br>
          Peso máximo por archivo: 2MB
        </Typography>
        <Button
          onClick={() => {
            setShowSizeAlertModal(false)
          }}
          sx={styles.sizeAlertModal.closeButton}
        >
          Cerrar
        </Button>
      </Sheet>
    </Modal>
  )

  const replyCard = () => (
    <Box sx={{ border: globalStyles.border, borderRadius: '8px', py: 1, px: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}
      >
        <Grid
          md={1}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {auth.currentUser?.photoURL ? (
            <img
              src={auth.currentUser?.photoURL}
              referrerPolicy="no-referrer"
              className="avatar"
              alt="user_avatar"
              style={{ width: '5rem', height: '5rem' }}
            />
          ) : (
            contactAvatar(
              isSuperAdmin
                ? `${userData?.data?.first_name ?? ''} ${userData?.data?.last_name ?? ''}`
                : userData?.data?.email ?? '',
            )
          )}
        </Grid>
        <Grid md={10}>
          <Typography level="h3">Respuesta</Typography>
          <FormLabel>
            {isClientAdmin
              ? `${userData?.data?.email} - Tú`
              : `${userData?.data?.first_name ?? ''} ${userData?.data?.last_name ?? ''} - Tú`}
          </FormLabel>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mb: 2 }}
      >
        <Grid
          md={1}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
        <Grid md={isBrowser && window.innerWidth < 1300 ? 6 : 10}>
          <FormLabel sx={{ mb: 1 }}>Respuesta/comentario</FormLabel>
          {!!supportTicketTextTemplates?.length && (
            <Grid
              md={12}
              sx={{ mb: 1 }}
            >
              <Select
                placeholder="Seleccionar respuesta guardada en biblioteca"
                onChange={(_e, value: TextTemplate | null) => {
                  handleMessageContentChange(
                    {
                      target: { name: 'content', value: value?.text ?? '' },
                    } as ChangeEvent<HTMLInputElement>,
                    lastMessageId,
                  )
                  setTextTemplateId(Number(value?.id))
                }}
                value={supportTicketTextTemplates?.find((tt) => tt.id === textTemplateId)}
              >
                {supportTicketTextTemplates?.map((textTemplate, index) => (
                  <Option
                    value={textTemplate}
                    key={`textTemplate-${index}`}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    {textTemplate.title}
                    <ListItemDecorator>
                      <IconButton
                        onClick={() => {
                          deleteTextTemplate({ id: textTemplate.id })
                          if (textTemplate.id === textTemplateId) setTextTemplateId(undefined)
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </ListItemDecorator>
                  </Option>
                ))}
              </Select>
            </Grid>
          )}
          <Textarea
            minRows={12}
            name="content"
            value={supportTicket?.messages?.[lastMessageId]?.content ?? ''}
            onChange={(e) =>
              handleMessageContentChange(e as unknown as React.ChangeEvent<HTMLInputElement>, lastMessageId)
            }
          />
          {isSuperAdmin && (
            <Grid container>
              <Grid sx={{ mt: 1 }}>
                {isBusyTextTemplate ? (
                  <SpinnerCenter size="sm" />
                ) : (
                  <Typography
                    component="label"
                    level="body-md-light"
                    startDecorator={
                      <Switch
                        sx={{ ml: 1 }}
                        size="sm"
                        onChange={() =>
                          contentHasChanged(textTemplateId!) &&
                          (!textTemplateId
                            ? setTextTemplateModal(true)
                            : updateTextTemplate({
                                id: textTemplateId!,
                                data: { text: supportTicket?.messages?.[lastMessageId]?.content ?? '' },
                              }))
                        }
                        checked={!textTemplateId ? false : !contentHasChanged(textTemplateId!)}
                      />
                    }
                  >
                    {`${
                      !textTemplateId ? 'Guardar' : contentHasChanged(textTemplateId!) ? 'Guardar' : 'Guardado'
                    } en biblioteca`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid md={1} />
        <Grid md={isBrowser && window.innerWidth < 1300 ? 6 : 10}>
          <AccordionGroup
            size="lg"
            sx={styles.accordionGroup}
          >
            <Accordion sx={styles.accordionGroup}>
              <AccordionSummary
                sx={{
                  '&:not(.Mui-expanded)': {
                    border: globalStyles.border,
                    borderRadius: globalStyles.borderRadius,
                    paddingY: 1,
                  },
                }}
              >
                <Typography
                  level="body-md-bold"
                  variant="plain"
                >
                  Adjuntar archivos
                </Typography>
              </AccordionSummary>
              <AccordionDetails slotProps={{ content: { sx: styles.accordionDetailsContent } }}>
                <Stack>
                  <Divider sx={styles.divider}>
                    <Typography
                      color="primary"
                      level="body-md-bold"
                    >
                      Imágenes
                    </Typography>
                  </Divider>
                  {images}
                  <Divider sx={styles.divider}>
                    <Typography
                      color="primary"
                      level="body-md-bold"
                    >
                      Archivos
                    </Typography>
                  </Divider>
                  {videos}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
          <Grid
            container
            sx={{ justifyContent: 'flex-end', mt: 1 }}
          >
            <Grid>
              <Button
                disabled={!hasUpdatePermission || !stepsCompleted || isBusy}
                onClick={() => saveSupportTicket().then(() => onClose?.())}
              >
                Guardar y enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
  return (
    <SpinnerWrapper
      loading={isBusy}
      error={isErrorTicket}
      errorMessage="Error cargando ticket de soporte"
    >
      <>
        {!id ? (
          <>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              sx={styles.title}
            >
              <Typography level="h3">Ticketera de soporte</Typography>
              <ModalClose
                size="lg"
                sx={styles.modalClose}
              />
            </Stack>
            <Box sx={styles.formContainer}>
              <Typography
                level="h2"
                sx={{ color: 'var(--joy-palette-primary-plainColor)', mb: 1, mt: 3, px: 2 }}
              >
                Nuevo ticket
              </Typography>
              <Typography
                level="body-md-light"
                sx={{ mb: 3, px: 2 }}
              >
                Crear nuevo ticket de soporte
              </Typography>
              <StepperAccordion
                sections={[
                  {
                    title: 'Información general',
                    items: [
                      {
                        title: 'Descripción',
                        content: description(),
                      },
                    ],
                  },
                  {
                    title: 'Recursos adicionales',
                    items: [
                      {
                        title: 'Imágenes',
                        content: images,
                      },
                      {
                        title: 'Videos',
                        content: videos,
                      },
                    ],
                  },
                ]}
              />
            </Box>
            <Box sx={styles.stepNavigation}>
              <Button
                disabled={!hasCreatePermission || !stepsCompleted || isBusy}
                onClick={() => saveSupportTicket().then(() => onClose?.())}
              >
                Enviar
              </Button>
            </Box>
          </>
        ) : (
          <Stepper
            style="joy"
            title={`${supportTicket.subject}`}
            steps={[
              {
                title: 'Tickets de soporte',
                completed: true,
                content: (
                  <div className="supportTicketForm">
                    <Grid
                      container
                      spacing={2}
                    >
                      {dataCard('id', 'Número de ticket')}
                      {dataCard('client', 'Cliente')}
                      {dataCard('user', 'Usuario')}
                      {dataCard('area', 'Área')}
                      {dataCard('priority', 'Prioridad')}
                      {dataCard('subject', 'Asunto')}
                      {(supportTicket?.resolution_date || isSuperAdmin) && dateCard()}
                      {!!supportTicket.rating && isSuperAdmin && ratingCard()}
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    {messagesToShow?.map((message, index) => (
                      <Grid
                        container
                        className="mb-3"
                        key={index}
                      >
                        <Grid md={12}>{messageCard(message)}</Grid>
                      </Grid>
                    ))}
                    {!isTicketClosed && (
                      <Grid
                        container
                        sx={{ mb: 3 }}
                      >
                        <Grid md={12}>{replyCard()}</Grid>
                      </Grid>
                    )}
                    {isTicketClosed && isClientAdmin && supportTicket.rating === 0 && (
                      <Grid
                        container
                        className="text-end"
                      >
                        <Grid>
                          <Button
                            style={{ minWidth: 90, height: '100%' }}
                            onClick={() =>
                              setRatingModal({
                                show: true,
                                title: '¿Cómo calificarías la atención recibida para la resolución de tu ticket?',
                              })
                            }
                          >
                            Calificar resolución
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                ),
                noTitle: true,
              },
            ]}
            buttons={[
              <Select
                key="status-select"
                name="status"
                value={supportTicket?.status}
                onChange={(_e, value) =>
                  handleChange({
                    target: { value: value, name: 'status' },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                variant="solid"
                sx={{
                  backgroundColor: getStatusColor(supportTicket.status),
                  '&.Mui-disabled': {
                    backgroundColor: getStatusColor(supportTicket.status),
                    color: 'white',
                  },
                }}
                disabled={!!isClientAdmin || isTicketClosed}
                indicator={!!isClientAdmin || isTicketClosed ? null : <KeyboardArrowDown />}
              >
                {Object.keys(support_ticket_status_colors).map((option, index) => (
                  <Option
                    key={`status ${index}`}
                    value={option}
                  >
                    {<Circle sx={{ color: getStatusColor(option as SupportTicket['status']), fontSize: '1rem' }} />}
                    {option}
                  </Option>
                ))}
              </Select>,
              isSuperAdmin && !isTicketClosed && (
                <Button
                  key="save-button"
                  disabled={!hasUpdatePermission || !stepsCompleted || isBusy}
                  onClick={() => saveSupportTicket().then(() => onClose?.())}
                >
                  Guardar
                </Button>
              ),
            ]}
            onVisualizeStep={markVisualizedStep}
          />
        )}

        <ConfirmationModal
          acceptText="Si"
          cancelText="No"
          modal={backModal}
          setModal={setBackModal}
          loading={false}
        />
        <InformationModal
          modal={filesSizeModal}
          setModal={setFilesSizeModal}
        />
        <RatingModal
          modal={ratingModal}
          setModal={setRatingModal}
          value={supportTicket?.rating ?? 0}
          handleRating={(rating: number) =>
            handleChange({
              target: {
                name: 'rating',
                value: rating.toString(),
              },
            } as ChangeEvent<HTMLInputElement>)
          }
          ticketId={supportTicket.id!}
        />
        <InputModal
          modal={inputModal}
          setModal={setInputModal}
        />
        {createTextTemplatesModal}
        {sizeAlertModal}
      </>
    </SpinnerWrapper>
  )
}

export default SupportTicketForm
