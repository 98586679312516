import React, { ChangeEvent, useState } from 'react'
import { useContext } from 'react'
import SpinnerWrapper from '@components/SpinnerWrapper'
import { auth, translateFirebaseError } from '@firebaseUtils/firebase'
import { AlertContext } from '@components/AlertContextWrapper'
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { PasswordInput } from '@components/PasswordInput'
import { Button, Container, FormLabel, Grid, Typography } from '@mui/joy'

const styles = {
  propagateWaitCursor: {
    cursor: 'wait',
    '& > *': {
      cursor: 'wait',
      '& > *': {
        cursor: 'wait',
      },
    },
  },
}

const ChangePassword = () => {
  const { toast } = useContext(AlertContext)
  const [oldPassword, setOldPassword] = useState('')
  const [oldPassWordError, setOldPassWordError] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false)
  const [score, setScore] = useState(0)

  const isBusy = isUpdatingPassword

  return (
    <SpinnerWrapper>
      <Container sx={isBusy ? styles.propagateWaitCursor : {}}>
        <fieldset disabled={isBusy}>
          {auth.currentUser?.providerData.length === 1 &&
            auth.currentUser.providerData[0].providerId === 'password' && (
              <>
                <Typography
                  level="h3"
                  sx={{ mb: 1 }}
                >
                  Cambiar contraseña
                </Typography>
                <Grid
                  container
                  sx={{ mb: 1 }}
                >
                  <Grid md={12}>
                    <FormLabel>Contraseña anterior</FormLabel>
                    <PasswordInput
                      value={oldPassword}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        e.persist()
                        setOldPassWordError('')
                        setOldPassword(e.target.value)
                      }}
                    />
                    <FormLabel className="text-danger">{oldPassWordError}</FormLabel>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid md={6}>
                    <FormLabel>Nueva contraseña</FormLabel>
                    <PasswordInput
                      onChangeScore={setScore}
                      showStrengthBar
                      value={password}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        e.persist()
                        setPassword(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <FormLabel>Repetir contraseña</FormLabel>
                    <PasswordInput
                      value={repeatPassword}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        e.persist()
                        setRepeatPassword(e.target.value)
                      }}
                    />
                    {password !== repeatPassword && (
                      <FormLabel className="text-danger">Las contraseñas no coinciden</FormLabel>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ justifyContent: 'flex-end', mt: 1 }}
                >
                  <Grid>
                    <Button
                      disabled={
                        password !== repeatPassword || !auth.currentUser || isUpdatingPassword || !score || !oldPassword
                      }
                      onClick={() => {
                        setIsUpdatingPassword(true)
                        const credential = EmailAuthProvider.credential(auth.currentUser!.email!, oldPassword)
                        reauthenticateWithCredential(auth.currentUser!, credential)
                          .then(async () => {
                            await toast
                              .promise(updatePassword(auth.currentUser!, password), {
                                pending: `Cambiando contraseña...`,
                                success: `Contraseña cambiada con éxito`,
                                error: `No se pudo cambiar la contraseña`,
                              })
                              .then(() => {
                                setOldPassword('')
                                setPassword('')
                                setRepeatPassword('')
                              })
                              .catch((e: any) => {
                                console.log(e)
                              })
                              .finally(() => setIsUpdatingPassword(false))
                          })
                          .catch((error: any) => setOldPassWordError(translateFirebaseError(error.code)))
                          .finally(() => setIsUpdatingPassword(false))
                      }}
                    >
                      Guardar contraseña
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
        </fieldset>
      </Container>
    </SpinnerWrapper>
  )
}

export default ChangePassword
