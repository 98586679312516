import { mediacoreApi } from '@apis/mediacoreApi'
import { createOneMutation, deleteByIdMutation, getAllQuery, getByIdQuery, updateByIdMutation } from '@apis/mediacoreQueries'
import { TextTemplate } from '@types'

const texTemplatesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getTextTemplates: builder.query<TextTemplate[], void>({
      query: getAllQuery('core/plantillas-textos'),
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'TextTemplate' as const, id })), 'TextTemplate'] : ['TextTemplate']),
    }),
    getTextTemplateById: builder.query<TextTemplate, { id: number }>({
      query: getByIdQuery('core/plantillas-textos'),
      providesTags: (_result, _error, { id }) => [{ type: 'TextTemplate', id }],
    }),
    deleteTextTemplate: builder.mutation<void, { id: number }>({
      query: deleteByIdMutation('core/plantillas-textos'),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            texTemplatesApi.util.updateQueryData('getTextTemplates', undefined, (draftTextTemplates) => {
              return draftTextTemplates.filter((draftTextTemplate) => draftTextTemplate.id !== id)
            }),
          )
        } catch {}
      },
    }),
    createTextTemplate: builder.mutation<TextTemplate, { data: Partial<TextTemplate> }>({
      query: createOneMutation('core/plantillas-textos'),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdTextTemplate } = await queryFulfilled
          dispatch(
            texTemplatesApi.util.updateQueryData('getTextTemplates', undefined, (draftTextTemplates) => {
              draftTextTemplates.unshift(createdTextTemplate)
              return draftTextTemplates
            }),
          )
        } catch {}
      },
    }),
    updateTextTemplate: builder.mutation<TextTemplate, { id: number; data: Partial<TextTemplate> }>({
      query: updateByIdMutation('core/plantillas-textos'),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTextTemplate } = await queryFulfilled
          dispatch(
            texTemplatesApi.util.updateQueryData('getTextTemplateById', { id }, (draft) => {
              Object.assign(draft, updatedTextTemplate)
            }),
          )
          dispatch(
            texTemplatesApi.util.updateQueryData('getTextTemplates', undefined, (draftTextTemplates) => {
              return draftTextTemplates.map((draftTextTemplate) => (draftTextTemplate.id !== id ? draftTextTemplate : updatedTextTemplate))
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTextTemplatesQuery,
  useGetTextTemplateByIdQuery,
  useDeleteTextTemplateMutation,
  useCreateTextTemplateMutation,
  useUpdateTextTemplateMutation,
  usePrefetch: usePrefetchTextTemplates,
} = texTemplatesApi
