import { Appraisal, AppraisalImagesData, TokkoProperties, TokkoPropertyPhoto } from '@types'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import {
  age_options,
  extra_amenities_options,
  extra_general_options,
  extra_services_options,
  measurement_options,
  surface_measurement_options,
} from '@constants'
import {
  useCreateTextTemplateMutation,
  useDeleteTextTemplateMutation,
  useGetTextTemplatesQuery,
  useUpdateTextTemplateMutation,
} from '@endpoints/textTemplatesEndpoint'
import { UserContext } from '@components/Auth'
import { onlyUnique, realAddressComponents, removeKeyFromArray, updateErrorToast, updateSuccessToast } from '@utils'
import { Id, toast } from 'react-toastify'
import {
  useCreateAppraisalMutation,
  useGetAppraisalByIdQuery,
  useUpdateAppraisalMutation,
  useUploadAppraisalBlueprintsMutation,
  useUploadAppraisalImagesMutation,
} from '@endpoints/appraisalsEndpoint'
import { navigate } from 'gatsby'
import { useGetPropertyMixTableQuery } from '@endpoints/tablesEndpoint'

export interface Props {
  id?: number
  clientId?: number
  handleShowForm: ({ show, type }: { show: boolean; type?: 'Novedad' | 'Tasación' | 'Reporte' | undefined }) => void
}

interface ImageGallery {
  id?: string
  file?: File | string
  text_alt?: string
  order?: number
}

export const useAppraisal = ({ id, clientId, handleShowForm }: Props) => {
  const { userData } = useContext(UserContext)

  const {
    data: queriedAppraisal,
    isLoading: _isLoadingAppraisal,
    isError: _isErrorAppraisal,
    isFetching: isFetchingAppraisal,
  } = useGetAppraisalByIdQuery({ id: id!, clientId: clientId! }, { skip: !clientId || !id })

  useEffect(() => {
    setAppraisal(queriedAppraisal ?? blankAppraisal)
  }, [queriedAppraisal, clientId])

  const {
    data: allUsersTextTemplates,
    isLoading: isLoadingTextTemplates,
    isError: _isErrorTextTemplates,
  } = useGetTextTemplatesQuery(undefined, { skip: userData.status !== 'fulfilled' })

  const {
    data: allProperties,
    isLoading: _isLoadingProperties,
    isError: _isErrorProperties,
  } = useGetPropertyMixTableQuery({ clientId: clientId!, owners: true }, { skip: !clientId })

  const [updateAppraisal, { isLoading: _isUpdating }] = useUpdateAppraisalMutation()
  const [createAppraisal, { isLoading: _isCreating }] = useCreateAppraisalMutation()

  const [isLoadingImagesData, setIsLoadingImagesData] = useState(false)
  const [isLoadingBlueprintsData, setIsLoadingBlueprintsData] = useState(false)

  const [uploadAppraisalImage] = useUploadAppraisalImagesMutation()
  const [uploadAppraisalBlueprint] = useUploadAppraisalBlueprintsMutation()

  const [createTextTemplate, { isLoading: isCreatingTextTemplate }] = useCreateTextTemplateMutation()
  const [deleteTextTemplate, { isLoading: isDeletingTextTemplate }] = useDeleteTextTemplateMutation()
  const [updateTextTemplate, { isLoading: isUpdatingTextTemplate }] = useUpdateTextTemplateMutation()

  const allTextTemplates = allUsersTextTemplates?.filter((tt) => tt.user === userData.data.id)

  const blankAppraisal: Partial<Appraisal> = {
    tokko_property: undefined,
    title: 'Informe de tasación',
    content: {
      visualizedSteps: [0],
      operations: [],
      valuation_price_percentage: '5',
      valuation_currency: 'USD',
      valuation_price_rent_percentage: '5',
      valuation_currency_rent: 'USD',
      valuation_price_temporary_rent_percentage: '5',
      valuation_currency_temporary_rent: 'USD',
      footnotes_title: 'Notas al pie',
      demand: 'Sin especificar',
      supply: 'Sin especificar',
      values: 'Sin especificar',
      security: 'Sin especificar',
      growth_rate: 'Sin especificar',
      location_quality: 'Sin especificar',
      expenses_currency: 'ARS',
      comparisonProperties: [],
      comparison_reference_points: [],
      parking_lot_type: 'Privada cubierta',
      age: 'Sin especificar',
      orientation: 'Sin especificar',
      disposition: 'Sin especificar',
      luminosity: 'Sin especificar',
      preservation_state: 'Sin especificar',
      building_quality: 'Sin especificar',
      total_surface_measurement: surface_measurement_options[0],
      roofed_surface_measurement: surface_measurement_options[0],
      semiroofed_surface_measurement: surface_measurement_options[0],
      unroofed_surface_measurement: surface_measurement_options[0],
      surface_measurement: surface_measurement_options[0],
      front_measurement: measurement_options[0],
      depth_measurement: measurement_options[0],
      extra_general: [],
      extra_services: [],
      extra_amenities: [],
      valuation_period_rent: 'Mensual',
      valuation_period_temporary_rent: 'Diario',
    },
    is_active: false,
    owners: [],
    date_published: moment().format('YYYY-MM-DDThh:mm'),
  }
  const [appraisal, setAppraisal] = useState<Partial<Appraisal>>(blankAppraisal)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setAppraisal((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleDateChange = (date: Moment | null) => {
    // For mui-x date picker
    setAppraisal((prevData) => ({
      ...prevData,
      date_published: date?.format('YYYY-MM-DDThh:mm') ?? '',
    }))
  }

  const setDate = (key: string, value: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      [key]: moment(value).format('YYYY-MM-DDThh:mm'),
    }))
  }

  const handleAuthorSelectChange = (value: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      author: value,
    }))
  }

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.persist) e.persist()
    setAppraisal((prevData) => ({
      ...prevData,
      content: { ...prevData.content, [e.target.name]: e.target.value },
    }))
  }

  const handleReferencePointNameChange = (value: string, index: number) => {
    setAppraisal((prevData) => {
      const newData = structuredClone(prevData)
      newData.content.reference_points[index].name = value
      return newData
    })
  }

  const handleSelectChange = (value: string, name: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: { ...prevData.content, [name]: value },
    }))
  }

  const handleTextOptionArrayChange = (name: string, option: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: prevData.content[name]?.includes(option)
          ? prevData.content[name].filter((el: string) => el !== option)
          : [...(prevData.content[name] ?? []), option],
      },
    }))
  }

  const handleAutoComplete = async (address: google.maps.places.PlaceResult[] | undefined): Promise<void> => {
    enum GeoType {
      COUNTRY = 'country',
      STATE = 'administrative_area_level_1',
      PARTY = 'administrative_area_level_2',
      CITY = 'locality',
      SUBLOCALITY = 'sublocality',
      NEIGHBORHOOD = 'neighborhood',
      STREET = 'route',
      STREET_NUMBER = 'street_number',
    }
    let street = ''
    let streetNumber = ''
    let state = ''
    let party = ''
    let city = ''
    let sublocality = ''
    let country = ''
    let neighborhood = ''
    let geo_lat = address?.[0]?.geometry?.location?.lat()
    let geo_long = address?.[0]?.geometry?.location?.lng()

    address?.[0]?.address_components?.forEach((element) => {
      if (element.types.includes(GeoType.STATE)) {
        state = element.short_name
      }
      if (element.types.includes(GeoType.CITY)) {
        city = element.long_name
      }
      if (element.types.includes(GeoType.COUNTRY)) {
        country = element.long_name
      }
      if (element.types.includes(GeoType.STREET)) {
        street = element.long_name
      }
      if (element.types.includes(GeoType.STREET_NUMBER)) {
        streetNumber = element.long_name
      }
      if (element.types.includes(GeoType.NEIGHBORHOOD)) {
        neighborhood = element.long_name
      }
      if (element.types.includes(GeoType.PARTY)) {
        party = element.long_name
      }
      if (element.types.includes(GeoType.SUBLOCALITY)) {
        sublocality = element.long_name
      }
    })

    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content, //
        real_address: `${street} ${streetNumber}`,
        country,
        city: party,
        neighborhood:
          Boolean(sublocality) || Boolean(neighborhood)
            ? `${sublocality} - ${neighborhood}`.trim().replace(/-$/, '').replace(/^-/, '').trim()
            : city,
        state,
        geo_lat,
        geo_long,
      },
    }))
  }

  const handleDragMarker = (e: google.maps.MapMouseEvent) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: { ...prevData.content, geo_lat: e.latLng?.lat(), geo_long: e.latLng?.lng() },
    }))
  }
  const handleCheckboxOptionArrayChange = (name: string, option: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: prevData.content[name]?.includes(option)
          ? prevData.content[name].filter((el: string) => el !== option)
          : [...(prevData.content[name] ?? []), option],
      },
    }))
  }
  const handleDeleteAmenity = (name: string, option: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: prevData.content[name]?.includes(option)
          ? prevData.content[name].filter((el: string) => el !== option)
          : [...(prevData.content[name] ?? []), option],
      },
    }))
  }

  const handleExtraName = (name: string, extraName: string) => {
    setAppraisal((prevData) => {
      const newData = structuredClone(prevData)
      if (extraName) newData.content[name].push(extraName)
      return newData
    })
  }

  const handleChangeGalleryFiles = (filteredFiles: File[] | undefined, key: 'images_data' | 'blueprints_data') =>
    setAppraisal((prevData) => ({
      ...prevData,
      [key]: [
        ...(prevData?.[key] ?? []),
        ...(filteredFiles?.map((newFile, fileIndex) => ({
          file: newFile,
          text_alt: '',
          order: (prevData?.[key]?.length ?? 0) + fileIndex,
        })) ?? []),
      ],
    }))

  const handleReorderGallery = (
    e: React.DragEvent<HTMLElement>,
    index: number,
    key: 'images_data' | 'blueprints_data',
  ) =>
    setAppraisal((prevData) => {
      const newFiles = [...(prevData?.[key] ?? [])]
      const reOrder = (array: Array<any>, from: number, to: number) => array.splice(to, 0, array.splice(from, 1)[0])

      reOrder(newFiles, Number(e.dataTransfer.getData('index')), index)
      return {
        ...prevData,
        [key]: newFiles.map((file, fileIndex) => ({ ...file, order: fileIndex })),
      }
    })

  const deleteGalleryImage = (index: number, key: 'images_data' | 'blueprints_data') =>
    setAppraisal((prevData) => {
      const newFiles = [...(prevData?.[key] ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        [key]: newFiles,
      }
    })

  const handleGalleryAltChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    key: 'images_data' | 'blueprints_data',
  ) => {
    e.persist()
    setAppraisal((prevData) => {
      const newFiles = [...(prevData?.[key] ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: e.target.value }
      return {
        ...prevData,
        [key]: newFiles,
      }
    })
  }

  const handleCheckboxChange = (name: string) => {
    setAppraisal((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: !prevData.content[name],
      },
    }))
  }

  const fetchPhoto = (photo: TokkoPropertyPhoto): Promise<AppraisalImagesData> => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.GATSBY_API_BASE_URL}owner/externo/tokko-propiedades/imagenes/?url=${photo.image}`)
        .then((response) => {
          if (response.ok) return response.blob()
          throw new Error('Fetch error')
        })
        .then((blob) => {
          resolve({
            file: new File([blob], 'photo.jpg', {
              type: blob.type,
            }),
            text_alt: photo.description ?? '',
            order: photo.order,
          })
        })
        .catch((error) => reject(error))
    })
  }

  const fetchPhotos = async (photos: TokkoProperties['photos']) => {
    const result = await Promise.allSettled(photos.map((photo) => fetchPhoto(photo)))
    return (result.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<AppraisalImagesData>[]).map(
      (res) => res.value,
    )
  }

  const handleChangeProperty = async (selectedPropertyId: number, owners?: number[]) => {
    const selectedProperty = allProperties?.results?.find((property) => Number(property.id) === selectedPropertyId)
    // const selectedPropertyOwner =
    //   ownerProfiles
    //     ?.filter((profile) => profile.tokko_property.includes(Number(selectedPropertyId)))
    //     .map((profiles) => profiles.user) ?? []
    let images_data: AppraisalImagesData[] = []
    let blueprints_data: AppraisalImagesData[] = []
    setIsLoadingImagesData(true)
    fetchPhotos(selectedProperty?.photos?.filter((photo: any) => !photo.is_blueprint) ?? [])
      .then((images_data) => {
        setAppraisal((prevData) => ({
          ...prevData,
          images_data,
        }))
      })
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoadingImagesData(false))
    setIsLoadingBlueprintsData(true)
    fetchPhotos(selectedProperty?.photos?.filter((photo: any) => photo.is_blueprint) ?? [])
      .then((blueprints_data) => {
        setAppraisal((prevData) => ({
          ...prevData,
          blueprints_data,
        }))
      })
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoadingBlueprintsData(false))
    setAppraisal((prevData) => ({
      ...prevData,
      tokko_property: Number(selectedPropertyId),
      owners,
      images_data,
      blueprints_data,
      content: {
        expenses_currency: 'ARS',
        visualizedSteps: appraisal.content?.visualizedSteps ?? [0],
        operations: selectedProperty?.operations ?? [],
        property_type: selectedProperty?.type?.name ? [selectedProperty?.type?.name] : selectedProperty?.property_type,
        expenses: Number(selectedProperty?.expenses ?? 0),
        room_amount: Number(selectedProperty?.room_amount ?? 0),
        suite_amount: Number(selectedProperty?.suite_amount ?? 0),
        bathroom_amount: Number(selectedProperty?.bathroom_amount ?? 0),
        toilet_amount: Number(selectedProperty?.toilet_amount ?? 0),
        parking_lot_amount: Number(selectedProperty?.parking_lot_amount ?? 0),
        disposition: selectedProperty?.disposition,
        orientation: selectedProperty?.orientation,
        description: selectedProperty?.rich_description ?? selectedProperty?.description ?? '',
        age: (() => {
          switch (true) {
            case Number(selectedProperty?.age) < 0:
              return age_options[0]
            case Number(selectedProperty?.age) === 0:
              return age_options[1]
            case Number(selectedProperty?.age) <= 5 && Number(selectedProperty?.age) > 0:
              return age_options[2]
            case Number(selectedProperty?.age) <= 10 && Number(selectedProperty?.age) > 5:
              return age_options[3]
            case Number(selectedProperty?.age) <= 20 && Number(selectedProperty?.age) > 10:
              return age_options[4]
            case Number(selectedProperty?.age) <= 50 && Number(selectedProperty?.age) > 20:
              return age_options[5]
            case Number(selectedProperty?.age) > 50:
              return age_options[6]
            default:
              return undefined
          }
        })(),
        real_address: realAddressComponents(selectedProperty?.real_address)?.address ?? selectedProperty?.address ?? '',
        fake_address: selectedProperty?.fake_address ?? '',
        full_address: selectedProperty?.real_address ?? '',
        geo_lat: selectedProperty?.geo_lat ?? '',
        geo_long: selectedProperty?.geo_long ?? '',
        total_surface: Number(selectedProperty?.total_surface?.split(' ')[0] ?? 0),
        roofed_surface: Number(selectedProperty?.roofed_surface ?? 0),
        semiroofed_surface: Number(selectedProperty?.semiroofed_surface ?? 0),
        unroofed_surface: Number(selectedProperty?.unroofed_surface ?? 0),
        surface: Number(selectedProperty?.surface) ?? 0,
        front_measure: Number(selectedProperty?.front_measure) ?? 0,
        depth_measure: Number(selectedProperty?.depth_measure) ?? 0,
        roofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        unroofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        semiroofed_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        total_surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        surface_measurement: selectedProperty?.surface_measurement ?? surface_measurement_options[0],
        computable_total_surface: Math.round(
          Number(selectedProperty?.roofed_surface ?? 0) +
            Number(selectedProperty?.semiroofed_surface ?? 0) * 0.5 +
            Number(selectedProperty?.unroofed_surface ?? 0) * 0.3,
        ),
        tokko_valuation_price: Number(selectedProperty?.valuation_price?.split(' ')[0] ?? 0),
        square_meter_value: Math.round(
          Number(selectedProperty?.valuation_price?.split(' ')[0] ?? 0) /
            (Number(selectedProperty?.roofed_surface ?? 0) +
              Number(selectedProperty?.semiroofed_surface ?? 0) * 0.5 +
              Number(selectedProperty?.unroofed_surface ?? 0) * 0.3),
        ),
        front_measurement: measurement_options[0],
        depth_measurement: measurement_options[0],
        extra_services:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 1 && extra_services_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        extra_general:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 2 && extra_general_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        extra_amenities:
          selectedProperty?.tags
            ?.filter((tag: any) => tag.type === 3 && extra_amenities_options.includes(tag.name))
            .map((tag: any) => tag.name) ?? [],
        valuation_price_percentage: 5,
        valuation_currency: 'USD',
        valuation_price_rent_percentage: 5,
        valuation_currency_rent: 'USD',
        valuation_price_temporary_rent_percentage: 5,
        valuation_currency_temporary_rent: 'USD',
        footnotes_title: 'Notas al pie',
        demand: 'Sin especificar',
        supply: 'Sin especificar',
        values: 'Sin especificar',
        security: 'Sin especificar',
        growth_rate: 'Sin especificar',
        location_quality: 'Sin especificar',
        floor: realAddressComponents(selectedProperty?.real_address)?.floor ?? '',
        apartment: realAddressComponents(selectedProperty?.real_address)?.apartment ?? '',
      },
    }))
  }

  const removeProperty = () => {
    setAppraisal((prevData) => ({
      ...prevData,
      tokko_property: undefined,
      content: blankAppraisal.content,
    }))
  }

  const uploadImages = (propertyId: number, gallery: ImageGallery[], endpoint: 'images' | 'blueprints') => {
    let imagesUploadIndex = 1
    let uploadErrors = 0
    const uploadImage = async (image: ImageGallery, propertyId: number, toastId: Id) =>
      (endpoint === 'images' ? uploadAppraisalImage : uploadAppraisalBlueprint)({
        id: propertyId,
        data: {
          files: [image.file as File],
          file_name: (image.file as File).name, //
          text_alt: image.text_alt ?? '',
          order: image.order,
        },
      })
        .unwrap()
        .then((res) => {
          return res
        })
        .catch((error) => {
          uploadErrors++
          return error
        })
        .finally(() => {
          toast.update(toastId, {
            render: `Cargando ${endpoint === 'images' ? 'imagen' : 'plano'} ${imagesUploadIndex++} de ${
              gallery.length
            } ...`,
          })
        })

    const uploadGallery = async (gallery: ImageGallery[], propertyId: number, toastId: Id) =>
      Promise.all(gallery.map((image) => uploadImage(image, propertyId, toastId)))
        .then((res) => {
          return res
        })
        .catch((error) => error)

    const toastId = toast.loading(`Cargando ${endpoint === 'images' ? 'imagen' : 'plano'} 1 de ${gallery.length} ...`)

    uploadGallery(gallery, propertyId, toastId).then(() =>
      toast.update(toastId, {
        render: `${endpoint === 'images' ? 'Imágenes cargadas' : 'Planos cargados'} exitosamente: ${
          gallery.length - uploadErrors
        }`,
        type: uploadErrors ? 'warning' : 'success',
        isLoading: false,
        autoClose: 3000,
      }),
    )
  }
  const handleModalVisibility = (show: boolean) => {
    handleShowForm({ show })
  }

  const saveAppraisal = (is_active?: boolean, goToPreview?: boolean) => {
    const data = {
      ...appraisal,
      images_data: removeKeyFromArray(
        appraisal?.images_data?.filter((id) => typeof id.file === 'string') ?? [],
        'file',
      ),
      blueprints_data: removeKeyFromArray(
        appraisal?.blueprints_data?.filter((id) => typeof id.file === 'string') ?? [],
        'file',
      ),
      content: {
        ...appraisal?.content,
        reference_points: appraisal?.content?.reference_points?.filter(
          (refPoint: { name: string; lat: number; lng: number }) => !!refPoint.name,
        ),
      },
      date_published: appraisal.date_published ?? new Date().toISOString(),
    }

    if (typeof is_active === 'boolean') data.is_active = is_active

    const pendingToast = () => {
      return toast.loading(`${id ? 'Guardando' : 'Creando'} tasación ...`)
    }

    const toastId = pendingToast()
    ;(id
      ? updateAppraisal({
          id,
          data,
        })
      : createAppraisal({ data: { ...data, client: clientId } })
    )
      .unwrap()
      .then((result: Appraisal) => {
        const imagesToUpload = appraisal?.images_data?.filter((id) => id.file instanceof File)
        const blueprintsToUpload = appraisal?.blueprints_data?.filter((id) => id.file instanceof File)
        if (imagesToUpload?.length) {
          uploadImages(result.id, imagesToUpload, 'images')
        }
        if (blueprintsToUpload?.length) {
          uploadImages(result.id, blueprintsToUpload, 'blueprints')
        }
        updateSuccessToast(toast, toastId, `Tasación ${id ? 'editada' : 'creada'} con éxito`)
        const basePath = '/mediaowner/comunicaciones/'

        goToPreview &&
          navigate(
            !data.is_active && !goToPreview
              ? `${basePath}tasaciones/editar/${result.id}/`
              : goToPreview
              ? `${basePath}tasaciones/previsualizar/${result.id}/`
              : `${basePath}`,
          )
        handleModalVisibility(false)
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo ${id ? 'editar' : 'crear'} la tasación`,
        )
      })
  }

  const markVisualizedStep = (step: number) => {
    if (!appraisal?.content?.visualizedSteps?.includes(step))
      setAppraisal((prevData) => ({
        ...prevData,
        content: {
          ...prevData.content,
          visualizedSteps: [...prevData?.content?.visualizedSteps, step],
        },
      }))
  }

  const addOwner = (userId: number) => {
    if (appraisal?.owners?.includes(userId)) return
    setAppraisal((prevData) => ({
      ...prevData,
      owners: [...(prevData?.owners ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setAppraisal((prevData) => ({
      ...prevData,
      owners: users.every((user) => prevData?.owners?.includes(user))
        ? prevData?.owners?.filter((id) => !users.includes(id))
        : [...(prevData?.owners ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setAppraisal((prevData) => ({
      ...prevData,
      owners: prevData?.owners?.filter((id) => id !== userId),
    }))
  }

  return {
    appraisal,
    setAppraisal,
    handleChange,
    handleDateChange,
    handleAuthorSelectChange,
    handleContentChange,
    handleTextOptionArrayChange,
    handleSelectChange,
    handleAutoComplete,
    handleDragMarker,
    handleCheckboxOptionArrayChange,
    handleDeleteAmenity,
    handleExtraName,
    handleChangeGalleryFiles,
    handleReorderGallery,
    deleteGalleryImage,
    handleGalleryAltChange,
    handleCheckboxChange,
    allTextTemplates,
    isLoadingTextTemplates,
    createTextTemplate,
    isCreatingTextTemplate,
    deleteTextTemplate,
    isDeletingTextTemplate,
    updateTextTemplate,
    isUpdatingTextTemplate,
    handleChangeProperty,
    isLoadingImagesData,
    isLoadingBlueprintsData,
    removeProperty,
    saveAppraisal,
    isFetchingAppraisal,
    markVisualizedStep,
    addOwner,
    removeOwner,
    toggleOwners,
    handleReferencePointNameChange,
    setDate,
  }
}
