import React from 'react'

interface Props {
    title: string,
    noMarginBottom?: boolean,
}

const Title = ({ title, noMarginBottom }: Props) => {
    return (
        <h2 className={`title ${noMarginBottom ? 'mt-5' : 'my-5'}`}>{title}</h2>
    )
}

export default Title