import React, { createContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
  children: JSX.Element
}

export const AlertContext = createContext<{
  toast: typeof toast
  setDeleteModal: React.Dispatch<
    React.SetStateAction<{
      header: string
      body: string
      show: boolean
      onAccept: () => void
    }>
  >
}>({ toast, setDeleteModal: () => null })

const AlertContextWrapper = ({ children }: Props) => {
  const [deleteModal, setDeleteModal] = useState<{ header: string; body: string; show: boolean; onAccept: () => void }>(
    { header: '', body: '', show: false, onAccept: () => null },
  )

  return (
    <AlertContext.Provider value={{ toast, setDeleteModal }}>
      {children}
      <ToastContainer
        position="bottom-right"
        transition={Slide}
        autoClose={3000}
        theme="dark"
      />
      <Modal
        show={deleteModal.show}
        onHide={() => setDeleteModal((prevState) => ({ ...prevState, show: false }))}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{deleteModal.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{deleteModal.body}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteModal((prevState) => ({ ...prevState, show: false }))}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setDeleteModal((prevState) => ({ ...prevState, show: false }))
              deleteModal.onAccept()
            }}
            variant="primary"
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </AlertContext.Provider>
  )
}

export default AlertContextWrapper
