import SpinnerWrapper from '@components/SpinnerWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { DynamicTable, TextCell, sortableNumberCell, sortablePlainTextCell } from './DynamicTable'
import { AddNewButton } from '@components/AddNewButton'
import { UserContext } from '@components/Auth'
import { authRoles, routes, support_ticket_priority_colors, support_ticket_status_colors } from '@constants'
import { SupportTicket } from '@types'
import { Icon } from '@components/Icon'
import moment from 'moment'
import StarRating from '@components/StarRating'
import { Badge, Drawer, IconButton, Tooltip } from '@mui/joy'
import SupportTicketForm from '@components/Forms/SupportTicketForm'
import { VisibilityOutlined } from '@mui/icons-material'
import { useGetTicketTableQuery } from '@endpoints/tablesEndpoint'
import { useAppSelector } from '@redux/reduxHooks'
import { useSession } from '@hooks/useSession'

interface DrawerState {
  open: boolean
  id?: number
}
interface Props {
  defaultDrawerState?: DrawerState
  isTableDrawerOpen?: boolean
}
interface Error {
  status: number
  data: {
    detail: string
  }
}

export const SupportTicketsTable = ({ defaultDrawerState, isTableDrawerOpen }: Props) => {
  const { userData } = useContext(UserContext)
  const { client, hasTicketsCreatePermission } = useSession()
  const [drawer, setDrawer] = useState<DrawerState>(defaultDrawerState ?? { open: false })

  useEffect(() => {
    if (isTableDrawerOpen) {
      window.history.replaceState(
        null,
        '',
        !drawer.open
          ? routes.core.support_tickets
          : !drawer.id
          ? routes.core.add_support_ticket
          : routes.core.edit_support_ticket(drawer.id),
      )
    }
  }, [drawer, isTableDrawerOpen])

  const isBrowser = typeof window !== 'undefined'

  const noTrailingSlashLocation = isBrowser ? location.pathname.replace(/\/$/, '') : ''
  const tableState = useAppSelector(
    (state) => state.tableConfigs[noTrailingSlashLocation as keyof typeof state.tableConfigs],
  )
  const { search = '', sorted = {}, filter = {}, activeTab = 'all' } = tableState ?? {}

  const {
    data: allTickets,
    isLoading: isLoadingTickets,
    isFetching: isFetchingTickets,
    isError: isErrorTicket,
    error: errorTicket,
  } = useGetTicketTableQuery(
    { clientId: client?.id!, search: Number(search?.length) < 3 ? '' : search, sorted, filter, activeTab },
    { skip: !client?.id },
  )

  const errorMessage =
    (errorTicket as Error)?.status === 403
      ? (errorTicket as Error)?.data?.detail
      : 'Error cargando el ticket de soporte'

  const isSuperAdmin = authRoles.ADMIN.includes(userData?.data?.role.name)

  const getPriorityColor = (priority: SupportTicket['priority']) => support_ticket_priority_colors[priority]
  const getStatusColor = (status: SupportTicket['status']) => support_ticket_status_colors[status]

  return (
    <>
      <SpinnerWrapper
        loading={isLoadingTickets}
        error={isErrorTicket}
        errorMessage={errorMessage}
      >
        <DynamicTable
          useBackendFilter
          meta={allTickets?.meta}
          options={{ activeTabName: 'Abierto', inactiveTabName: 'Cerrado' }}
          extraElements={
            !isSuperAdmin ? (
              <AddNewButton
                disabled={!hasTicketsCreatePermission}
                onClick={() => setDrawer({ open: true })}
              />
            ) : (
              <></>
            )
          }
          fetching={isFetchingTickets}
          tableConfig={{
            id: { header: '# Ticket', sortType: 'number' },
            status: { header: 'Estado' },
            priority: { header: 'Prioridad' },
            ...(isSuperAdmin ? { client: { header: 'Cliente', sortType: 'string' } } : {}),
            subject: { header: 'Asunto', sortType: 'string' },
            created_at: { header: 'Fecha', sortType: 'date' },
            ...(isSuperAdmin && activeTab === 'inactive' ? { rating: { header: 'Calificación' } } : {}),
            actions: { header: 'Acciones' },
          }}
          rows={
            allTickets?.results.map((supportTicket) => ({
              activeRow: supportTicket.status !== 'Cerrado',
              content: {
                id: sortableNumberCell(supportTicket.id),
                status: {
                  render: (
                    <td className="align-middle text-center justify-content-center align-items-center py-4">
                      <Icon
                        icon="circle"
                        style={{
                          color: getStatusColor(supportTicket.status ?? ''),
                          fontSize: '1rem',
                        }}
                        className="me-2"
                      />
                      {supportTicket.status ?? '-'}
                    </td>
                  ),
                  sortValue: supportTicket?.status ?? '',
                },
                priority: {
                  render: (
                    <td className="align-middle text-center justify-content-center align-items-center ">
                      <Icon
                        icon="flag"
                        style={{
                          color: getPriorityColor(supportTicket?.priority ?? ''),
                          fontSize: '1rem',
                        }}
                        className="me-2"
                      />
                      {supportTicket?.priority ?? '-'}
                    </td>
                  ),
                  sortValue: supportTicket?.priority ?? '',
                },
                client: sortablePlainTextCell((supportTicket?.client as number).toString(), 20),
                area: sortablePlainTextCell(supportTicket?.area, 20),
                subject: sortablePlainTextCell(supportTicket?.subject, 20),
                created_at: sortablePlainTextCell(supportTicket?.created_at),
                resolution_date: {
                  render: (
                    <TextCell
                      cellStyles={{
                        color:
                          moment(supportTicket?.resolution_date).isBefore(moment(), 'day') &&
                          supportTicket.status !== 'Cerrado'
                            ? '#FF0000'
                            : '#000000',
                      }}
                    >
                      {supportTicket?.resolution_date
                        ? moment(supportTicket.resolution_date).format('DD/MM/YYYY')
                        : '-'}
                    </TextCell>
                  ),
                  sortValue: supportTicket?.resolution_date,
                },
                rating: {
                  render: (
                    <td className="align-middle text-center">
                      <div className="d-flex justify-content-center">
                        <StarRating value={supportTicket.rating} />
                      </div>
                    </td>
                  ),
                },
                actions: {
                  render: (
                    <td style={{ textAlign: 'center' }}>
                      <Tooltip title="Abrir ticket">
                        <IconButton
                          variant="plain"
                          onClick={() => setDrawer({ open: true, id: supportTicket.id })}
                        >
                          <Badge
                            size="sm"
                            color="danger"
                            invisible={
                              !supportTicket.messages?.some((message) => {
                                return (
                                  message.read === false &&
                                  (isSuperAdmin ? message.role === 'client' : message.role === 'admin')
                                )
                              })
                            }
                          >
                            <VisibilityOutlined />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </td>
                  ),
                },
              },
            })) ?? []
          }
        />
      </SpinnerWrapper>
      <Drawer
        anchor="right"
        open={drawer.open}
        onClose={() => setDrawer({ open: false })}
        slotProps={{
          content: {
            sx: {
              width: { xs: '100vw', md: '900px' },
            },
          },
        }}
      >
        {Boolean(client?.id) && (
          <SupportTicketForm
            key={drawer.id}
            clientId={client?.id!}
            id={drawer.id}
            onClose={() => setDrawer({ open: false })}
          />
        )}
      </Drawer>
    </>
  )
}
