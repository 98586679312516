import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react'
import PasswordStrengthBar, { PasswordFeedback } from 'react-password-strength-bar'
import { IconButton, Input } from '@mui/joy'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

interface Props {
  showStrengthBar?: boolean
  value?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onChangeScore?: (score: number, feedback: PasswordFeedback) => void
}

export const PasswordInput = ({
  showStrengthBar,
  value = '',
  placeholder,
  onChange,
  onKeyDown,
  onChangeScore,
}: Props) => {
  const [showValue, setShowValue] = useState(false)
  return (
    <>
      <Input
        {...{ value, placeholder, onChange, onKeyDown }}
        type={!showValue ? 'password' : undefined}
        endDecorator={
          <IconButton onClick={() => setShowValue(!showValue)}>
            {!showValue ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </IconButton>
        }
      />
      {!!showStrengthBar && (
        <PasswordStrengthBar
          scoreWords={['Muy débil', 'Débil', 'Normal', 'Buena', 'Óptima']}
          shortScoreWord={!value ? '' : 'Demasiado corta'}
          scoreWordStyle={{ fontSize: '0.875em' }}
          password={value}
          minLength={6} // Matches Firebase requirement
          onChangeScore={onChangeScore}
        />
      )}
    </>
  )
}
