import React from 'react'
import { Form, FormControlProps, FormText } from 'react-bootstrap'

interface AdditionalProps {
  label?: string
}

const FormControlStyled = ({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement> & FormControlProps & AdditionalProps) => {
  return rest.label ? (
    <div>
      {<FormText>{rest.label}</FormText>}
      <Form.Control
        {...rest}
        bsPrefix={'formControlStyledInput'}
      ></Form.Control>
    </div>
  ) : (
    <Form.Control
      {...rest}
      bsPrefix={'formControlStyledInput'}
    ></Form.Control>
  )
}

export default FormControlStyled
