import { createSlice } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

interface TableConfig {
  [key: string]: {
    header: string
    sortType?: string
  }
}

interface TableState {
  sorted?: { direction?: 'asc' | 'desc'; key?: keyof TableConfig }
  filter?: { [key: keyof TableConfig]: ReactNode }
  search?: string
  rowsPerPage?: number
  excludedKeys?: string[]
  activeTab?: string
}

interface InitialState {
  [location: string]: TableState
}

export const tableConfigsSlice = createSlice({
  name: 'tableConfigs',
  initialState: {} as InitialState,
  reducers: {
    changeTableConfig: {
      reducer(state, action) {
        Object.assign(state, action.payload)
      },
      prepare(location, tableState) {
        return {
          payload: { [location]: tableState },
          meta: '',
          error: '',
        }
      },
    },
  },
})

export const { changeTableConfig } = tableConfigsSlice.actions
