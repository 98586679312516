import Stack from '@mui/joy/Stack'
import { Icon } from '@components/Icon'
import SpinnerCenter from '@components/SpinnerCenter'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import { changeTableConfig } from '@slices/tableConfigsSlice'
import { Link, navigate } from 'gatsby'
import React, { ChangeEvent, Fragment, useState, useEffect, ReactNode } from 'react'
import Typography from '@mui/joy/Typography'
import { globalStyles } from '@styles/styles'
import Box from '@mui/joy/Box'
import Switch from '@mui/joy/Switch'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import AccordionDetails from '@mui/joy/AccordionDetails'
import Button from '@mui/joy/Button'
import { DeleteOutline, GridView, List, Search } from '@mui/icons-material'
import Checkbox from '@mui/joy/Checkbox'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import Table from '@mui/joy/Table'
import Tooltip from '@mui/joy/Tooltip'
import Dropdown from '@mui/joy/Dropdown'
import MenuButton from '@mui/joy/MenuButton'
import AccordionGroup from '@mui/joy/AccordionGroup'
import IconButton from '@mui/joy/IconButton'
import Input from '@mui/joy/Input'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import { useDebouncedCallback } from 'use-debounce'
import Layout from '@components/Layout'
import { AspectRatio, Grid, ListItemDecorator, Divider } from '@mui/joy'

interface TableConfig {
  [key: string]: {
    header: string
    sortType?: string
  }
}

interface Row {
  activeRow?: boolean
  content: {
    [key: keyof TableConfig]: {
      render: JSX.Element
      sortValue?: string | number | Date
    }
  }
}

interface RowCard {
  title: string
  date: string
  image: string
  button: React.ReactNode
  fallbackJSX?: JSX.Element
}

interface CustomFilters {
  [key: keyof TableConfig]: string[]
}

interface Props {
  meta?: {
    totals: {
      actives: number
      inactives: number
      total: number
    }
    filters: {
      [key: string]: any
    }
    sorted: string[]
  }
  useBackendFilter?: boolean
  cards?: RowCard[]
  rows: Row[]
  fetching?: boolean
  tableConfig: TableConfig
  extraElements?: JSX.Element
  options?: {
    activeTabName?: string
    inactiveTabName?: string
    hiddenTabs?: boolean
    hiddenSearchBar?: boolean
    hiddenFilters?: boolean
    customFilters?: CustomFilters
    customBodyHeight?: string
    tableSelection?: {
      title: string
      link: string
    }[]
  }
  onTabChange?: (activeTab: string) => void
}

const styles = {
  leftPanel: {
    resultsSummary: {
      height: globalStyles.navBarHeight,
    },
    activeFilters: {
      paddingX: 2,
      paddingY: 1,
      marginBottom: 2,
      border: globalStyles.border,
      borderRadius: globalStyles.borderRadius,
    },
    activeFiltersSwitch: {
      marginRight: 1,
    },
    filters: {
      paddingX: 2,
      paddingY: 1,
      marginBottom: 2,
      border: globalStyles.border,
      borderRadius: globalStyles.borderRadius,
    },
    appliedFilters: {
      title: {
        marginTop: 1,
        justifyContent: 'space-between',
      },
      bodyItem: {
        border: '1px solid var(--joy-palette-primary-outlinedColor)',
        borderRadius: globalStyles.borderRadius,
        paddingX: 1,
      },
      bodyItemText: {
        color: 'var(--joy-palette-primary-outlinedColor)',
      },
    },
    availableFilters: {
      box: {
        paddingX: 1,
        paddingY: 1,
        marginBottom: 2,
        marginTop: 2,
        border: globalStyles.border,
        borderRadius: globalStyles.borderRadius,
      },
      outsideAccordion: { paddingY: 2 },
      insideAccordion: { paddingY: 2 },
    },
  },
  rightPanel: {
    tabsBar: {
      global: {
        width: '100%',
      },
      tab: {
        height: globalStyles.navBarHeight,
        borderBottom: globalStyles.border,
      },
      extraElements: {
        height: globalStyles.navBarHeight,
      },
    },
  },
}

export const DynamicTable = ({
  rows,
  fetching,
  tableConfig,
  extraElements,
  options,
  onTabChange,
  useBackendFilter,
  meta,
  cards,
}: Props) => {
  const noTrailingSlashLocation = location.pathname.replace(/\/$/, '')
  const tableState = useAppSelector(
    (state) => state.tableConfigs[noTrailingSlashLocation as keyof typeof state.tableConfigs],
  )
  const dispatch = useAppDispatch()
  const iterableTableConfig = Object.keys(tableConfig)

  const [sorted, setSorted] = useState<{ direction?: 'asc' | 'desc'; key?: keyof TableConfig }>(
    tableState?.sorted ?? {},
  )

  const [filter, setFilter] = useState<{ [key: keyof TableConfig]: ReactNode } | undefined>(tableState?.filter ?? {})
  const [search, setSearch] = useState(tableState?.search ?? '')
  const [undebouncedSearch, setUndebouncedSearch] = useState(tableState?.search ?? '')
  const [excludedKeys, setExcludedKeys] = useState<string[]>(tableState?.excludedKeys ?? [])
  const [activeTab, setActiveTab] = useState<string>(tableState?.activeTab ?? 'all')
  const [resultsView, setResultsView] = useState<'table' | 'grid'>(Boolean(cards) ? 'grid' : 'table')

  const handleSort = (tableKey: keyof TableConfig) => {
    setSorted((sort) => ({ direction: sort?.direction === 'asc' ? 'desc' : 'asc', key: tableKey }))
  }

  const debounceSearch = useDebouncedCallback((value) => {
    setSearch(value)
  }, 500)

  useEffect(() => {
    debounceSearch(undebouncedSearch)
  }, [undebouncedSearch])

  useEffect(() => {
    dispatch(changeTableConfig(noTrailingSlashLocation, { filter, search, sorted, excludedKeys, activeTab }))
  }, [filter, search, sorted, excludedKeys, activeTab])

  const sortRows = (rows: Row[]) =>
    rows.sort((a, b) => {
      if (!sorted.key) return 1
      if (typeof a.content[sorted.key]?.sortValue === undefined) return 1
      let c = a,
        d = b
      if (sorted?.direction === 'desc') {
        c = b
        d = a
      }
      switch (tableConfig[sorted.key].sortType) {
        case 'number':
          return (
            ((c.content[sorted.key].sortValue as number) ?? 0) - //
            ((d.content[sorted.key].sortValue as number) ?? 0)
          )
        case 'string':
          return (c.content[sorted.key].sortValue as string)
            .toLocaleString()
            .toLowerCase()
            .localeCompare(
              (d.content[sorted.key].sortValue as string) //
                .toLocaleString()
                .toLowerCase(),
            )
        case 'date':
          return (
            new Date(c.content[sorted.key].sortValue as string).getTime() - //
            new Date(d.content[sorted.key].sortValue as string).getTime()
          )
        default:
          return 1
      }
    })

  const filterRows = (rows: Row[]) =>
    rows.filter(
      (row) =>
        !Object.keys(tableConfig)
          .map((tableKey) =>
            options?.customFilters?.[tableKey]
              ? !!filter?.[tableKey] &&
                !(row.content[tableKey]?.render?.props.children as string).includes(filter?.[tableKey] as string)
              : !!filter?.[tableKey] && filter?.[tableKey] !== row.content[tableKey]?.render?.props.children,
          )
          .some((noMatches) => noMatches),
    )

  const searchRows = (rows: Row[]) =>
    rows.filter((row) =>
      Object.keys(tableConfig)
        .map(
          (tableKey) =>
            !search ||
            row.content[tableKey]?.render?.props?.children?.toString().toLowerCase().includes(search.toLowerCase()),
        )
        .some((matches) => matches),
    )

  const rowsToShow = useBackendFilter
    ? rows
    : activeTab === 'active'
    ? sortRows(rows)?.filter((row) => row.activeRow)
    : activeTab === 'inactive'
    ? sortRows(rows)?.filter((row) => !row.activeRow)
    : sortRows(rows)
  const filteredRows = useBackendFilter ? rows : searchRows(filterRows(rowsToShow))

  const customColumnWidth: { [key: string]: string } = {
    '': '5%',
    Estado: '10%',
    Portada: '10%',
    Acciones: '10%',
  }

  const customHeaderJustify: { [key: string]: string } = {
    Acciones: 'center',
  }

  const table = (rows: Row[]) => {
    const rowsToShow = useBackendFilter ? rows : searchRows(filterRows(rows))
    return (
      <>
        <Table sx={{ cursor: fetching ? 'wait' : 'default' }}>
          <thead>
            <tr
              style={{
                height: globalStyles.navBarHeight,
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              {iterableTableConfig
                .filter((tableKey) => !excludedKeys.includes(tableConfig[tableKey].header))
                .map((tableKey, index: number) => (
                  <th
                    style={{
                      cursor: !useBackendFilter
                        ? tableConfig[tableKey].sortType
                          ? 'pointer'
                          : undefined
                        : meta?.sorted.includes(tableKey)
                        ? 'pointer'
                        : undefined,
                      width: customColumnWidth[tableConfig[tableKey].header],
                    }}
                    key={`header-${index}`}
                    onClick={() =>
                      (!useBackendFilter ? tableConfig[tableKey].sortType : meta?.sorted.includes(tableKey))
                        ? handleSort(tableKey)
                        : {}
                    }
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        justifyContent: customHeaderJustify[tableConfig[tableKey].header] ?? 'start',
                      }}
                    >
                      <Typography
                        sx={{ color: globalStyles.secondaryTextColor }}
                        level="body-md-light"
                      >
                        {tableConfig[tableKey].header}
                      </Typography>
                      {(!useBackendFilter ? tableConfig[tableKey].sortType : meta?.sorted.includes(tableKey)) && (
                        <>
                          <Icon
                            style={{
                              position: 'absolute',
                              right: 0,
                              fontSize: '1.5rem',
                              marginBottom: '0.4rem',
                              opacity: sorted?.key === tableKey && sorted.direction === 'asc' ? '1' : '0.3',
                            }}
                            icon={'arrow_drop_up'}
                          />
                          <Icon
                            style={{
                              position: 'absolute',
                              right: 0,
                              fontSize: '1.5rem',
                              marginTop: '0.4rem',
                              opacity: sorted?.key === tableKey && sorted.direction === 'desc' ? '1' : '0.3',
                            }}
                            icon={'arrow_drop_down'}
                          />
                        </>
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {rowsToShow.map((row, rowIndex) => (
              <tr
                style={{
                  opacity: row.activeRow ? 1 : 0.5,
                }}
                key={`row-${rowIndex}`}
              >
                {iterableTableConfig
                  .filter((tableKey) => !excludedKeys.includes(tableConfig[tableKey].header))
                  .map((tableKey) => {
                    const render = row.content[tableKey]?.render
                    return <Fragment key={tableKey}>{render ?? <td></td>}</Fragment>
                  })}
              </tr>
            ))}
          </tbody>
        </Table>
        {!rowsToShow.length && (
          <p
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              textAlign: 'center',
            }}
          >
            <i>No hay elementos para mostrar</i>
          </p>
        )}
      </>
    )
  }

  const grid = (cards: RowCard[]) => (
    <Grid
      container
      spacing={4}
      sx={{ padding: 4 }}
    >
      {cards!.map((card, index) => (
        <Grid
          key={index}
          xs={12}
          sm={6}
          md={4}
        >
          <AspectRatio sx={{ position: 'relative', borderRadius: globalStyles.card.borderRadius }}>
            {card.image ? (
              <img
                src={card.image}
                alt=""
              />
            ) : (
              card.fallbackJSX
            )}
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                padding: 2,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: 'linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5))',
                alignItems: 'end',
              }}
            >
              <Stack gap={2}>
                <Typography
                  level="body-sm-light"
                  sx={{ color: 'white' }}
                >
                  {card.date}
                </Typography>
                <Typography
                  level="h3"
                  sx={{ color: 'white' }}
                >
                  {card.title}
                </Typography>
              </Stack>
              <Stack sx={{ paddingY: 1 }}>{card.button}</Stack>
            </Stack>
          </AspectRatio>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Layout
      customBodyHeight={options?.customBodyHeight}
      hiddenLeftPanel={options?.hiddenFilters}
      hiddenTopBar={options?.hiddenSearchBar}
      loaders={{
        // left: fetching,
        right: fetching,
      }}
      leftHeader={
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={styles.leftPanel.resultsSummary}
        >
          <Typography level="title-lg-bold">
            {'Mostrando '}
            <Typography
              color="primary"
              level="title-lg-bold"
            >{`${filteredRows.length} resultado(s)`}</Typography>
          </Typography>
        </Stack>
      }
      leftBody={
        <>
          {!options?.hiddenTabs && (
            <Box sx={styles.leftPanel.activeFilters}>
              <Stack gap={1}>
                <Typography
                  component={'span'}
                  sx={{
                    color: activeTab === 'all' ? undefined : globalStyles.secondaryTextColor,
                  }}
                  startDecorator={
                    <Switch
                      size="sm"
                      sx={styles.leftPanel.activeFiltersSwitch}
                      checked={activeTab === 'all'}
                      onChange={() => {
                        setActiveTab('all')
                        onTabChange?.('all')
                      }}
                    ></Switch>
                  }
                >
                  {`Todos (${meta?.totals.total ?? ''})`}
                </Typography>
                <Typography
                  component={'span'}
                  sx={{
                    color: activeTab === 'active' ? undefined : globalStyles.secondaryTextColor,
                  }}
                  startDecorator={
                    <Switch
                      size="sm"
                      sx={styles.leftPanel.activeFiltersSwitch}
                      checked={activeTab === 'active'}
                      onChange={() => {
                        setActiveTab('active')
                        onTabChange?.('active')
                      }}
                    ></Switch>
                  }
                >
                  {`${options?.activeTabName ?? 'Activas'} (${meta?.totals.actives ?? ''})`}
                </Typography>
                <Typography
                  component={'span'}
                  sx={{
                    color: activeTab === 'inactive' ? undefined : globalStyles.secondaryTextColor,
                  }}
                  startDecorator={
                    <Switch
                      size="sm"
                      sx={styles.leftPanel.activeFiltersSwitch}
                      checked={activeTab === 'inactive'}
                      onChange={() => {
                        setActiveTab('inactive')
                        onTabChange?.('inactive')
                      }}
                    ></Switch>
                  }
                >
                  {`${options?.inactiveTabName ?? 'No activas'} (${meta?.totals.inactives ?? ''})`}
                </Typography>
              </Stack>
            </Box>
          )}
          <Box sx={styles.leftPanel.filters}>
            <Accordion>
              <AccordionSummary>
                <Typography level="title-lg-bold">
                  Filtrando en{' '}
                  <Typography
                    level="title-lg-bold"
                    color="primary"
                  >
                    {activeTab === 'active'
                      ? options?.activeTabName ?? 'Activas'
                      : activeTab === 'inactive'
                      ? options?.inactiveTabName ?? 'No activas'
                      : 'Todos'}
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={styles.leftPanel.appliedFilters.title}
                >
                  <Typography>Aplicados</Typography>
                  <Button
                    sx={{
                      paddingX: 0,
                    }}
                    onClick={() => setFilter({})}
                    variant="plain"
                  >
                    <Typography level="body-md-light">Borrar todos</Typography>
                  </Button>
                </Stack>
                <Stack gap={1}>
                  {Object.keys(filter ?? {})
                    .filter((filterKey) => filter?.[filterKey])
                    .map((filterKey, index) => (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={styles.leftPanel.appliedFilters.bodyItem}
                        key={index + filterKey}
                      >
                        <Typography
                          sx={styles.leftPanel.appliedFilters.bodyItemText}
                          level="body-md"
                        >
                          {filter?.[filterKey]}
                        </Typography>
                        <IconButton
                          color="primary"
                          onClick={() => setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: undefined }))}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    ))}
                </Stack>
                <Box sx={styles.leftPanel.availableFilters.box}>
                  <Accordion>
                    <AccordionSummary>
                      <Typography level="title-lg-bold">Filtros disponibles</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={styles.leftPanel.availableFilters.outsideAccordion}>
                      <AccordionGroup>
                        {(!useBackendFilter ? iterableTableConfig : meta?.filters)?.map(
                          (tableKey: any, index: number) => {
                            const thisHeader = !useBackendFilter
                              ? tableConfig[tableKey].header
                              : tableKey.label ?? tableConfig[tableKey.key]?.header ?? tableKey.key
                            return (
                              (!useBackendFilter ? Boolean(tableConfig[tableKey].sortType) : true) && (
                                <Accordion
                                  sx={{
                                    px: 0,
                                  }}
                                  key={index}
                                >
                                  <AccordionSummary>
                                    <Typography level="title-lg-light">{thisHeader}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails sx={styles.leftPanel.availableFilters.insideAccordion}>
                                    <Stack gap={1}>
                                      {!options?.customFilters?.[tableKey] || useBackendFilter
                                        ? (!useBackendFilter
                                            ? rows.filter(
                                                (uniqueRow, rowIndex) =>
                                                  rows.findIndex(
                                                    (row) =>
                                                      row.content[tableKey]?.sortValue ===
                                                      uniqueRow.content[tableKey]?.sortValue,
                                                  ) === rowIndex,
                                              )
                                            : tableKey.values
                                          ).map((row: any, optionIndex: number) => {
                                            const checkboxValue = !useBackendFilter
                                              ? row.content[tableKey]?.render?.props.children
                                              : row.name
                                            return (
                                              <Checkbox
                                                checked={
                                                  filter?.[!useBackendFilter ? tableKey : tableKey.key] ===
                                                  checkboxValue
                                                }
                                                label={`${ellipt(checkboxValue)} (${row.total})`}
                                                sx={{
                                                  color:
                                                    filter?.[!useBackendFilter ? tableKey : tableKey.key] ===
                                                    checkboxValue
                                                      ? undefined
                                                      : globalStyles.secondaryTextColor,
                                                }}
                                                onClick={() =>
                                                  setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    [!useBackendFilter ? tableKey : tableKey.key]: checkboxValue,
                                                  }))
                                                }
                                                key={`option-${optionIndex}`}
                                              ></Checkbox>
                                            )
                                          })
                                        : options?.customFilters?.[tableKey].map((option, optionIndex) => (
                                            <Checkbox
                                              label={option}
                                              onClick={() =>
                                                setFilter((prevFilter) => ({ ...prevFilter, [tableKey]: option }))
                                              }
                                              key={`option-${optionIndex}`}
                                            ></Checkbox>
                                          ))}
                                    </Stack>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            )
                          },
                        )}
                      </AccordionGroup>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      }
      rightHeader={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          sx={styles.rightPanel.tabsBar.global}
        >
          <Tabs value={options?.tableSelection?.findIndex((table) => table.link.includes(noTrailingSlashLocation))}>
            <TabList disableUnderline>
              {options?.tableSelection?.map(({ title, link }, index) => (
                <Link
                  key={`tab-${index}-${title}`}
                  to={link}
                  style={{ textDecoration: 'none', backgroundColor: 'inherit' }}
                >
                  <Tab
                    color="primary"
                    sx={styles.rightPanel.tabsBar.tab}
                  >
                    <Typography
                      level={link.includes(noTrailingSlashLocation) ? 'title-lg-bold' : 'title-lg-light'}
                      sx={link.includes(noTrailingSlashLocation) ? {} : { color: globalStyles.secondaryTextColor }}
                    >
                      {title}
                    </Typography>
                  </Tab>
                </Link>
              ))}
            </TabList>
          </Tabs>
          <Stack
            sx={styles.rightPanel.tabsBar.extraElements}
            alignItems="center"
            alignContent={'center'}
            direction="row"
            gap={2}
          >
            <div className="d-flex justify-content-end me-3">{extraElements ?? false}</div>
            {!options?.hiddenSearchBar && (
              <Input
                aria-label="Buscar"
                value={undebouncedSearch}
                placeholder={`Buscar en ${
                  options?.tableSelection
                    ?.find((table) => table.link.includes(noTrailingSlashLocation))
                    ?.title.toLowerCase() ?? 'resultados'
                }`}
                endDecorator={<Search />}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUndebouncedSearch(e.target.value)}
              ></Input>
            )}
            {!options?.hiddenFilters && false && (
              <Dropdown>
                <MenuButton startDecorator={<List />} />
                <Menu>
                  <MenuItem
                    onClick={() => setExcludedKeys([])} //
                  >
                    <Checkbox
                      label={'Todos'}
                      checked={!excludedKeys.length}
                      onChange={() => null}
                    />
                  </MenuItem>
                  {iterableTableConfig.map((tableKey, index: number) => {
                    const thisHeader = tableConfig[tableKey].header
                    return (
                      <MenuItem
                        key={`option-${index}`}
                        onClick={() => {
                          setExcludedKeys((prevKeys) =>
                            prevKeys.includes(thisHeader)
                              ? prevKeys.filter((key) => key !== thisHeader)
                              : [...prevKeys, thisHeader],
                          )
                        }}
                      >
                        <Checkbox
                          label={thisHeader}
                          checked={!excludedKeys.includes(thisHeader)}
                          onChange={() => null}
                        />
                      </MenuItem>
                    )
                  })}
                </Menu>
              </Dropdown>
            )}
            {Boolean(cards) && (
              <Stack direction="row">
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={() => setResultsView('table')}
                  startDecorator={<List />}
                ></Button>
                <Divider orientation="vertical" />
                <Button
                  size="sm"
                  variant="plain"
                  color="neutral"
                  onClick={() => setResultsView('grid')}
                  startDecorator={<GridView />}
                ></Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      }
      rightBody={resultsView === 'table' ? table(rowsToShow) : grid(cards!)}
    />
  )
}

interface SwitchCellProps {
  disabled?: boolean
  checked: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  label?: string
}

export const SwitchCell = ({ disabled, checked, onChange, label }: SwitchCellProps) => (
  <td>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        level="body-md"
        component={'span'}
        startDecorator={
          <Switch
            sx={{ marginRight: 1 }}
            size="sm"
            {...{ disabled, checked, onChange, label }}
          />
        }
      >
        {/* {label} */}
      </Typography>
    </Box>
  </td>
)

interface TextCellProps {
  children?: ReactNode
  ellipsis?: number
  cellStyles?: React.CSSProperties
}

const ellipt = (children: any, ellipsis: number = 50) =>
  typeof children === 'string' && children.length > ellipsis ? children.slice(0, ellipsis - 1) + '\u2026' : children

export const TextCell = ({ children, ellipsis = 50, cellStyles }: TextCellProps) => {
  const isEllipted = !(typeof children !== 'string' || children.length < ellipsis)

  const td = (
    <td style={cellStyles}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        {!isEllipted ? children : children?.slice(0, ellipsis - 1) + '\u2026'}
      </Box>
    </td>
  )

  return isEllipted ? (
    <Tooltip
      placement="top"
      title={children}
    >
      {td}
    </Tooltip>
  ) : (
    td
  )
}

export const sortablePlainTextCell = (text?: string, ellipsis?: number, loading?: boolean) => ({
  render: loading ? <SpinnerCell /> : <TextCell {...{ ellipsis }}>{text ?? ''}</TextCell>, //
  sortValue: text ?? '',
})

export const sortableNumberCell = (value?: number) => ({
  render: <TextCell>{value?.toString() ?? ''}</TextCell>, //
  sortValue: value?.toString() ?? '',
})

export const sortableDateCell = (date?: string, justDate?: boolean) => ({
  render: (
    <TextCell>
      {date ? (justDate ? new Date(date).toLocaleDateString() : new Date(date).toLocaleString()) : '-'}
    </TextCell>
  ), //
  sortValue: new Date(date ?? 0),
})

interface DuplicateIconProps {
  disabled?: boolean
  onClick?: () => void
}

export const DuplicateIcon = ({ disabled, onClick, ...rest }: DuplicateIconProps) => (
  <Tooltip
    placement="top"
    title={'Duplicar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && onClick?.()}
      className={`material-icons-outlined  mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      library_add
    </i>
  </Tooltip>
)

interface EditIconProps {
  path: string
  disabled?: boolean
}

export const EditIcon = ({
  path,
  disabled,
  ...rest
}: EditIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={'Editar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname } })}
      className={`material-icons mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      drive_file_rename_outline
    </i>
  </Tooltip>
)

interface FeaturedIconProps {
  disabled?: boolean
  featured?: boolean
  onClick?: () => void
}

export const FeaturedIcon = ({
  disabled,
  featured,
  onClick,
  ...rest
}: FeaturedIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={featured ? 'Destacada' : 'Destacar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && onClick?.()}
      className={`material-icons mx-1`}
      style={{
        cursor: disabled ? 'inherit' : 'pointer',
        fontSize: '1.5rem',
        color: featured ? '#ffb200' : '#9A9A9A40',
      }}
    >
      star
    </i>
  </Tooltip>
)

interface DeleteIconProps {
  disabled?: boolean
  onClick?: () => void
}

export const DeleteIcon = ({
  disabled,
  onClick,
  ...rest
}: DeleteIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={'Eliminar'}
  >
    <i
      {...rest}
      onClick={() => !disabled && onClick?.()}
      className={`material-icons mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      delete_outline
    </i>
  </Tooltip>
)

interface PreviewIconProps {
  path: string
  disabled?: boolean
  unreadedMessages?: boolean
  customTooltip?: string
}

export const PreviewIcon = ({
  path,
  disabled,
  unreadedMessages,
  customTooltip,
  ...rest
}: PreviewIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={customTooltip ?? 'Previsualizar'}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {unreadedMessages && (
        <div
          style={{
            position: 'absolute',
            top: '-0',
            right: '-3px',
            width: '.5rem',
            height: '.5rem',
            borderRadius: '50%',
            backgroundColor: '#D83D27',
          }}
        ></div>
      )}

      <i
        {...rest}
        onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname } })}
        className={`material-icons-outlined mx-1 ${disabled ? 'text-muted' : ''}`}
        style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
      >
        visibility
      </i>
    </div>
  </Tooltip>
)

interface AssignUserIconProps {
  tooltip: string
  path: string
  disabled?: boolean
}

export const AssignUserIcon = ({
  tooltip,
  path,
  disabled,
  property,
  ...rest
}: AssignUserIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={tooltip}
  >
    <i
      {...rest}
      onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname, prop: property } })}
      className={`material-icons mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      person_add
    </i>
  </Tooltip>
)

interface CustomIconProps {
  icon: string
  tooltip: string
  path: string
  disabled?: boolean
  iconCategory?: 'outlined'
}

export const CustomIcon = ({
  tooltip,
  path,
  disabled,
  icon,
  iconCategory,
  ...rest
}: CustomIconProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <Tooltip
    placement="top"
    title={tooltip}
  >
    <i
      {...rest}
      onClick={() => !disabled && navigate(path, { state: { prevPath: location.pathname } })}
      className={`material-icons${iconCategory ? `-${iconCategory}` : ''} mx-1 ${disabled ? 'text-muted' : ''}`}
      style={{ cursor: disabled ? 'inherit' : 'pointer', fontSize: '1.5rem' }}
    >
      {icon}
    </i>
  </Tooltip>
)

export const SpinnerCell = () => (
  <td className="align-middle text-center">
    <SpinnerCenter size="sm" />
  </td>
)

interface ThumbnailCellProps {
  src?: string
  fallbackJSX?: JSX.Element
}

export const ThumbnailCell = ({ src, fallbackJSX }: ThumbnailCellProps) => (
  <td>
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {src ? (
        <img
          style={{
            objectFit: 'contain',
          }}
          className="thumbnail"
          src={src}
        ></img>
      ) : (
        fallbackJSX
      )}
    </Box>
  </td>
)

interface ActionsMenuButtonProps {
  startDecorator: ReactNode
  disabled?: boolean
  onClick?: () => void
  label: string
}

interface ActionsMenuProps {
  items: ActionsMenuButtonProps[]
}

export const ActionsMenu = ({ items }: ActionsMenuProps) => (
  <Dropdown>
    <MenuButton variant="plain">...</MenuButton>
    <Menu sx={{ zIndex: '9999' }}>
      {items.map((item, index) => (
        <MenuItem
          disabled={item.disabled}
          key={index}
          onClick={() => !item.disabled && item.onClick?.()}
        >
          <ListItemDecorator>{item.startDecorator}</ListItemDecorator>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  </Dropdown>
)

export const ActionsMenuCell = ({ items }: ActionsMenuProps) => (
  <td>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ActionsMenu items={items} />
    </Box>
  </td>
)
