import React, { useContext, useEffect } from 'react'
import { ClientContext } from '@components/ClientContextWrapper'
import { authRoles } from '@constants'
import { useAppDispatch } from '@redux/reduxHooks'
import { setClient } from '@slices/userSlice'
import { useSession } from '@hooks/useSession'
import { Role } from '@types'
import SpinnerCenter from './SpinnerCenter'
import { Option, Select } from '@mui/joy'

interface Props {
  disabled?: boolean
  hidden?: boolean
  fullwidth?: boolean
}

const ClientSelect = ({ disabled, hidden }: Props) => {
  const { setSelectedClient, setSelectedClientDetails } = useContext(ClientContext)
  const { client, user } = useSession()

  const dispatch = useAppDispatch()

  const allowedClients = user?.allowed_clients // Retrieved clients restricted from backend

  const handleClientSelect = (id: number | string) => {
    const client = allowedClients?.find((client) => client.id === Number(id))
    setSelectedClient(id)
    setSelectedClientDetails(client ?? null)
    sessionStorage.setItem('clientId', id.toString())
    dispatch(setClient(client))
  }

  useEffect(() => {
    if (allowedClients?.length && !client)
      handleClientSelect(sessionStorage.getItem('clientId') ?? allowedClients[0]?.id)
  }, [user])

  return (
    <>
      {!user ? (
        <div className="d-flex justify-content-center">
          <SpinnerCenter
            hidden={hidden}
            className="mt-4"
          />
        </div>
      ) : !authRoles.ADMIN.includes((user?.role as Role)?.name) && allowedClients?.length === 1 ? (
        <div
          className="text-center"
          style={{ color: 'white' }}
        >
          <strong>{`${allowedClients?.[0]?.name}`}</strong>
        </div>
      ) : (
        <Select
          sx={{
            visibility: hidden ? 'hidden' : 'visible',
          }}
          value={client?.id ?? 0}
          disabled={disabled}
          onChange={(_, value) => {
            handleClientSelect(value!)
          }}
        >
          {!allowedClients?.length && <option value={''}></option>}
          {allowedClients?.map((client) => (
            <Option
              key={`section-${client.id}`}
              value={client.id}
            >{`${client.id} - ${client.name}`}</Option>
          ))}
        </Select>
      )}
    </>
  )
}

export default ClientSelect
