import { Tenant, User, News, NewsTags } from '@types'

export const serializeData = (data: any) => {
  const formData = new FormData()
  for (let key in data) {
    if (key === 'files') Array.from(data[key]).forEach((file: any) => formData.append(key, file))
    else if (data[key] !== undefined && data[key] !== null)
      formData.append(
        key,
        data[key] instanceof Blob || typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key]),
      )
  }
  return formData
}

export const getByUidMutation = ({ uid }: { uid: string }) => ({
  url: `core/usuarios/get_by_uid/`,
  method: 'POST',
  body: { uid },
})

export const buildSiteMutation = ({ siteId, clientId: client }: { siteId: number; clientId: number }) => ({
  url: `site/sitios/buildsite/${siteId}/`,
  method: 'POST',
  body: { client },
})

export const getAllQuery = (url: string) => () => ({
  url: `${url}/`,
  method: 'GET',
  params: { format: 'json' },
})

export const getAllByClientIdQuery =
  (url: string) =>
  ({ clientId: client }: { clientId: number }) => ({
    url: `${url}/`,
    method: 'GET',
    params: { format: 'json', client },
  })

export const getByIdQuery =
  (endpoint: string) =>
  ({ id }: { id: number }) =>
    `${endpoint}/${id}/?format=json`

export const getByIdAndClientIdQuery =
  (url: string) =>
  ({ id, clientId: client }: { id: number; clientId: number }) => ({
    url: `${url}/${id}/`,
    method: 'GET',
    params: { format: 'json', client },
  })

export const deleteByIdMutation =
  (endpoint: string) =>
  ({ id }: { id: number }) => ({
    url: `${endpoint}/${id}/?hard_delete=true`,
    method: 'DELETE',
  })

export const deleteByEmailAndClientMutation =
  (endpoint: string) =>
  ({ email, client }: { client: number; email: string }) => ({
    url: `${endpoint}/`,
    method: 'DELETE',
    params: { client, email },
  })

export const createOneMutation =
  (endpoint: string) =>
  ({ data, params }: { data: Partial<Tenant> | Partial<User> | Partial<News> | Partial<NewsTags>; params?: any }) => ({
    url: `${endpoint}/`,
    method: 'POST',
    body: serializeData(data),
    params,
  })

export const updateByIdMutation =
  (endpoint: string) =>
  ({ id, data }: { id: number; data: Partial<Tenant> | Partial<User> | Partial<News> | Partial<NewsTags> }) => ({
    url: `${endpoint}/${id}/`,
    method: 'PATCH',
    body: serializeData(data),
  })

export const getActiveActionMutation = ({
  client,
  section,
  type,
  properties,
  developments,
}: {
  client: number
  section: string
  type: string
  properties?: number[]
  developments?: number[]
}) => ({
  url: `push/acciones/get_activa/`,
  method: 'GET',
  params: { client, section, type, properties, developments },
})

export const createInvestorMutation = ({
  client,
  email,
  name,
  extra_info,
  phones,
  ownerships,
}: {
  client: number[]
  email: string
  name: string
  extra_info?: string
  phones?: number
  ownerships?: number[]
  // is_active?: boolean
}) => ({
  url: `investor/inversores/`,
  method: 'POST',
  body: serializeData({ client, email, name, extra_info, phones, ownerships }),
})

export const deleteInvestorMutation = ({ email, client }: { client: number; email: string }) => ({
  url: `investor/inversores/`,
  method: 'DELETE',
  params: { client, email },
})

export const getAllWithParamsQuery = (endpoint: string) => (params: any) => ({
  url: `${endpoint}/?format=json`,
  method: 'GET',
  params,
})

export const pkSubEndpointMutation =
  (endpoint: string, subendpoint: string) =>
  ({ id, data }: { id: number; data: any }) => ({
    url: `${endpoint}/${id}/${subendpoint}/`,
    method: 'POST',
    body: serializeData(data),
  })

export const getAssignedModuleMutation = ({ client, modules }: { client: number; modules: number[] }) => ({
  url: `core/custom-login/activas/`,
  method: 'GET',
  params: { client, modules },
})

export const getPublicNewsDetailByClientIdQuery =
  (endpoint: string) =>
  ({ clientId: client, id }: { clientId: number; id: number }) => ({
    url: `${endpoint}/${id}/get_data_detail/`,
    method: 'GET',
    params: { format: 'json', client },
  })
