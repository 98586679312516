import { configureStore } from '@reduxjs/toolkit'
import { mediacoreApi } from '@apis/mediacoreApi'
import { setupListeners } from '@reduxjs/toolkit/query'
import { tableConfigsSlice } from '@slices/tableConfigsSlice'
import { userSlice } from '@slices/userSlice'

export const store = configureStore({
  reducer: {
    [tableConfigsSlice.name]: tableConfigsSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [mediacoreApi.reducerPath]: mediacoreApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      mediacoreApi.middleware, //
    ]),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Configure Redux with Typescript following official recommendations, see:
// https://redux-toolkit.js.org/tutorials/typescript
// Configure RTK Query, see:
// https://redux-toolkit.js.org/rtk-query/overview
