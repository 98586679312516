import { Box, Button, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy'
import React from 'react'

interface ModalProps {
  header: string
  body: string
  show: boolean
}

interface Props {
  modal: ModalProps
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
}

const InformationModal = ({ modal, setModal }: Props) => {
  return (
    <Modal
      open={modal.show}
      onClose={() => setModal((prevState) => ({ ...prevState, show: false }))}
    >
      <ModalDialog sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DialogTitle>{modal.header}</DialogTitle>
        <ModalClose />
        <DialogContent>{modal.body}</DialogContent>

        <Box >
          <Button
            sx={{float: 'right'}}
            onClick={() => {
              setModal((prevState) => ({ ...prevState, show: false }))
            }}
          >
            Aceptar
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}

export default InformationModal
