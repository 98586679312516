import React, { useContext, useEffect, useState } from 'react'
import { auth } from '@firebaseUtils/firebase'
import { signOut } from 'firebase/auth'
import { UserContext } from '@components/Auth'
import { Link, navigate } from 'gatsby'
import ClientSelect from '@components/ClientSelect'
import { mediacoreApi } from '@apis/mediacoreApi'
import { ClientContext } from '@components/ClientContextWrapper'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import {
  useDeleteNotificationMutation,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from '@endpoints/notificationsEndpoint '
import Notifications from '@mui/icons-material/Notifications'
import NotificationsActive from '@mui/icons-material/NotificationsActive'
import Badge from '@mui/joy/Badge'
import ModalClose from '@mui/joy/ModalClose'
import Drawer from '@mui/joy/Drawer'
import Box from '@mui/material/Box'
import Typography from '@mui/joy/Typography'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import { globalStyles } from '@styles/styles'
import Avatar from '@mui/joy/Avatar'
import { IconButton, Input, Skeleton, Switch, Tooltip } from '@mui/joy'
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles'
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles'
import { changeUserColor, toggleGradientComponents, toggleCustomPalette } from '@slices/userSlice'
import {
  AccountCircleOutlined,
  PowerSettingsNewOutlined,
  KeyboardArrowDown,
  ConfirmationNumberOutlined,
  Close,
  MarkEmailUnreadOutlined,
  DraftsOutlined,
  DeleteOutline,
} from '@mui/icons-material'
import { useSession } from '@hooks/useSession'
import { SupportTicketsTable } from '@components/tables/SupportTicketsTable'
import { Module } from '@types'
import NotificationsSettings from '@components/NotificationsSettings'
import DropdownMenuItem from '@components/DropdownMenuItem'
import MyProfile from '@components/MyProfile'
import { DrawerTitle } from '@components/tables/UserCommunicationsTable'

const isBrowser = typeof window !== 'undefined'

interface DrawerState {
  open: boolean
  id?: number
}

interface Props {
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  defaultDrawerState?: DrawerState
}

const TopAndBottomNavBar = ({ setShowMobileMenu, defaultDrawerState }: Props) => {
  const dispatch = useAppDispatch()
  const userState = useAppSelector((state) => state.user)
  const { client } = useSession()
  const { userData } = useContext(UserContext)

  const [drawer, setDrawer] = useState<DrawerState>(defaultDrawerState ?? { open: false })
  const [notificationsDrawer, setNotificationsDrawer] = useState<DrawerState>({ open: false })
  const [myProfileDrawer, setMyProfileDrawer] = useState<DrawerState>({ open: false })

  const { setSelectedClient, setSelectedClientDetails, selectedClientDetails } = useContext(ClientContext)
  const { data: allNotifications } = useGetNotificationsQuery(undefined, {
    skip: userData.status !== 'fulfilled',
    pollingInterval: 30000,
  })

  const clientNotifications = allNotifications?.filter(
    (notification) => notification.client === Number(selectedClientDetails?.id),
  )
  const [updateNotification, { isLoading: _isUpdating }] = useUpdateNotificationMutation()
  const [deleteNotification, { isLoading: _isDeleting }] = useDeleteNotificationMutation()
  const [showOverlay, setShowOverlay] = useState(false)
  const [showClientMenu, setShowClientMenu] = useState(false)

  const disableClientSelection = // Client change must be disabled when editing existing elements. Otherwise, client reassignment may occur.
    (isBrowser && location.pathname.includes('destaques/editar')) ||
    (isBrowser && location.pathname.includes('novedades/editar')) ||
    (isBrowser && location.pathname.includes('emprendimientos/editar'))

  const newNotifications = clientNotifications?.filter(({ status }) => status === 'NEW')

  const mainStyles = {
    clientSelect: {
      width: 334.48,
    },
    notificationsIcon: {
      width: 92.35,
    },
  }

  const styles = {
    navBar: {
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
      // backgroundColor: globalStyles.defaultWhite,
      position: 'sticky',
      top: 0,
      zIndex: 1020,
    },
    homeIcon: {
      display: { xs: 'none', sm: 'flex' },
      width: 92.35,
      borderRight: globalStyles.border,
    },
    notificationsIcon: {
      width: mainStyles.notificationsIcon.width,
      borderRight: globalStyles.border,
      borderLeft: globalStyles.border,
    },
    notificationsDrawer: {
      global: {
        width: mainStyles.clientSelect.width + mainStyles.notificationsIcon.width,
      },
      title: {
        paddingX: 3,
        height: globalStyles.navBarHeight,
        borderBottom: globalStyles.border,
      },
      modalClose: {
        position: 'relative',
        right: 0,
        top: 0,
      },
      box: {
        paddingX: 4,
        pb: 3,
      },
      card: {
        width: 366.83,
        borderRadius: '8px',
        marginBottom: 2,
        border: globalStyles.border,
        p: 2,
      },
    },
    moduleSelect: {
      display: { xs: 'none', sm: 'flex' },
      width: 307.83,
      borderRight: globalStyles.border,
    },
    clientSelect: {
      width: mainStyles.clientSelect.width,
    },
    clientMenuIcons: {
      borderRadius: globalStyles.borderRadius,
      justifyContent: 'start',
      px: '0.5rem',
      py: '0.75rem',
    },
    avatar: {
      background: userState.gradientComponents ? globalStyles.gradientColor() : 'var(--joy-palette-primary-solidBg)',
      color: 'white',
    },
    clientDrawer: {
      title: {
        paddingX: 2,
        height: globalStyles.navBarHeight,
        width: mainStyles.clientSelect.width,
        borderBottom: globalStyles.border,
      },
      modalClose: {
        position: 'relative',
        right: 0,
        top: 0,
      },
      box: {
        paddingX: 2,
        paddingY: 2,
      },
    },
    titleBar: {
      height: 2 * globalStyles.navBarHeight,
      padding: 3,
    },
    title: {
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    },
    modalClose: {
      position: 'relative',
      right: 0,
      top: 0,
    },
    formContainer: {
      overflowY: 'auto',
      height: `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
    },
  }
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme()
  const { setMode: setJoyMode } = useJoyColorScheme()
  const [dropdownLabels, setDropdownLabels] = useState<{ label: string; path: string }[]>([])

  useEffect(() => {
    setDropdownLabels([
      {
        label: 'MediaBlog',
        path: '/mediablog',
      },
      {
        label: 'MediaInvestor',
        path: '/mediainvestor',
      },
      {
        label: 'MediaOwner',
        path: '/mediaowner',
      },
      {
        label: 'MediaTracker',
        path: '/mediatracker',
      },
      {
        label: 'MediaPush',
        path: '/mediapush',
      },
      {
        label: 'MediaJobs',
        path: '/mediajobs',
      },
    ])
  }, [])

  const hasMediaSiteModule = selectedClientDetails?.modules?.some((module: Module) => module.code === 'SITE')

  const handleDeleteAllNotifications = () => {
    if (clientNotifications) {
      const notificationIds = clientNotifications.map((notification) => notification.id)
      notificationIds.forEach((id) => deleteNotification({ id }))
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.navBar}
      >
        <Stack
          direction="row"
          sx={{ height: styles.navBar.height }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={styles.moduleSelect}
          >
            <Button
              variant="plain"
              endDecorator={<KeyboardArrowDown sx={{ color: 'var(--joy-palette-text-primary)' }} />}
              onClick={() => setShowMobileMenu((value) => !value)}
            >
              <Typography
                level="h4"
                fontWeight="400"
              >
                {isBrowser
                  ? dropdownLabels.find(({ path }) => location?.pathname?.startsWith(path))?.label ?? 'Inicio'
                  : 'Inicio'}
              </Typography>
            </Button>
          </Stack>
        </Stack>

        <ClientSelect
          hidden
          disabled={disableClientSelection}
        />

        <Stack
          direction="row"
          sx={{ height: styles.navBar.height }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ ...styles.notificationsIcon }}
          >
            <Button
              variant="plain"
              sx={{ color: globalStyles.secondaryTextColor }}
              onClick={() => setShowOverlay((value) => !value)}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color="danger"
                size="sm"
                badgeContent={newNotifications?.length ?? 0}
              >
                {newNotifications?.length ? <NotificationsActive /> : <Notifications />}
              </Badge>
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ ...styles.clientSelect }}
          >
            <Button
              startDecorator={
                <Avatar sx={styles.avatar}>
                  {selectedClientDetails?.name.slice(0, 1)}
                  <Skeleton loading={!client?.id} />
                </Avatar>
              }
              endDecorator={<KeyboardArrowDown />}
              variant="plain"
              onClick={() => setShowClientMenu(true)}
            >
              <Typography
                level="h4"
                color="neutral"
                fontWeight="400"
              >
                <Skeleton loading={!client?.id}>{selectedClientDetails?.name ?? 'Lorem ipsum dolor sit'}</Skeleton>
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Drawer
        anchor="right"
        open={showOverlay}
        onClose={() => setShowOverlay(false)}
        slotProps={{
          content: {
            style: { width: styles.notificationsDrawer.global.width },
          },
        }}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={styles.notificationsDrawer.title}
        >
          <Typography level="h3">Notificaciones</Typography>
          <ModalClose
            size="lg"
            sx={styles.notificationsDrawer.modalClose}
            onClick={() => setShowOverlay(false)}
          />
        </Stack>
        {clientNotifications?.length ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ px: 4.5, pt: 2, pb: 1 }}
          >
            <Typography level="title-lg-light">Bandeja principal</Typography>
            <IconButton onClick={handleDeleteAllNotifications}>
              {/* // To-do: Eliminar todas desde el back */}
              <Typography
                level="title-lg-light"
                startDecorator={<DeleteOutline />}
              >
                Eliminar todas
              </Typography>
            </IconButton>
          </Stack>
        ) : (
          <></>
        )}
        <Box sx={styles.notificationsDrawer.box}>
          {clientNotifications?.length ? (
            clientNotifications?.map((notification) => (
              <Box
                key={notification.id}
                sx={{
                  ...styles.notificationsDrawer.card,
                  opacity: notification.status === 'NEW' ? '100%' : '60%',
                  cursor: notification.status === 'NEW' ? 'pointer' : '',
                }}
                onClick={() =>
                  notification.status === 'NEW'
                    ? updateNotification({
                        id: notification.id,
                        data: { status: 'READ' },
                      })
                    : () => {}
                }
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography level="h3">{notification.title}</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Tooltip title={`Marcar como ${notification.status !== 'NEW' ? 'no ' : ' '}leído`}>
                      <IconButton
                        onClick={() =>
                          updateNotification({
                            id: notification.id,
                            data: { status: notification.status === 'NEW' ? 'READ' : 'NEW' },
                          })
                        }
                      >
                        {notification.status === 'NEW' ? <MarkEmailUnreadOutlined /> : <DraftsOutlined />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton onClick={() => deleteNotification({ id: notification.id })}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Typography
                  level="body-md-light"
                  sx={{ mt: 1 }}
                >
                  {notification.message}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 1 }}
                >
                  {Boolean(notification.path) &&
                    (notification?.path?.includes('/mediasite') ? hasMediaSiteModule : true) && (
                      <Button
                        onClick={() => {
                          navigate(notification.path)
                          setShowOverlay(false)
                        }}
                      >
                        {notification.text_link}
                      </Button>
                    )}
                  <Typography level="body-sm">{new Date(notification.created_at).toLocaleString()}</Typography>
                </Stack>
              </Box>
            ))
          ) : (
            <Typography
              level="body-md-light"
              sx={{ mt: 2 }}
            >
              No hay notificaciones.
            </Typography>
          )}
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={showClientMenu}
        onClose={() => setShowClientMenu(false)}
        slotProps={{
          content: {
            style: { width: styles.clientDrawer.title.width },
          },
        }}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={styles.clientDrawer.title}
        >
          <Stack
            gap={2}
            justifyContent="start"
            direction="row"
            alignItems="center"
          >
            <Avatar
              size="lg"
              sx={styles.avatar}
            >
              {selectedClientDetails?.name.slice(0, 1)}
            </Avatar>
            <Stack>
              <Typography level="h3">{selectedClientDetails?.name}</Typography>
              <Typography level="title-md">{selectedClientDetails?.email}</Typography>
            </Stack>
          </Stack>
          <ModalClose
            size="lg"
            sx={styles.clientDrawer.modalClose}
            onClick={() => setShowClientMenu(false)}
          />
        </Stack>
        <Stack sx={styles.clientDrawer.box}>
          <DropdownMenuItem
            label="Perfil"
            items={[
              {
                label: 'Editar mi perfil',
                path: '',
                onClick: () => {
                  setMyProfileDrawer({ open: true }), setShowClientMenu(false)
                },
              },
              {
                label: 'Notificaciones',
                path: '',
                onClick: () => {
                  setNotificationsDrawer({ open: true }), setShowClientMenu(false)
                },
              },
            ]}
            icon={
              <AccountCircleOutlined
                sx={{
                  mr: 1,
                }}
              />
            }
            menuIconsStyle={styles.clientMenuIcons}
          />
          <Button
            variant="plain"
            color="neutral"
            sx={styles.clientMenuIcons}
          >
            <Link
              style={{ textDecoration: 'none', color: 'var(--joy-palette-neutral-softColor)' }}
              to=""
              onClick={() => {
                setDrawer({ open: true }), setShowClientMenu(false)
              }}
            >
              <Typography
                startDecorator={
                  <ConfirmationNumberOutlined
                    sx={{
                      mr: 1,
                    }}
                  />
                }
                level="title-lg-light"
              >
                Ticket de soporte
              </Typography>
            </Link>
          </Button>
          <Button
            variant="plain"
            color="neutral"
            sx={styles.clientMenuIcons}
            onClick={() => {
              setSelectedClient('')
              setSelectedClientDetails(null)
              dispatch(mediacoreApi.util.resetApiState())
              signOut(auth)
            }}
          >
            <Typography
              startDecorator={
                <PowerSettingsNewOutlined
                  sx={{
                    mr: 1,
                  }}
                />
              }
              level="title-lg-light"
            >
              Cerrar sesión
            </Typography>
          </Button>
          <Stack sx={{ my: 2 }}>
            <ClientSelect
              fullwidth
              disabled={disableClientSelection}
            />
          </Stack>
          <Typography
            sx={{ pt: 2 }}
            component={'span'}
            startDecorator={
              <Switch
                checked={mode === 'dark'}
                onClick={() => {
                  setMaterialMode(mode === 'dark' ? 'light' : 'dark')
                  setJoyMode(mode === 'dark' ? 'light' : 'dark')
                }}
              ></Switch>
            }
          >
            Modo oscuro
          </Typography>
          <Typography
            sx={{ pt: 1 }}
            component={'span'}
            startDecorator={
              <Switch
                checked={userState.gradientComponents}
                onClick={() => dispatch(toggleGradientComponents())}
              ></Switch>
            }
          >
            Usar gradiente de color (beta)
          </Typography>{' '}
          <Typography
            sx={{ py: 1 }}
            component={'span'}
            startDecorator={
              <Switch
                checked={userState.customPalette}
                onClick={() => dispatch(toggleCustomPalette())}
              ></Switch>
            }
          >
            Usar paleta personalizada (beta)
          </Typography>
          {userState.customPalette && (
            <Input
              id="color-picker"
              type="color"
              value={userState.color}
              title="Color de la aplicación"
              onChange={(e) => {
                e.persist()
                dispatch(changeUserColor(e.target.value))
              }}
            />
          )}
        </Stack>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.open}
        onClose={() => setDrawer({ open: false })}
        slotProps={{
          content: {
            sx: {
              width: { xs: '100vw', md: '900px' },
            },
          },
        }}
      >
        <DrawerTitle title="Ticketera de soporte" />
        <Stack
          id="titleBar"
          sx={styles.titleBar}
        >
          <Typography
            level="h2"
            color="primary"
          >
            Tickets
          </Typography>
          <Typography level="body-md-light">Ver y responder consultas de clientes</Typography>
        </Stack>
        <Box
          sx={styles.formContainer}
          key={drawer.id}
        >
          {Boolean(drawer.open) && (
            <SupportTicketsTable
              isTableDrawerOpen={drawer.open}
              key={drawer.id}
            />
          )}
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={notificationsDrawer.open}
        onClose={() => setNotificationsDrawer({ open: false })}
        slotProps={{
          content: {
            sx: {
              width: { xs: '100vw', md: '900px' },
            },
          },
        }}
      >
        <DrawerTitle title="Notificaciones" />
        <Stack
          id="titleBar"
          sx={styles.titleBar}
        >
          <Typography
            level="h2"
            color="primary"
          >
            Configurá tus notificaciones
          </Typography>
          <Typography level="body-md-light">
            Seleccioná los medios por los cuales quieras recibir tus notificaciones de la plataforma.
          </Typography>
        </Stack>
        <Box
          sx={styles.formContainer}
          key={notificationsDrawer.id}
        >
          {Boolean(notificationsDrawer.open) && <NotificationsSettings />}
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={myProfileDrawer.open}
        onClose={() => setMyProfileDrawer({ open: false })}
        slotProps={{
          content: {
            sx: {
              width: { xs: '100vw', md: '900px' },
            },
          },
        }}
      >
        <DrawerTitle title="Edición perfil" />
        <Box
          sx={styles.formContainer}
          key={myProfileDrawer.id}
        >
          {Boolean(myProfileDrawer.open) && <MyProfile />}
        </Box>
      </Drawer>
    </>
  )
}

export default TopAndBottomNavBar
