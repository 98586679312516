import React, { useState } from 'react'
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap'

interface Props {
  value: number
  disableSelection?: boolean
  onChange?: (rating: number) => void
  starStyle?: React.CSSProperties
  tooltipDisabled?: boolean
}

/**
 * @name StarRating
 * @category Components
 * @description Five selectable stars for rating
 * @param value Rating to show
 * @param disableSelection Boolean to make stars non-selectables
 * @param onChange Triggered when stars are selected
 * @example
 * <StarRating
 * value={ratingValue}
 * disableSelection={false}
 * onChange={(rating)=> setRatingValue(rating))}
 * />
 */

const StarRating = ({ value, disableSelection, onChange, starStyle, tooltipDisabled }: Props) => {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null)

  const handleStarHover = (index: number) => {
    if (!!onChange) {
      setHoveredStar(index + 1)
    }
  }
  const roundToHalf =
    value - Math.floor(value) <= 0.25
      ? Math.floor(value)
      : value - Math.floor(value) <= 0.75
      ? Math.floor(value) + 0.5
      : Math.floor(value) + 1

  const getStarType = (index: number) => {
    if (hoveredStar !== null) {
      if (index + 1 <= hoveredStar) {
        return 'star'
      } else {
        return 'star_outline'
      }
    }
    const integerPart = Math.floor(roundToHalf)
    const decimalPart = roundToHalf - integerPart
    return decimalPart === 0.5
      ? index + 1 === integerPart + 1
        ? 'star_half'
        : index + 1 <= integerPart
        ? 'star'
        : 'star_outline'
      : index + 1 <= integerPart
      ? 'star'
      : 'star_outline'
  }

  return (
    <OverlayTrigger
      //   placement={placement}
      overlay={
        !tooltipDisabled ? (
          <Tooltip>
            Valoración: <strong>{roundToHalf}</strong>
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <Stack direction="horizontal">
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            className="StarRating"
            onClick={() => {
              if (!disableSelection) {
                onChange ? onChange(index + 1) : null
              }
              return
            }}
            onMouseEnter={() => handleStarHover(index)}
            onMouseLeave={() => setHoveredStar(null)}
          >
            <i
              key={`star-${index}`}
              className="material-icons"
              style={
                getStarType(index) === 'star_outline'
                  ? { color: '#9A9A9A40', ...starStyle }
                  : { color: '#ffb200', ...starStyle }
              }
            >
              {getStarType(index)}
            </i>
          </div>
        ))}
      </Stack>
    </OverlayTrigger>
  )
}

export default StarRating
