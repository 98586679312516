import { Tune } from '@mui/icons-material'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import { CircularProgress, Drawer } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import React, { ReactNode } from 'react'

interface LayoutProps {
  leftHeader?: React.ReactNode
  leftBody?: React.ReactNode
  rightHeader?: React.ReactNode
  rightBody?: React.ReactNode
  loaders?: {
    left?: boolean
    right?: boolean
  }
  hiddenLeftPanel?: boolean
  hiddenTopBar?: boolean
  customBodyHeight?: string
}

const Layout = ({
  leftHeader,
  leftBody,
  rightHeader,
  rightBody,
  loaders,
  hiddenLeftPanel,
  hiddenTopBar,
  customBodyHeight,
}: LayoutProps) => {
  const [open, setOpen] = React.useState(false)

  const handleToggleDrawer = () => {
    setOpen(!open)
  }

  const rightBodyHeight = {
    xs: customBodyHeight ?? `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
    sm: customBodyHeight ?? `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
  }

  const styles = {
    body: {
      maxHeight: `calc(100vh - ${globalStyles.navBarHeight}px)`,
    },
    leftPanel: {
      globalMobile: {
        minWidth: 307.83,
      },
      global: {
        display: { xs: 'none', sm: 'block' },
        minHeight: {
          xs: `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
          sm: `calc(100vh - ${globalStyles.navBarHeight}px)`,
        },
        minWidth: 307.83,
        borderRight: globalStyles.border,
      },
      header: {
        height: globalStyles.navBarHeight,
        borderBottom: globalStyles.border,
      },
      body: {
        position: 'relative',
        paddingX: 4,
        paddingY: 3,
        maxHeight: {
          xs: `calc(100vh - ${3 * globalStyles.navBarHeight}px)`,
          sm: `calc(100vh - ${2 * globalStyles.navBarHeight}px)`,
        },
        overflowY: 'auto',
      },
    },
    rightPanel: {
      header: {
        display: { xs: 'none', sm: 'block' },
        paddingX: 4,
        height: globalStyles.navBarHeight,
        borderBottom: globalStyles.border,
      },
      body: {
        position: 'relative',
        maxHeight: rightBodyHeight,
        minHeight: rightBodyHeight,
        overflowY: 'auto',
      },
    },
    bottomBar: {
      display: { xs: 'block', sm: 'none' },
      width: '100vw',
      height: globalStyles.navBarHeight,
      borderTop: globalStyles.border,
      position: 'absolute',
      bottom: 0,
      zIndex: 1021,
    },
  }

  const leftPanelContent = (
    <>
      {!hiddenTopBar && <Stack sx={styles.leftPanel.header}>{leftHeader}</Stack>}
      <Box sx={styles.leftPanel.body}>
        {leftBody}
        <Loader show={loaders?.left} />
      </Box>
    </>
  )

  return (
    <>
      <Stack
        sx={styles.body}
        direction="row"
      >
        {!hiddenLeftPanel && <Stack sx={styles.leftPanel.global}>{leftPanelContent}</Stack>}
        <Stack
          id="bottom-body"
          sx={{ width: '100%' }}
        >
          {!hiddenTopBar && (
            <Stack
              direction="row"
              sx={styles.rightPanel.header}
            >
              {rightHeader}
            </Stack>
          )}
          <Box sx={styles.rightPanel.body}>
            {rightBody}
            <Loader show={loaders?.right} />
          </Box>
        </Stack>
      </Stack>
      {true && (
        <Stack
          sx={styles.bottomBar}
          direction={'row'}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              height: globalStyles.navBarHeight,
              width: 92.35,
              borderRight: globalStyles.border,
            }}
          >
            <Button
              variant="plain"
              onClick={handleToggleDrawer}
            >
              <Tune fontSize="large" />
            </Button>
          </Stack>
        </Stack>
      )}
      {!hiddenLeftPanel && (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            content: {
              style: {
                width: styles.leftPanel.globalMobile.minWidth,
              },
            },
          }}
        >
          {open && <Stack sx={styles.leftPanel.globalMobile}>{leftPanelContent}</Stack>}
        </Drawer>
      )}
    </>
  )
}

interface LoaderProps {
  show?: boolean
}

export const Loader = ({ show }: LoaderProps) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'var(--mui-palette-text-primary)',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: show ? 0.3 : 0,
          zIndex: show ? 2 : -1,
          transition: 'opacity 0.3s',
          cursor: 'wait',
        }}
      ></Box>
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: 'opacity 0.3s',
          opacity: show ? 1 : 0,
          zIndex: show ? undefined : -1,
          cursor: 'wait',
        }}
      />
    </>
  )
}

export default Layout

export const FiltersBox = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        paddingX: 2,
        paddingY: 1,
        marginBottom: 2,
        border: globalStyles.border,
        borderRadius: globalStyles.borderRadius,
      }}
    >
      {children}
    </Box>
  )
}
