import { UserContext } from '@components/Auth'
import { external_websites_inquiries_options, inquiries_options } from '@constants'
import { useCreateReportMutation, useGetReportByIdQuery, useUpdateReportMutation } from '@endpoints/reportsEndpoint'
import { useGetTokkoPropertyTableQuery, useGetVisitRecordTableQuery } from '@endpoints/tablesEndpoint'
import { Report, ReportExternalWebsitesInquiry, ReportInquiry, TokkoProperties, VisitRecord } from '@types'
import { onlyUnique, updateErrorToast, updateSuccessToast } from '@utils'
import moment, { Moment } from 'moment'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  clientId: number
  handleShowForm: ({ show, type }: { show: boolean; type?: 'Novedad' | 'Tasación' | 'Reporte' | undefined }) => void
  id?: number
}

export const useReport = ({ clientId, id, handleShowForm }: Props) => {
  const { userData } = useContext(UserContext)
  const {
    data: queriedReport,
    isLoading: isLoadingQueriedReport,
    isError: isErrorQueriedReport,
    isFetching: _isFetchingReport,
  } = useGetReportByIdQuery({ id: id!, clientId }, { skip: userData.status !== 'fulfilled' || !clientId || !id })

  const {
    data: visitRecordQuery,
    isLoading: isLoadingVisitRecord,
    isError: isErrorVisitRecord,
    isFetching: isFetchingVisitRecord,
  } = useGetVisitRecordTableQuery({ clientId: clientId! }, { skip: userData.status !== 'fulfilled' || !clientId })

  const [updateReport, { isLoading: isUpdatingReport }] = useUpdateReportMutation()
  const [createReport, { isLoading: isCreatingReport }] = useCreateReportMutation()

  const blankReport: Partial<Report> = {
    tokko_property: undefined,
    content: {
      visualizedSteps: [0],
      inquiries: [{ source: inquiries_options[0], count: 0 }],
      external_websites_inquiries: [{ source: external_websites_inquiries_options[0], count: 0, visits_count: 0 }],
      fake_address: '',
      real_address: '',
    },
    is_active: false,
    visits: [],
    date_from: moment().format('YYYY-MM-DDThh:mm'),
    date_to: moment().format('YYYY-MM-DDThh:mm'),
  }
  const [report, setReport] = useState(blankReport)

  const {
    data: allProperties,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
    isFetching: isFetchingProperties,
  } = useGetTokkoPropertyTableQuery(
    { clientId: clientId, owners: true, ids: report.tokko_property ? [report.tokko_property] : [] },
    { skip: !clientId || !report.tokko_property },
  )

  useEffect(() => {
    if (queriedReport) {
      setReport(
        queriedReport
          ? {
              ...queriedReport,
              date_from: queriedReport.date_from ?? moment().format('YYYY-MM-DDThh:mm'),
              date_to: queriedReport.date_to ?? moment().format('YYYY-MM-DDThh:mm'),
              content: {
                ...queriedReport.content,
                visualizedSteps: queriedReport.content.visualizedSteps.map((step: number) => step - 1),
              },
            }
          : blankReport,
      )
    }
  }, [queriedReport])

  const propertyVisits =
    visitRecordQuery?.results?.filter((visit) => Number(visit.tokko_property) === report.tokko_property) ?? []

  const isLoadingReport = isLoadingQueriedReport || isLoadingProperties
  const isErrorReport = isErrorQueriedReport || isErrorProperties

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setReport((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleDateChange = (date: Moment | null, name: 'date_from' | 'date_to') => {
    setReport((prevData) => ({
      ...prevData,
      [name]: date?.format('YYYY-MM-DDThh:mm') ?? '',
    }))
  }

  const filteredByPeriodVisits = (visits: VisitRecord[], tokkoPropertyId: number | undefined) => {
    if (visits) {
      return visits.filter(
        (visit) =>
          visit.is_active &&
          visit.tokko_property == tokkoPropertyId &&
          moment(visit.date_visit).format('YYYY-MM-DD') >= moment(report.date_from).format('YYYY-MM-DD') &&
          moment(visit.date_visit).format('YYYY-MM-DD') <= moment(report.date_to).format('YYYY-MM-DD'),
      )
    }
    return []
  }
  const handleChangeProperty = (selectedProperty: Partial<TokkoProperties> | undefined, owners: number[]) => {
    const periodVisits = filteredByPeriodVisits(visitRecordQuery?.results ?? [], selectedProperty?.id)
    const visitsIds = periodVisits.map((visit) => visit.id)
    setReport((prevData) => ({
      ...prevData,
      tokko_property: Number(selectedProperty?.id),
      owners: owners ?? [],
      visits: visitsIds,
      content: {
        ...prevData.content,
        fake_address: selectedProperty?.fake_address ?? '',
        real_address: selectedProperty?.real_address ?? '',
      },
    }))
  }

  const handleInquiriesSourceChange = (value: string | null, name: string, index: number) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content[name][index].source = value
      return newData
    })
  }
  const handleInquiriesChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: string,
    index: number,
    keyValue: keyof ReportInquiry | keyof ReportExternalWebsitesInquiry,
  ) => {
    e.persist()
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content[name][index][keyValue] = keyValue.includes('count') ? Number(e.target.value) : e.target.value
      return newData
    })
  }
  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.persist) e.persist()
    setReport((prevData) => ({
      ...prevData,
      content: { ...prevData.content, [e.target.name]: e.target.value },
    }))
  }
  const removeInquiry = (index: number, inquiryType: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content[inquiryType].splice(index, 1)
      return newData
    })
  }
  const addInquiry = (name: string, blankInquiry: ReportInquiry | ReportExternalWebsitesInquiry) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content[name].push(structuredClone(blankInquiry))
      return newData
    })
  }
  const handleModalVisibility = (show: boolean) => {
    handleShowForm({ show })
  }
  const saveReport = (is_active: boolean) => {
    const data = {
      ...report,
      is_active,
    }

    const pendingToast = () => {
      return toast.loading(`${id ? 'Guardando' : 'Creando'} reporte ...`)
    }

    const toastId = pendingToast()
    ;(id
      ? updateReport({
          id,
          data,
        })
      : createReport({ data: { ...data, client: clientId } })
    )
      .unwrap()
      .then(() => {
        updateSuccessToast(toast, toastId, `Reporte ${id ? 'editado' : 'creado'} con éxito`)
        handleModalVisibility(false)
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo ${id ? 'editar' : 'crear'} el reporte`,
        )
      })
  }
  const markVisualizedStep = (step: number) => {
    if (!report?.content?.visualizedSteps?.includes(step))
      setReport((prevData) => ({
        ...prevData,
        content: {
          ...prevData.content,
          visualizedSteps: [...prevData?.content?.visualizedSteps, step],
        },
      }))
  }
  const isStepVisualized = (step: number) => !!report?.content?.visualizedSteps?.includes(step)
  const stepsCompleted = (() => {
    let isCompleted = [
      isStepVisualized(0),
      !!(isStepVisualized(id ? 0 : 1) && !!report && !!report.title && !!report?.tokko_property),
      isStepVisualized(id ? 1 : 2),
    ]
    if (id) {
      isCompleted.shift()
    }
    return isCompleted
  })()

  const addOwner = (userId: number) => {
    if (report?.owners?.includes(userId)) return
    setReport((prevData) => ({
      ...prevData,
      owners: [...(prevData?.owners ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setReport((prevData) => ({
      ...prevData,
      owners: users.every((user) => prevData?.owners?.includes(user))
        ? prevData?.owners?.filter((id) => !users.includes(id))
        : [...(prevData?.owners ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setReport((prevData) => ({
      ...prevData,
      owners: prevData?.owners?.filter((id) => id !== userId),
    }))
  }

  return {
    report,
    handleChange,
    handleDateChange,
    isLoadingReport,
    isErrorReport,
    handleChangeProperty,
    allProperties,
    handleInquiriesSourceChange,
    handleInquiriesChange,
    removeInquiry,
    addInquiry,
    handleContentChange,
    propertyVisits,
    isLoadingVisitRecord,
    isErrorVisitRecord,
    isFetchingVisitRecord,
    isCreatingReport,
    isUpdatingReport,
    saveReport,
    isFetchingProperties,
    markVisualizedStep,
    stepsCompleted,
    addOwner,
    toggleOwners,
    removeOwner,
  }
}
