import * as React from 'react'
import SpinnerWrapper from '@components/SpinnerWrapper'
import { globalStyles } from '@styles/styles'
import { DynamicTable, ThumbnailCell, sortablePlainTextCell } from '@components/tables/DynamicTable'
import { useAppSelector } from '@redux/reduxHooks'
import { useSession } from '@hooks/useSession'
import { useGetInvestorCommunicationTableQuery, useGetOwnerCommunicationTableQuery } from '@endpoints/tablesEndpoint'
import { StaticImage } from 'gatsby-plugin-image'
import { Drawer, Button, Typography, Stack, ModalClose, AspectRatio, Grid, FormLabel, LinearProgress } from '@mui/joy'
import { CalendarTodayOutlined, Download, East, HomeOutlined, PersonOutline, Place, Star } from '@mui/icons-material'
import { JoyStyles, UserCommunication } from '@types'
import { useOwnerNews } from '@hooks/useOwnerNews'
import RichTextEditor from '@components/RichTextEditor'
import { useAppraisal } from '@hooks/useAppraisal'
import moment from 'moment'
import { useEffect } from 'react'
import { Rating } from '@mui/material'
import { useReport } from '@hooks/useReport'

import { chartsColors, routes } from '@constants'
import {
  AmenitiesItem,
  ContentCarousel,
  InformationItem,
  PieCard,
  PreviewHeader,
  PreviewSection,
  PropertyStripe,
  RatingCard,
  ValuationCard,
} from '@components/PrevewComponents'
import { useInvestorNews } from '@hooks/useInvestorNews'

interface DrawerState {
  open: boolean
  id?: number
  type?: UserCommunication['type']
}
interface UserCommunicationsTableProps {
  defaultDrawerState?: DrawerState
  source: 'owner_view' | 'investor_view'
}

export const UserCommunicationsTable = ({
  defaultDrawerState = { open: false },
  source,
}: UserCommunicationsTableProps) => {
  const { client } = useSession()
  const isBrowser = typeof window !== 'undefined'
  const noTrailingSlashLocation = isBrowser ? location.pathname.replace(/\/$/, '') : ''
  const tableState = useAppSelector(
    (state) => state.tableConfigs[noTrailingSlashLocation as keyof typeof state.tableConfigs],
  )
  const { search = '', sorted = {}, filter = {} } = tableState ?? {}
  const skip = !client?.id

  const [drawer, setDrawer] = React.useState<DrawerState>(defaultDrawerState)

  const {
    data: communications,
    isFetching: isFetchingComuunications,
    isLoading: isLoadingComuunications,
    isError: isErrorComuunications,
  } = (source === 'owner_view' ? useGetOwnerCommunicationTableQuery : useGetInvestorCommunicationTableQuery)(
    {
      clientId: client?.id!,
      search: Number(search?.length) < 3 ? '' : search,
      sorted,
      filter,
      activeTab: 'all',
    },
    { skip },
  )

  const titles = {
    'Novedad de Propietarios': 'Novedades',
    'Novedad de Inversores': 'Novedades',
    Reporte: 'Reportes',
    Tasación: 'Tasación',
  }

  const [_location, setLocation] = React.useState('')

  useEffect(() => {
    const detailRoutes = {
      'Novedad de Propietarios': routes[source].owner_news_detail,
      'Novedad de Inversores': routes[source].investor_news_detail,
      Reporte: routes[source].report_detail,
      Tasación: routes[source].appraisal_detail,
    }
    window.history.replaceState(
      null,
      '',
      !drawer.open
        ? routes[source].communications //
        : detailRoutes[drawer.type!](drawer.id!),
    )
    setLocation(window.location.href)
  }, [drawer])

  return (
    <>
      <SpinnerWrapper
        style={{
          minHeight: `calc(100vh - ${globalStyles.navBarHeight}px)`,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        loading={isLoadingComuunications}
        error={isErrorComuunications}
      >
        <DynamicTable
          tableConfig={{
            thumbnail: { header: 'Portada' },
            communication_type: { header: 'Tipo' },
            title: { header: 'Título' },
            author: { header: 'Autor' },
            date: { header: 'Fecha/Período' },
            actions: { header: 'Acciones' },
          }}
          meta={communications?.meta}
          useBackendFilter
          options={{
            inactiveTabName: 'No activas (En borrador)',
            hiddenTabs: true,
            tableSelection: [
              {
                title: 'Comunicaciones',
                link: routes.owner_view.communications,
              },
            ],
          }}
          cards={communications?.results?.map((communication) => ({
            title: communication.title,
            date: communication.date,
            image: communication.thumbnail,
            fallbackJSX:
              communication.type === 'Tasación' ? (
                <StaticImage
                  src={'../../images/MediaOwner/communications/appraisal.png'}
                  alt=""
                />
              ) : communication.type === 'Reporte' ? (
                <StaticImage
                  src={'../../images/MediaOwner/communications/report.png'}
                  alt=""
                />
              ) : (
                <StaticImage
                  src={'../../images/MediaOwner/communications/news.png'}
                  alt=""
                />
              ),
            button: (
              <Button
                variant="outlined"
                color="neutral"
                sx={{
                  ':not(:hover)': {
                    color: 'white',
                  },
                }}
                endDecorator={<East fontSize="small" />}
                onClick={() => setDrawer({ open: true, id: communication.id, type: communication.type })}
              >
                Leer nota completa
              </Button>
            ),
          }))}
          rows={
            communications?.results?.map((communication) => ({
              content: {
                thumbnail: {
                  render: (
                    <ThumbnailCell
                      src={communication.thumbnail}
                      fallbackJSX={
                        communication.type === 'Tasación' ? (
                          <StaticImage
                            className="thumbnail"
                            src={'../../images/MediaOwner/communications/appraisal.png'}
                            alt=""
                          />
                        ) : communication.type === 'Reporte' ? (
                          <StaticImage
                            className="thumbnail"
                            src={'../../images/MediaOwner/communications/report.png'}
                            alt=""
                          />
                        ) : (
                          <StaticImage
                            className="thumbnail"
                            src={'../../images/MediaOwner/communications/news.png'}
                            alt=""
                          />
                        )
                      }
                    />
                  ),
                },
                communication_type: sortablePlainTextCell(communication.type),
                title: sortablePlainTextCell(communication.title, 50),
                author: sortablePlainTextCell(communication.author),
                date: sortablePlainTextCell(communication.date),
                actions: {
                  render: (
                    <td
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant="plain"
                        color="primary"
                        onClick={() => setDrawer({ open: true, id: communication.id, type: communication.type })}
                      >
                        <Typography endDecorator={<East fontSize="small" />}>Leer nota completa</Typography>
                      </Button>
                    </td>
                  ),
                },
              },
            })) ?? []
          }
          fetching={isFetchingComuunications}
        />
      </SpinnerWrapper>
      <Drawer
        anchor="right"
        open={drawer.open}
        onClose={() => setDrawer({ open: false })}
        slotProps={{
          content: {
            sx: {
              width: { xs: '100vw', md: '900px' },
            },
          },
        }}
      >
        {drawer.open && (
          <>
            <DrawerTitle title={titles[drawer.type!]} />
            {drawer.type === 'Novedad de Propietarios' && <OwnerNewsPreview id={drawer.id!} />}
            {drawer.type === 'Novedad de Inversores' && <InvestorNewsPreview id={drawer.id!} />}
            {drawer.type === 'Tasación' && <AppraisalPreview id={drawer.id!} />}
            {drawer.type === 'Reporte' && <ReportPreview id={drawer.id!} />}
          </>
        )}
      </Drawer>
    </>
  )
}

export const DrawerTitle = ({ title }: { title: string }) => (
  <Stack
    justifyContent="space-between"
    direction="row"
    alignItems="center"
    sx={{
      paddingX: 3,
      minHeight: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    }}
  >
    <Typography level="h3">{title}</Typography>
    <ModalClose size="lg" />
  </Stack>
)

interface PreviewProps {
  id: number
}

export const OwnerNewsPreview = ({ id }: PreviewProps) => {
  const { client } = useSession()
  const { ownerNews, loading } = useOwnerNews({
    id,
    clientId: client?.id,
    handleShowForm: () => {},
  })
  return (
    <SpinnerWrapper loading={loading}>
      <>
        <PreviewHeader
          title={ownerNews.title}
          headline={ownerNews.headline}
          date={new Date(ownerNews.date_published ?? '').toLocaleDateString()}
        />
        <Stack
          sx={{
            paddingX: 3,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
          }}
        >
          {Boolean(ownerNews.image) && (
            <AspectRatio>
              <img
                src={ownerNews.image as string}
                alt=""
              />
            </AspectRatio>
          )}
          <PreviewSection title="Información general">
            <RichTextEditor
              noToolbar
              readOnly
              content={ownerNews.content ?? ''}
              placeholder={''}
            />
          </PreviewSection>
          <PreviewSection title="Archivos para descargar">
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
            >
              {ownerNews.files_data?.map(({ file }, index) => (
                <Button
                  component="a"
                  key={index}
                  variant="plain"
                  color="primary"
                  href={file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    startDecorator={<Download fontSize="small" />}
                    level="body-md-light"
                  >
                    {(file as string).substring((file as string).lastIndexOf('/') + 1)}
                  </Typography>
                </Button>
              ))}
            </Stack>
          </PreviewSection>
        </Stack>
      </>
    </SpinnerWrapper>
  )
}

export const AppraisalPreview = ({ id }: PreviewProps) => {
  const { client } = useSession()
  const { appraisal, isFetchingAppraisal } = useAppraisal({
    id,
    clientId: client?.id!,
    handleShowForm: () => {},
  })
  const noUnspecified = (value: string) => value !== 'Sin especificar' && value
  const mapCenter = {
    lat: Number(appraisal.content.geo_lat ?? -34.60373472685532),
    lng: Number(appraisal.content.geo_long ?? -58.3815675939131),
  }

  const currentPropertyStripe = (
    <PropertyStripe
      image={appraisal.images_data?.[0]?.file as string}
      title={appraisal.content.real_address}
      subtitle={`${appraisal.content.floor} - ${appraisal.content.apartment}`}
      city={appraisal.content.city}
      state={appraisal.content.state}
      country={appraisal.content.country}
      age={appraisal.content.age}
      computable_total_surface={(
        Number(appraisal.content.roofed_surface ?? 0) +
        Number(appraisal.content.semiroofed_surface ?? 0) * 0.5 +
        Number(appraisal.content.unroofed_surface ?? 0) * 0.3
      ).toString()}
      roofed_surface={appraisal.content.roofed_surface}
      semiroofed_surface={appraisal.content.semiroofed_surface}
      unroofed_surface={appraisal.content.unroofed_surface}
      room_amount={appraisal.content.room_amount}
      suite_amount={appraisal.content.suite_amount}
      bathroom_amount={appraisal.content.bathroom_amount}
      parking_lot_amount={appraisal.content.parking_lot_amount}
      extra_amenities={appraisal.content.extra_amenities}
      square_meter_value={'-'}
      tokko_valuation_price={'-'}
    />
  )

  const styles: JoyStyles = {
    content: {
      padding: 3,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
    },
    generalInfoIcon: {
      width: 45,
      borderRadius: '50%',
      border: '1px solid var(--joy-palette-primary-plainDisabledColor)',
    },
    imageLegend: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      paddingY: 2,
      textAlign: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
    },
    comparisonContent: {
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      paddingX: 2,
      scrollBehavior: 'smooth',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }

  return (
    <SpinnerWrapper loading={isFetchingAppraisal}>
      <>
        <PreviewHeader
          title={appraisal.title}
          headline={'Te contamos un poco sobre esta propiedad'}
          date={new Date(appraisal.date_published ?? '').toLocaleDateString()}
        />
        <Stack sx={styles.content}>
          {Boolean(appraisal.images_data?.[0]) && (
            <AspectRatio>
              <img
                src={appraisal.images_data?.[0]?.file as string}
                alt=""
              />
            </AspectRatio>
          )}
          <PreviewSection title="Información general">
            <Grid
              container
              spacing={2}
            >
              {[
                {
                  label: 'Inmueble',
                  value: `${appraisal.content.real_address}, ${appraisal.content.neighborhood}, ${appraisal.content.state}`,
                  icon: <HomeOutlined color="disabled" />,
                },
                {
                  label: 'Fecha de realización',
                  value: moment(appraisal.content.created_at).locale('es').format('D [de] MMMM [de] YYYY'),
                  icon: <CalendarTodayOutlined color="disabled" />,
                },
                {
                  label: 'Preparado para',
                  value: ``,
                  icon: <PersonOutline color="disabled" />,
                },
                {
                  label: 'Realizado por',
                  value: appraisal.author,
                  icon: <PersonOutline color="disabled" />,
                },
              ].map(({ label, value, icon }) => (
                <Grid
                  key={label}
                  xs={6}
                  sm={3}
                >
                  <Stack
                    direction="row"
                    gap={2}
                  >
                    <Stack>
                      <AspectRatio
                        ratio="1"
                        variant="plain"
                        color="neutral"
                        sx={styles.generalInfoIcon}
                      >
                        <div>{icon}</div>
                      </AspectRatio>
                    </Stack>
                    <Stack>
                      <Typography level="title-lg-bold">{label}</Typography>
                      <Typography
                        level="body-md-light"
                        color="neutral"
                      >
                        {value}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </PreviewSection>
          <PreviewSection title="Descripción">
            <RichTextEditor
              noToolbar
              readOnly
              content={appraisal.content.description ?? ''}
              placeholder={''}
            />
          </PreviewSection>
          <PreviewSection title="Resumen">
            <ContentCarousel
              items={[
                {
                  title: 'Información',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Información'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {[
                          {
                            alwaysShow: false,
                            header: 'Tipo de propiedad',
                            value: appraisal.content.property_type?.join(', '),
                          },
                          { alwaysShow: false, header: 'Antigüedad', value: appraisal.content.age },
                          { alwaysShow: false, header: 'Orientación', value: appraisal.content.orientation },
                          { alwaysShow: false, header: 'Disposición', value: appraisal.content.disposition },
                          { alwaysShow: false, header: 'Luminosidad', value: appraisal.content.luminosity },
                          {
                            alwaysShow: false,
                            header: 'Estado de conservación',
                            value: appraisal.content.preservation_state,
                          },
                          {
                            alwaysShow: false,
                            header: 'Calidad de construcción',
                            value: appraisal.content.building_quality,
                          },
                          {
                            alwaysShow: false,
                            header: 'Expensas',
                            value: appraisal.content.expenses,
                            valueToShow: `${appraisal.content.expenses_currency} ${Number(
                              appraisal.content.expenses,
                            ).toLocaleString('es-AR')}`,
                          },
                          { alwaysShow: true, header: 'Ambientes', value: appraisal.content.room_amount },
                          { alwaysShow: true, header: 'Dormitorios', value: appraisal.content.suite_amount },
                          { alwaysShow: true, header: 'Baños', value: appraisal.content.bathroom_amount },
                          { alwaysShow: true, header: 'Toilettes', value: appraisal.content.toilet_amount },
                          { alwaysShow: true, header: 'Cocheras', value: appraisal.content.parking_lot_amount },
                          {
                            alwaysShow: false,
                            header: 'Tipo de cochera',
                            value: !!appraisal.content.parking_lot_type && !!appraisal.content.parking_lot_amount,
                            valueToShow: appraisal.content.parking_lot_type,
                          },
                        ].map((item, index) => (
                          <InformationItem
                            key={index}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
                {
                  title: 'Superficie de la propiedad',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Superficie de la propiedad'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {[
                          {
                            alwaysShow: false,
                            header: 'Sup. total',
                            value: appraisal.content.total_surface,
                            valueToShow:
                              `${appraisal.content.total_surface} ${appraisal.content.total_surface_measurement}`.trim(),
                          },
                          {
                            alwaysShow: false,
                            header: 'Sup. cubierta',
                            value: appraisal.content.roofed_surface,
                            valueToShow:
                              `${appraisal.content.roofed_surface} ${appraisal.content.roofed_surface_measurement}`.trim(),
                          },
                          {
                            alwaysShow: false,
                            header: 'Sup. semidescubierta',
                            value: appraisal.content.semiroofed_surface,
                            valueToShow:
                              `${appraisal.content.semiroofed_surface} ${appraisal.content.semiroofed_surface_measurement}`.trim(),
                          },
                          {
                            alwaysShow: false,
                            header: 'Sup. descubierta',
                            value: appraisal.content.unroofed_surface,
                            valueToShow:
                              `${appraisal.content.unroofed_surface} ${appraisal.content.unroofed_surface_measurement}`.trim(),
                          },
                        ].map((item, index) => (
                          <InformationItem
                            key={index}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
                {
                  title: 'Dimensiones de terreno',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Dimensiones de terreno'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {[
                          {
                            alwaysShow: true,
                            header: 'Sup. terreno',
                            value: appraisal.content.surface,
                            valueToShow: `${appraisal.content.surface} ${appraisal.content.surface_measurement}`.trim(),
                          },
                          {
                            alwaysShow: true,
                            header: 'Frente',
                            value: appraisal.content.front_measure,
                            valueToShow:
                              `${appraisal.content.front_measure} ${appraisal.content.front_measurement}`.trim(),
                          },
                          {
                            alwaysShow: true,
                            header: 'Fondo',
                            value: appraisal.content.depth_measure,
                            valueToShow:
                              `${appraisal.content.depth_measure} ${appraisal.content.depth_measurement}`.trim(),
                          },
                        ].map((item, index) => (
                          <InformationItem
                            key={index}
                            {...item}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
              ]}
            />
          </PreviewSection>
          <PreviewSection title="Ubicación">
            <Stack gap={3}>
              <ContentCarousel
                items={[
                  {
                    title: 'Información',
                    content: (
                      <Stack gap={2}>
                        <Typography level="h2">{'Información'}</Typography>
                        <Grid
                          container
                          spacing={2}
                        >
                          {[
                            { alwaysShow: false, header: 'Dirección', value: appraisal.content.real_address },
                            { alwaysShow: false, header: 'Piso', value: appraisal.content.floor },
                            { alwaysShow: false, header: 'Depto', value: appraisal.content.apartment },
                            { alwaysShow: false, header: 'País', value: appraisal.content.country },
                            { alwaysShow: false, header: 'Provincia', value: appraisal.content.state },
                            { alwaysShow: false, header: 'Zona', value: appraisal.content.region },
                            { alwaysShow: false, header: 'Partido', value: appraisal.content.city },
                            { alwaysShow: false, header: 'Barrio', value: appraisal.content.neighborhood },
                          ].map((item, index) => (
                            <InformationItem
                              key={index}
                              {...item}
                            />
                          ))}
                        </Grid>
                      </Stack>
                    ),
                  },
                  {
                    title: 'Análisis de la zona',
                    content: (
                      <Stack gap={2}>
                        <Typography level="h2">{'Análisis de la zona'}</Typography>
                        <Grid
                          container
                          spacing={2}
                        >
                          {[
                            { alwaysShow: false, header: 'Zona', value: appraisal.content.construction_types },
                            { alwaysShow: false, header: 'Zonificación', value: appraisal.content.zonification },
                            {
                              alwaysShow: false,
                              header: 'Índice de crecimiento',
                              value: noUnspecified(appraisal.content.growth_rate),
                              valueToShow: appraisal.content.growth_rate,
                            },
                            {
                              alwaysShow: false,
                              header: 'Calidad de ubicación',
                              value: noUnspecified(appraisal.content.location_quality),
                              valueToShow: appraisal.content.location_quality,
                            },
                            {
                              alwaysShow: false,
                              header: 'Seguridad',
                              value: noUnspecified(appraisal.content.security),
                              valueToShow: appraisal.content.security,
                            },
                            {
                              alwaysShow: false,
                              header: 'Demanda',
                              value: noUnspecified(appraisal.content.demand),
                              valueToShow: appraisal.content.demand,
                            },
                            {
                              alwaysShow: false,
                              header: 'Oferta',
                              value: noUnspecified(appraisal.content.supply),
                              valueToShow: appraisal.content.supply,
                            },
                            {
                              alwaysShow: false,
                              header: 'Valores',
                              value: noUnspecified(appraisal.content.values),
                              valueToShow: appraisal.content.values,
                            },
                          ].map((item, index) => (
                            <InformationItem
                              key={index}
                              {...item}
                            />
                          ))}
                        </Grid>
                      </Stack>
                    ),
                  },
                ]}
              />
              <Stack>
                <FormLabel>Geolocalización y puntos de referencia</FormLabel>
                <div
                  className="mb-3"
                  style={{ height: '250px' }}
                >
                  <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${mapCenter.lat},${
                      mapCenter.lng
                    }&zoom=13&size=640x400&scale=2&key=${
                      process.env.GATSBY_GOOGLE_MAPS_API_KEY
                    }&style=saturation:-100&markers=%7C${mapCenter.lat},${mapCenter.lng}${
                      appraisal?.content?.reference_points
                        ?.map(
                          (rp: { lat: number; lng: number }, index: number) =>
                            `&markers=color:0x${chartsColors[index].slice(1)}%7Clabel:${index + 1}%7C${rp.lat},${
                              rp.lng
                            }`,
                        )
                        ?.join('') ?? ''
                    }`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  ></img>
                </div>
                <Grid
                  container
                  spacing={2}
                >
                  {appraisal?.content?.reference_points?.map((rp: { name: string }, index: number) => (
                    <Grid
                      key={index}
                      xs={6}
                      sm={3}
                    >
                      <Typography
                        sx={{
                          padding: 1,
                          borderRadius: globalStyles.borderRadius,
                        }}
                        variant="outlined"
                        level="body-md-light"
                        startDecorator={<Place sx={{ color: chartsColors[index] }} />}
                      >
                        {rp.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>
          </PreviewSection>
          <PreviewSection title="Adicionales y amenities">
            <ContentCarousel
              items={[
                {
                  title: 'Servicios',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Servicios'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {appraisal.content.extra_services?.map((value: string, index: number) => (
                          <AmenitiesItem
                            key={index}
                            value={value}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
                {
                  title: 'Generales',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Generales'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {appraisal.content.extra_general?.map((value: string, index: number) => (
                          <AmenitiesItem
                            key={index}
                            value={value}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
                {
                  title: 'Amenities',
                  content: (
                    <Stack gap={2}>
                      <Typography level="h2">{'Amenities'}</Typography>
                      <Grid
                        container
                        spacing={2}
                      >
                        {appraisal.content.extra_amenities?.map((value: string, index: number) => (
                          <AmenitiesItem
                            key={index}
                            value={value}
                          />
                        ))}
                      </Grid>
                    </Stack>
                  ),
                },
              ]}
            />
          </PreviewSection>
          <PreviewSection title="Imágenes de la propiedad">
            <Grid
              container
              spacing={4}
            >
              {appraisal.images_data?.map((image, index) => (
                <Grid
                  key={index}
                  xs={12}
                  sm={4}
                >
                  <AspectRatio sx={{ position: 'relative' }}>
                    <img
                      src={image.file as string}
                      alt=""
                    />
                    {Boolean(image.text_alt) && <Typography sx={styles.imageLegend}>{image.text_alt}</Typography>}
                  </AspectRatio>
                </Grid>
              ))}
            </Grid>
          </PreviewSection>
          {Boolean(appraisal.content.rentComparisonProperties) && (
            <PreviewSection title="Comparable de propiedades - Alquiler">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  sm={3}
                >
                  {currentPropertyStripe}
                </Grid>
                <Grid
                  xs={12}
                  sm={9}
                >
                  <Stack
                    direction="row"
                    gap={2}
                    sx={styles.comparisonContent}
                  >
                    {appraisal.content.rentComparisonProperties.map((property: any, index: number) => (
                      <PropertyStripe
                        key={index}
                        image={property.frontCover ?? ''}
                        title={property.full_address ?? ''}
                        subtitle={'' ?? ''}
                        city={property.city ?? ''}
                        minWidth={'33%' ?? ''}
                        state={property.state ?? ''}
                        country={property.country ?? ''}
                        age={property.age ?? ''}
                        computable_total_surface={property.computable_total_surface ?? ''}
                        roofed_surface={property.roofed_surface ?? ''}
                        semiroofed_surface={property.semiroofed_surface ?? ''}
                        unroofed_surface={property.unroofed_surface ?? ''}
                        room_amount={property.room_amount ?? ''}
                        suite_amount={property.suite_amount ?? ''}
                        bathroom_amount={property.bathroom_amount ?? ''}
                        parking_lot_amount={property.parking_lot_amount ?? ''}
                        extra_amenities={property.extra_amenities ?? ''}
                        square_meter_value={property.square_meter_value ?? ''}
                        tokko_valuation_price={property.tokko_valuation_price ?? ''}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </PreviewSection>
          )}
          {Boolean(appraisal.content.temporaryRentComparisonProperties) && (
            <PreviewSection title="Comparable de propiedades - Alquiler temporario">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  sm={3}
                >
                  {currentPropertyStripe}
                </Grid>
                <Grid
                  xs={12}
                  sm={9}
                >
                  <Stack
                    direction="row"
                    gap={2}
                    sx={styles.comparisonContent}
                  >
                    {appraisal.content.temporaryRentComparisonProperties.map((property: any, index: number) => (
                      <PropertyStripe
                        key={index}
                        image={property.frontCover ?? ''}
                        title={property.full_address ?? ''}
                        subtitle={'' ?? ''}
                        city={property.city ?? ''}
                        minWidth={'33%' ?? ''}
                        state={property.state ?? ''}
                        country={property.country ?? ''}
                        age={property.age ?? ''}
                        computable_total_surface={property.computable_total_surface ?? ''}
                        roofed_surface={property.roofed_surface ?? ''}
                        semiroofed_surface={property.semiroofed_surface ?? ''}
                        unroofed_surface={property.unroofed_surface ?? ''}
                        room_amount={property.room_amount ?? ''}
                        suite_amount={property.suite_amount ?? ''}
                        bathroom_amount={property.bathroom_amount ?? ''}
                        parking_lot_amount={property.parking_lot_amount ?? ''}
                        extra_amenities={property.extra_amenities ?? ''}
                        square_meter_value={property.square_meter_value ?? ''}
                        tokko_valuation_price={property.tokko_valuation_price ?? ''}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </PreviewSection>
          )}
          {Boolean(appraisal.content.saleComparisonProperties) && (
            <PreviewSection title="Comparable de propiedades - Venta">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  sm={3}
                >
                  {currentPropertyStripe}
                </Grid>
                <Grid
                  xs={12}
                  sm={9}
                >
                  <Stack
                    direction="row"
                    gap={2}
                    sx={styles.comparisonContent}
                  >
                    {appraisal.content.saleComparisonProperties.map((property: any, index: number) => (
                      <PropertyStripe
                        key={index}
                        image={property.frontCover ?? ''}
                        title={property.full_address ?? ''}
                        subtitle={'' ?? ''}
                        city={property.city ?? ''}
                        minWidth={'33%' ?? ''}
                        state={property.state ?? ''}
                        country={property.country ?? ''}
                        age={property.age ?? ''}
                        computable_total_surface={property.computable_total_surface ?? ''}
                        roofed_surface={property.roofed_surface ?? ''}
                        semiroofed_surface={property.semiroofed_surface ?? ''}
                        unroofed_surface={property.unroofed_surface ?? ''}
                        room_amount={property.room_amount ?? ''}
                        suite_amount={property.suite_amount ?? ''}
                        bathroom_amount={property.bathroom_amount ?? ''}
                        parking_lot_amount={property.parking_lot_amount ?? ''}
                        extra_amenities={property.extra_amenities ?? ''}
                        square_meter_value={property.square_meter_value ?? ''}
                        tokko_valuation_price={property.tokko_valuation_price ?? ''}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </PreviewSection>
          )}
          <PreviewSection title="Valuación final">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={6}
              >
                <Stack>
                  <FormLabel>Opinión de valor</FormLabel>
                  <RichTextEditor
                    noToolbar
                    readOnly
                    content={appraisal.content.valuation_review}
                    placeholder={''}
                  />
                </Stack>
              </Grid>
              <Grid
                xs={12}
                sm={6}
              >
                <Stack gap={2}>
                  {Boolean(appraisal.content.valuation_price) && (
                    <ValuationCard
                      title="Valor de venta de la propiedad"
                      valuation_currency={appraisal.content.valuation_currency}
                      valuation_price={appraisal.content.valuation_price}
                      valuation_price_has_percentage={!!appraisal.content.valuation_price_percentage}
                      valuation_price_percentage={appraisal.content.valuation_price_percentage}
                    />
                  )}
                  {Boolean(appraisal.content.valuation_price_rent) && (
                    <ValuationCard
                      title="Valor de alquiler de la propiedad"
                      valuation_currency={appraisal.content.valuation_currency_rent}
                      valuation_price={appraisal.content.valuation_price_rent}
                      valuation_price_has_percentage={!!appraisal.content.valuation_price_rent_has_percentage}
                      valuation_price_percentage={appraisal.content.valuation_price_rent_percentage}
                      valuation_period={appraisal.content.valuation_period_rent}
                    />
                  )}
                  {Boolean(appraisal.content.valuation_price_temporary_rent) && (
                    <ValuationCard
                      title="Valor de alquiler de la propiedad"
                      valuation_currency={appraisal.content.valuation_currency_temporary_rent}
                      valuation_price={appraisal.content.valuation_price_temporary_rent}
                      valuation_price_has_percentage={!!appraisal.content.valuation_price_temporary_rent_has_percentage}
                      valuation_price_percentage={appraisal.content.valuation_price_temporary_rent_percentage}
                      valuation_period={appraisal.content.valuation_period_temporary_rent}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </PreviewSection>
          {Boolean(appraisal.content.footnotes) && (
            <PreviewSection title="Notas al pie">
              <RichTextEditor
                noToolbar
                readOnly
                content={appraisal.content.footnotes}
                placeholder={''}
              />
            </PreviewSection>
          )}
        </Stack>
      </>
    </SpinnerWrapper>
  )
}

export const ReportPreview = ({ id }: PreviewProps) => {
  const { client } = useSession()
  const { report, isLoadingReport } = useReport({
    id,
    clientId: client?.id!,
    handleShowForm: () => {},
  })

  const webVisits = report.content.external_websites_inquiries
    .map(({ source, visits_count }: any) => [source, visits_count ?? 0])
    .concat(['Sitio web inmobiliaria', report.content.real_estate_visits_count ?? 0])
  console.log(webVisits)
  const totalWebVisits = webVisits?.reduce((acc: number, value: any) => acc + value[1], 0) ?? 0

  const inquiries = report.content.inquiries.map(({ source, count, other_source }: any) => [
    other_source ?? source,
    count ?? 0,
  ])

  const totalInquiries = inquiries?.reduce((acc: number, value: any) => acc + value[1], 0) ?? 0

  const styles: JoyStyles = {
    content: {
      paddingX: 3,
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
    },
    physicalMetrics: {
      border: globalStyles.border,
      borderRadius: globalStyles.card.borderRadius,
      padding: 2,
    },
    ratingCards: {
      maxHeight: '50vh',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }

  return (
    <SpinnerWrapper loading={isLoadingReport}>
      <>
        <PreviewHeader
          title={report.content.fake_address}
          headline={report.title}
          date={report.date_from}
        />
        <Stack sx={styles.content}>
          <PreviewSection title="Métricas sitios online">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={6}
              >
                <PieCard
                  data={webVisits}
                  total={totalWebVisits}
                  headers={['Sitio', 'Visitas', 'Promedio']}
                  totalLabel="Total visitas"
                />
              </Grid>
            </Grid>
          </PreviewSection>
          <PreviewSection title="Otras métricas">
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                sm={6}
              >
                <PieCard
                  data={inquiries}
                  total={totalInquiries}
                  headers={['Origen', 'Consultas']}
                  totalLabel="Total consultas"
                />
              </Grid>
            </Grid>
          </PreviewSection>
          <PreviewSection title="Mètricas físicas de la propiedad">
            <Grid
              container
              spacing={3}
              sx={styles.physicalMetrics}
            >
              <Grid
                xs={12}
                sm={8}
              >
                <Stack gap={2}>
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems={'center'}
                  >
                    <Typography level="h1">{'5.0'}</Typography>
                    <Stack>
                      <Rating
                        defaultValue={2.5}
                        precision={0.5}
                        readOnly
                      />
                      <Typography level="body-sm-light">{'5 valoraciones'}</Typography>
                    </Stack>
                  </Stack>
                  <Grid
                    container
                    spacing={0}
                    columns={24}
                  >
                    {[5, 4, 3, 2, 1].map((value, index) => (
                      <React.Fragment key={index}>
                        <Grid xs={22}>
                          <LinearProgress
                            size="sm"
                            determinate
                            sx={{ transform: 'translate(0, 5px)' }}
                            value={15 * value}
                          />
                        </Grid>
                        <Grid xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            gap={1}
                          >
                            <Typography level="body-sm-light">{value}</Typography>
                            <Star fontSize="small" />
                          </Stack>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
              <Grid
                xs={12}
                sm={4}
                sx={styles.ratingCards}
              >
                <Stack gap={2}>
                  <RatingCard />
                  <RatingCard />
                  <RatingCard />
                </Stack>
              </Grid>
            </Grid>
          </PreviewSection>
        </Stack>
      </>
    </SpinnerWrapper>
  )
}

export const InvestorNewsPreview = ({ id }: PreviewProps) => {
  const { client } = useSession()
  const { investorNews, isFetchingNews } = useInvestorNews({
    id,
    clientId: client?.id,
  })
  return (
    <SpinnerWrapper loading={isFetchingNews}>
      <>
        <PreviewHeader
          title={investorNews?.title ?? ''}
          headline={investorNews?.headline ?? ''}
          date={new Date(investorNews?.date_published ?? '').toLocaleDateString()}
        />
        <Stack
          sx={{
            paddingX: 3,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            height: `calc(100vh - ${3.5 * globalStyles.navBarHeight}px)`,
          }}
        >
          {Boolean(investorNews?.image) && (
            <AspectRatio>
              <img
                src={investorNews?.image as string}
                alt=""
              />
            </AspectRatio>
          )}
          <PreviewSection title="Información general">
            <RichTextEditor
              noToolbar
              readOnly
              content={investorNews?.content ?? ''}
              placeholder={''}
            />
          </PreviewSection>
          {Boolean(investorNews?.images_data?.length) && (
            <PreviewSection title="Galería de imágenes">
              <Grid
                container
                spacing={4}
              >
                {investorNews?.images_data?.map((image, index) => (
                  <Grid
                    key={index}
                    xs={12}
                    sm={4}
                  >
                    <AspectRatio sx={{ position: 'relative' }}>
                      <img
                        src={image.file as string}
                        alt=""
                      />
                      {Boolean(image.text_alt) && (
                        <Typography
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            paddingY: 2,
                            textAlign: 'center',
                            background: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                          }}
                        >
                          {image.text_alt}
                        </Typography>
                      )}
                    </AspectRatio>
                  </Grid>
                ))}
              </Grid>
            </PreviewSection>
          )}
          {Boolean(investorNews?.files_data?.length) && (
            <PreviewSection title="Archivos para descargar">
              <Stack
                direction="row"
                gap={2}
                flexWrap="wrap"
              >
                {investorNews?.files_data?.map(({ file }, index) => (
                  <Button
                    component="a"
                    key={index}
                    variant="plain"
                    color="primary"
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      startDecorator={<Download fontSize="small" />}
                      level="body-md-light"
                    >
                      {(file as string).substring((file as string).lastIndexOf('/') + 1)}
                    </Typography>
                  </Button>
                ))}
              </Stack>
            </PreviewSection>
          )}
        </Stack>
      </>
    </SpinnerWrapper>
  )
}
