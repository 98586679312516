export const authRoles = {
  SA: ['SA'], // Only Super Admin has access
  ADMIN: ['SA', 'ADMIN'], // Only SA & Admin has access
  CLIENT_ADMIN: ['SA', 'ADMIN', 'CLIENT_ADMIN'], // ...
  CLIENT: ['SA', 'ADMIN', 'CLIENT_ADMIN', 'CLIENT'],
}

export const chartsColors = [
  '#2e3f50', //
  '#bc5c5c',
  '#bc9f5c',
  '#8fbc5c',
  '#5cb6bc',
  '#5c8abc',
  '#5c63bc',
  '#9e5cbc',
  '#bc5c8c',
  '#bc5c5c',
]

export const modules = {
  SITE: 'MediaSite',
  TRACKER: 'MediaTracker',
  BLOG: 'MediaBlog',
  PUSH: 'MediaPush',
  INVESTOR: 'MediaInvestor',
  OWNER: 'MediaOwner',
}

export const google_icons = [
  'search',
  'home',
  'account_circle',
  'settings',
  'done',
  'info',
  'check_circle',
  'delete',
  'visibility',
  'shopping_cart',
  'favorite',
  'description',
  'logout',
  'favorite_border',
  'lock',
  'schedule',
  'language',
  'help_outline',
  'face',
  'manage_accounts',
  'verified',
  'filter_alt',
  'event',
  'thumb_up',
  'fingerprint',
  'dashboard',
  'list',
  'login',
  'visibility_off',
  'calendar_today',
  'check_circle_outline',
  'highlight_off',
  'help',
  'paid',
  'question_answer',
  'task_alt',
  'date_range',
  'article',
  'shopping_bag',
  'open_in_new',
  'lightbulb',
  'perm_identity',
  'credit_card',
  'history',
  'trending_up',
  'account_balance',
  'delete_outline',
  'report_problem',
  'fact_check',
  'assignment',
  'verified_user',
  'star_rate',
  'arrow_right_alt',
  'account_balance_wallet',
  'autorenew',
  'view_list',
  'build',
  'work',
  'print',
  'store',
  'analytics',
  'today',
  'delete_forever',
  'admin_panel_settings',
  'lock_open',
  'watch_later',
  'grade',
  'room',
  'calendar_month',
  'savings',
  'code',
  'update',
  'receipt',
  'add_shopping_cart',
  'contact_support',
  'power_settings_new',
  'pets',
  'done_all',
  'explore',
  'bookmark',
  'note_add',
  'account_box',
  'bookmark_border',
  'reorder',
  'pending_actions',
  'shopping_basket',
  'payment',
  'launch',
  'drag_indicator',
  'supervisor_account',
  'touch_app',
  'pending',
  'zoom_in',
  'assessment',
  'leaderboard',
  'thumb_up_off_alt',
  'open_in_full',
  'exit_to_app',
  'preview',
  'done_outline',
  'assignment_ind',
  'published_with_changes',
  'card_giftcard',
  'view_in_ar',
  'work_outline',
  'feedback',
  'timeline',
  'swap_horiz',
  'dns',
  'assignment_turned_in',
  'sync_alt',
  'stars',
  'label',
  'flight_takeoff',
  'tips_and_updates',
  'book',
  'contact_page',
  'bug_report',
  'space_dashboard',
  'alarm',
  'cached',
  'pan_tool',
  'gavel',
  'translate',
  'edit_calendar',
  'supervised_user_circle',
  'minimize',
  'extension',
  'android',
  'accessibility',
  'get_app',
  'add_task',
  'record_voice_over',
  'trending_flat',
  'hourglass_empty',
  'help_center',
  'rocket_launch',
  'accessibility_new',
  'question_mark',
  'thumb_down',
  'rule',
  'sticky_note_2',
  'source',
  'dashboard_customize',
  'settings_applications',
  'flutter_dash',
  'support',
  'find_in_page',
  'close_fullscreen',
  'ads_click',
  'redeem',
  'announcement',
  'loyalty',
  'swap_vert',
  'view_headline',
  'arrow_circle_right',
  'restore',
  'dangerous',
  'group_work',
  'euro_symbol',
  'sensors',
  'compare_arrows',
  'subject',
  'disabled_by_default',
  'table_view',
  'nightlight_round',
  'privacy_tip',
  'https',
  'toc',
  'track_changes',
  'arrow_circle_up',
  'copyright',
  'bookmarks',
  'grading',
  'api',
  'query_builder',
  'input',
  'perm_media',
  'view_module',
  'build_circle',
  'zoom_out',
  'perm_contact_calendar',
  'backup',
  'open_with',
  'settings_phone',
  'book_online',
  'circle_notifications',
  'currency_exchange',
  'speaker_notes',
  'perm_phone_msg',
  'card_membership',
  'label_important',
  'wysiwyg',
  'arrow_circle_down',
  'file_present',
  '3d_rotation',
  'pageview',
  'upgrade',
  'integration_instructions',
  'change_history',
  'trending_down',
  'swipe',
  'class',
  'accessible',
  'g_translate',
  'settings_accessibility',
  'expand',
  'offline_bolt',
  'percent',
  'production_quantity_limits',
  'settings_backup_restore',
  'aspect_ratio',
  'model_training',
  'donut_large',
  'arrow_circle_left',
  'segment',
  'view_column',
  'calendar_view_month',
  'schedule_send',
  'alarm_on',
  'maximize',
  'bookmark_add',
  'thumbs_up_down',
  'settings_ethernet',
  'theaters',
  'view_agenda',
  'thumb_down_off_alt',
  'important_devices',
  'unpublished',
  'invert_colors',
  'open_in_browser',
  'no_accounts',
  'addchart',
  'commute',
  'opacity',
  'history_toggle_off',
  'system_update_alt',
  'view_week',
  'youtube_searched_for',
  'tour',
  'hide_source',
  'shop',
  'mark_as_unread',
  'turned_in',
  'bookmark_added',
  'settings_input_antenna',
  'plagiarism',
  'search_off',
  'not_started',
  'flight_land',
  'assignment_late',
  'shopping_cart_checkout',
  'donut_small',
  'contactless',
  'saved_search',
  'highlight_alt',
  'settings_input_component',
  'anchor',
  'view_carousel',
  'assignment_return',
  'view_quilt',
  'turned_in_not',
  'hotel_class',
  'all_inbox',
  'mediation',
  'edit_off',
  'settings_remote',
  'balance',
  'fit_screen',
  'filter_alt_off',
  'hourglass_full',
  'flaky',
  'toll',
  'remove_shopping_cart',
  'lock_clock',
  'settings_voice',
  'swap_horizontal_circle',
  'rocket',
  'online_prediction',
  'view_sidebar',
  'event_seat',
  'vertical_split',
  'pregnant_woman',
  'next_plan',
  'data_exploration',
  'restore_from_trash',
  'camera_enhance',
  'markunread_mailbox',
  'dynamic_form',
  'calendar_view_week',
  'tab',
  'view_stream',
  'accessible_forward',
  'terminal',
  'lock_reset',
  'remove_done',
  'request_page',
  'add_to_drive',
  'token',
  'settings_power',
  'smart_button',
  'card_travel',
  'outbox',
  'try',
  'offline_pin',
  'calendar_view_day',
  'find_replace',
  'rowing',
  'compress',
  'outlet',
  'chrome_reader_mode',
  'assignment_returned',
  'settings_brightness',
  'http',
  'alarm_add',
  'fax',
  'backup_table',
  'spellcheck',
  'new_label',
  'density_medium',
  'generating_tokens',
  'restore_page',
  'credit_card_off',
  'play_for_work',
  'wifi_protected_setup',
  'settings_overscan',
  'gif',
  'free_cancellation',
  'add_card',
  'outbound',
  'settings_input_composite',
  'quickreply',
  'swap_vertical_circle',
  'view_day',
  'comment_bank',
  'cancel_schedule_send',
  'disabled_visible',
  'line_weight',
  'switch_access_shortcut',
  '123',
  'polymer',
  'horizontal_split',
  'work_history',
  'batch_prediction',
  'view_timeline',
  'send_and_archive',
  'picture_in_picture',
  'assured_workload',
  'event_repeat',
  'display_settings',
  'satellite_alt',
  'code_off',
  'bookmark_remove',
  'settings_bluetooth',
  'webhook',
  'pan_tool_alt',
  'noise_control_off',
  'shop_two',
  'flip_to_front',
  'gif_box',
  'manage_history',
  'html',
  'speaker_notes_off',
  'eject',
  'output',
  'alarm_off',
  'perm_data_setting',
  'settings_input_hdmi',
  'perm_scan_wifi',
  'hourglass_disabled',
  'settings_cell',
  'app_blocking',
  'work_off',
  'sensors_off',
  'switch_access_shortcut_add',
  'picture_in_picture_alt',
  'line_style',
  'perm_device_information',
  'subtitles_off',
  'update_disabled',
  'join_full',
  'javascript',
  'app_shortcut',
  'voice_over_off',
  'swipe_left',
  'troubleshoot',
  'settings_input_svideo',
  'abc',
  'private_connectivity',
  'flip_to_back',
  'view_array',
  'open_in_new_off',
  'lightbulb_circle',
  'all_out',
  'install_desktop',
  'swipe_right',
  'join_inner',
  'swipe_up',
  'shop_2',
  'label_off',
  'rounded_corner',
  'tab_unselected',
  'text_rotate_vertical',
  'density_small',
  'data_thresholding',
  'explore_off',
  'text_rotation_none',
  'commit',
  'not_accessible',
  'install_mobile',
  'pin_invoke',
  'view_kanban',
  'swipe_down',
  'css',
  'perm_camera_mic',
  'extension_off',
  'join_left',
  'pinch',
  'arrow_outward',
  'pin_end',
  'swipe_vertical',
  'lock_person',
  'spatial_audio_off',
  'view_cozy',
  'join_right',
  'noise_aware',
  'text_rotate_up',
  'text_rotation_down',
  'text_rotation_angledown',
  'swipe_right_alt',
  'text_rotation_angleup',
  'browse_gallery',
  'php',
  'view_comfy_alt',
  'view_compact_alt',
  'swipe_down_alt',
  'network_ping',
  'density_large',
  'on_device_training',
  'swipe_left_alt',
  'swipe_up_alt',
  'add_home',
  'spatial_tracking',
  'spatial_audio',
  'unfold_more_double',
  'width_full',
  'unfold_less_double',
  'width_normal',
  'hls',
  'repartition',
  'hls_off',
  'transcribe',
  'width_wide',
  'face_unlock',
] as const

export const routes = {
  core: {
    404: `/404/`,
    dev404: `/dev-404-page/`,
    html404: `/404.html`,
    root: `/`,
    change_password: `/cambiar_contrasena/`,
    my_profile: `/mi_perfil/`,
    login: `/sesion/inicio/`,
    custom_login: (loginPath: string) => `/sesion/inicio/${loginPath}/`,
    users: `/usuarios/`,
    add_user: `/usuarios/nuevo/`,
    edit_user: (id: number) => `/usuarios/editar/${id}/`,
    assign_user_permissions: (id: number) => `/usuarios/asignar_permisos/${id}/`,
    clients: `/clientes/`,
    add_client: `/clientes/nuevo/`,
    edit_client: (id: number) => `/clientes/editar/${id}/`,
    login_templates: `/configuracion/pantallas_inicio/`,
    add_login_template: `/configuracion/pantallas_inicio/nuevo/`,
    edit_login_template: (id: number) => `/configuracion/pantallas_inicio/editar/${id}/`,
    groups_and_permissions: `/configuracion/grupos_y_permisos/`,
    support_tickets: `/tickets_soporte/`,
    add_support_ticket: `/tickets_soporte/nuevo`,
    edit_support_ticket: (id: number) => `/tickets_soporte/${id}`,
  },
  site: {
    my_website: `/mediasite/misitioweb/`,
    my_website_v2: `/mediasite/mi_sitio_web_v2/`,
    site_compiler: `/mediasite/compilador_de_sitios_web/`,
    dns: `/mediasite/dns/`,
    published_sites: `/mediasite/sitiospublicados/`,
    fonts: `/mediasite/fuentes/`,
    add_font: `/mediasite/fuentes/nuevo/`,
    edit_font: (id: number) => `/mediasite/fuentes/editar/${id}/`,
    templates: `/mediasite/templates/`,
    add_template: `/mediasite/templates/nuevo/`,
    edit_template: (id: number) => `/mediasite/templates/editar/${id}/`,
    template_categories: `/mediasite/templates/categorias/`,
    add_template_category: `/mediasite/templates/categorias/nuevo/`,
    edit_template_category: (id: number) => `/mediasite/templates/categorias/editar/${id}/`,
    properties: `/mediasite/propiedades/`,
    add_property: `/mediasite/propiedades/nuevo`,
    edit_property: (id: number) => `/mediasite/propiedades/editar/${id}/`,
    developments: `/mediasite/emprendimientos/`,
    add_development: `/mediasite/emprendimientos/nuevo`,
    edit_development: (id: number) => `/mediasite/emprendimientos/editar/${id}/`,
    groups: `/mediasite/grupos/`,
    add_group: `/mediasite/grupos/nuevo`,
    edit_group: (id: number) => `/mediasite/grupos/editar/${id}/`,
    groupings: `/mediasite/agrupacion/`,
    add_grouping: `/mediasite/agrupacion/nuevo`,
    edit_grouping: (id: number) => `/mediasite/agrupacion/editar/${id}/`,
    contacts: `/mediasite/contactos/`,
    preview_contact: (id: number) => `/mediasite/contactos/contacto/${id}/`,
  },
  blog: {
    news: `/mediablog/novedades/`,
    add_news: `/mediablog/novedades/nuevo/`,
    edit_news: (id: number) => `/mediablog/novedades/editar/${id}/`,
    tags: `/mediablog/tags/`,
    add_tags: `/mediablog/tags/nuevo/`,
    edit_tag: (id: number) => `/mediablog/tags/editar/${id}/`,
    preview_news: (id: number) => `/mediablog/novedades/previsualizar/${id}/`,
  },
  push: {
    actions: `/mediapush/destaques/`, //
    add_action: `/mediapush/destaques/nuevo/`,
    edit_action: (id: number) => `/mediapush/destaques/editar/${id}/`,
  },
  tracker: {
    analytics: `/mediatracker/analiticas/`, //
    realtime_analytics: `/mediatracker/analiticastiemporeal/`,
  },
  investor: {
    config: `/mediainvestor/configuracion/`,
    investors: `/mediainvestor/inversores/`,
    edit_investor: (id: number) => `/mediainvestor/inversores/editar/${id}/`,
    developments: `/mediainvestor/emprendimientos/`,
    add_development: `/mediainvestor/emprendimientos/nuevo/`,
    edit_development: (id: number) => `/mediainvestor/emprendimientos/editar/${id}/`,
    assign_development: (id: number) => `/mediainvestor/inversores/asignar/${id}/`,
    news: `/mediainvestor/novedades/`,
    add_news: `/mediainvestor/novedades/nuevo/`,
    edit_news: (id: number) => `/mediainvestor/novedades/editar/${id}/`,
    preview_news: (id: number) => `/mediainvestor/novedades/previsualizar/${id}/`,
  },
  owner: {
    communications: `/mediaowner/comunicaciones/`,
    add_communication: `/mediaowner/comunicaciones/nuevo/`,
    config: `/mediaowner/configuracion/`,
    owners: `/mediaowner/propietarios/`,
    edit_owner: (id: number) => `/mediaowner/propietarios/editar/${id}/`,
    visits: `/mediaowner/visitas/`,
    add_visit: `/mediaowner/visitas/nuevo/`,
    visit_record: (id: number) => `/mediaowner/visitas/registro_visita/${id}/`,
    edit_visit: (id: number) => `/mediaowner/visitas/editar/${id}/`,
    add_news: `/mediaowner/comunicaciones/novedades/nuevo/`,
    edit_news: (id: number) => `/mediaowner/comunicaciones/novedades/editar/${id}/`,
    preview_news: (id: number) => `/mediaowner/comunicaciones/novedades/previsualizar/${id}/`,
    add_report: `/mediaowner/comunicaciones/reportes/nuevo/`,
    edit_report: (id: number) => `/mediaowner/comunicaciones/reportes/editar/${id}/`,
    preview_report: (id: number) => `/mediaowner/comunicaciones/reportes/previsualizar/${id}/`,
    add_appraisal: `/mediaowner/comunicaciones/tasaciones/nuevo/`,
    edit_appraisal: (id: number) => `/mediaowner/comunicaciones/tasaciones/editar/${id}/`,
    preview_appraisal: (id: number) => `/mediaowner/comunicaciones/tasaciones/previsualizar/${id}/`,
    assign_property: (id: number) => `/mediaowner/propietarios/asignar/${id}/`,
  },
  investor_view: {
    communications: `/inversor/comunicaciones/`,
    investor_news_detail: (id: number) => `/inversor/novedades_inversor/${id}/`,
    owner_news_detail: (id: number) => `/inversor/novedades_propietario/${id}/`,
    report_detail: (id: number) => `/inversor/reportes/${id}/`,
    appraisal_detail: (id: number) => `/inversor/tasaciones/${id}/`,
  },
  owner_view: {
    investor_news_detail: (id: number) => `/propietario/novedades_inversor/${id}/`,
    owner_news_detail: (id: number) => `/propietario/novedades_propietario/${id}/`,
    report_detail: (id: number) => `/propietario/reportes/${id}/`,
    appraisal_detail: (id: number) => `/propietario/tasaciones/${id}/`,
    visit_detail: (id: number) => `/propietario/reportes/tus_visitas/${id}/`,
    communications: `/propietario/comunicaciones/`,
  },
  jobs: {
    positions: `/mediajobs/posiciones`,
    add_position: `/mediajobs/posiciones/nuevo/`,
    edit_position: (id: number) => `/mediajobs/posiciones/editar/${id}`,
    applications: `/mediajobs/candidatos`,
    edit_application: (id: number) => `/mediajobs/candidatos/editar/${id}`,
    application_detail: (id: number) => `/mediajobs/candidatos/posicion/${id}`,
  },
}

export const currency_options = [
  {
    value: 'USD',
    label: 'USD (Dólares estadounidenses)',
  },
  {
    value: 'ARS', //
    label: 'ARS (pesos Argentinos)',
  },
]

export const operation_types = ['Venta', 'Alquiler', 'Temporario']

export const appraisal_property_types = [
  'Departamento', //
  'Casa',
  'PH',
  'Oficina',
  'Local',
  'Cochera',
  'Terreno',
  'Otro',
]

export const property_types = [
  'Departamento', //
  'Casa',
  'PH',
  'Oficina',
  'Local',
  'Cochera',
  'Terreno',
  'Quinta',
  'Depósito',
  'Campo',
  'Edificio comercial',
  'Otro',
]
export const development_property_types = [
  'Edificio residencial', //
  'Edificio de oficinas',
  'Edificio mixto',
  'Hotel',
  'Club',
  'Centro logístico',
  'Loteo',
  'Barrio privado',
  'Rural',
  'Náutico',
  'Centro comercial',
  'Otro',
]

export const parking_lot_options = [
  'Privada cubierta',
  'Privada descubierta',
  'Privada semicubierta',
  'Sin especificar',
]

export const age_options = [
  'En construcción', //
  'A estrenar',
  '1 a 5 años',
  '6 a 10 años',
  '11 a 20 años',
  '21 a 50 años',
  'Más de 50 años',
  'Sin especificar',
]
export const orientation_options = [
  'Sur', //
  'Norte',
  'Este',
  'Oeste',
  'Noreste',
  'Sudeste',
  'Noroeste',
  'Suroeste',
  'Sin especificar',
]
export const disposition_options = [
  'Frente', //
  'Contrafrente',
  'Lateral',
  'Interno',
  'Sin especificar',
]
export const luminosity_options = ['Poco luminoso', 'Luminoso', 'Muy luminoso', 'Sin especificar']
export const preservation_state_options = [
  'Mal estado',
  'Estado regular',
  'Buen estado',
  'Excelente estado',
  'Sin especificar',
]
export const building_quality_options = [
  'Baja calidad',
  'Calidad promedio',
  'Buena calidad',
  'Excelente calidad',
  'Sin especificar',
]

export const zonification_options = [
  'APH',
  'ARE',
  'C1',
  'C2',
  'C3 I',
  'C3 II',
  'E1',
  'E2',
  'E3',
  'E4',
  'I1',
  'I2',
  'NE',
  'P',
  'R1a',
  'R1bI',
  'R1bII',
  'R2aI',
  'R2aII',
  'R2bI',
  'R2bII',
  'R2bIII',
  'RU',
  'RUA',
  'U',
  'UF',
  'UP',
  'UP/APH 2',
]
export const region_options = ['Norte', 'Sur', 'Este', 'Oeste']

export const construction_types_options = ['Residencial', 'Comercial', 'Industrial', 'Mixta', 'Otro']

export const growth_rate_options = ['Sin especificar', 'Decreciente', 'Estable', 'Creciente', 'Acelerado']

export const location_quality_options = ['Sin especificar', 'Mala', 'Regular', 'Buena', 'Excelente']

export const security_options = ['Sin especificar', 'Baja', 'Regular', 'Alta', 'Máxima']

export const supply_options = ['Sin especificar', 'Escasa', 'Moderada', 'Abundante', 'Sobre oferta']

export const demand_options = ['Sin especificar', 'Inexistente', 'Escasa', 'Moderada', 'Alta']

export const values_options = ['Sin especificar', 'Decreciente', 'Estable', 'Creciente', 'En alza']

export const surface_measurement_options = ['M2', 'HA']

export const measurement_options = ['M', 'KM']

export const extra_services_options = [
  'Agua Corriente',
  'Electricidad',
  'Gas Natural',
  'Cable',
  'Internet',
  'Teléfono',
  'Pavimento',
  'Cloaca',
]
export const extra_general_options = [
  'Balcón',
  'Baulera',
  'Aire acond.',
  'Alarma',
  'Terraza',
  'Sótano',
  'Jardín',
  'Patio',
  'Vestidor',
  'Apto crédito',
  'Apto prof.',
  'Seguridad',
]
export const extra_amenities_options = [
  'Piscina',
  'Piscina climatizada',
  'Calefacción',
  'Gimnasio',
  'Parrilla',
  'Quincho',
  'SUM',
  'Área deportiva',
  'Área de juegos',
  'Sauna',
  'Solarium',
  'Spa',
  'Laundry',
  'Espacios verdes',
  'Área coworking',
  'Hidromasaje',
  'Bicicletero',
  'Rooftop',
]

export const subdivision_options = ['Piso', 'Subsuelo', 'Nivel', 'Lote', 'Fracción', 'Área']

export const valuation_period_rent_options = ['Mensual', 'Semestral', 'Anual']

export const valuation_period_temporary_rent_options = [
  'Diario',
  'Fin de semana',
  'Semanal',
  'Quincenal',
  'Mensual',
  'Anual',
]
export const construction_status_options = ['Pre-venta', 'En pozo', 'En construcción', 'Terminado']

export const payment_method_options = ['Contado', 'Financiado', 'Flexible']

export const elevators_brand_options = ['Schindler', 'Otis', 'Hyundai', 'Thyssenkrupp', 'Otro']

export const climate_control_options = [
  'Aire acondicionado central',
  'Calefacccion central',
  'Mediante radiadores',
  'Mediante equipos Split frío-calor',
  'Suelo radiante',
  'Otro',
  'Sin especificar',
]

export const fire_detection_system_options = [
  'No posee',
  'Detectores de humo',
  'Detectores de calor',
  'Detectores de llama',
  'Sin especificar',
]

export const fire_extinguishing_system_options = ['Rociadores automáticos', 'Extintores y mangeras', 'Sin especificar']

export const development_extra_services_options = [
  'Agua Corriente',
  'Electricidad',
  'Gas Natural',
  'Cable',
  'Internet',
  'Teléfono',
  'Pavimento',
  'Cloaca',
  'Limpieza',
  'Recolección de residuos',
]
export const development_extra_general_options = [
  'Hall de acceso',
  'Bauleras',
  'Cocheras de cortesía',
  'Accesibilidad',
  'CCTV',
  'Garita de seguridad',
  'Cerco perimetral',
  'Grupo electrógeno',
  'Apto blanqueo',
  'Apto crédito',
  'Apto prof.',
  'Seguridad 24hs',
]
export const development_extra_amenities_options = [
  'Piscina',
  'Piscina climatizada',
  'Bar/Restaurante',
  'Gimnasio',
  'Parrilla',
  'Quincho',
  'SUM',
  'Área deportiva',
  'Área de juegos',
  'Sauna',
  'Solarium',
  'Spa',
  'Laundry',
  'Espacios verdes',
  'Área coworking',
  'Hidromasaje',
  'Bicicletero',
  'Rooftop',
]

export const experience_level_options = ['Sin especificar', 'Trainee', 'Junior', 'Semi-senior', 'Senior']

export const mode_options = ['Sin especificar', 'Presencial', 'Remota', 'Híbrida']

export const application_status_options = [
  'En revisión',
  'Descartado',
  'Entrevistas',
  'Evaluación',
  'Pre-seleccionado',
  'Negociación',
  'Contratado',
]

export const languages_options = [
  'Inglés',
  'Español',
  'Chino (Mandarín)',
  'Francés',
  'Alemán',
  'Italiano',
  'Japonés',
  'Portugués',
  'Ruso',
  'Árabe',
]

export const language_level_options = [
  'No conoce el idioma',
  'Básico',
  'Conversación',
  'Profesional',
  'Nativo o lengua materna',
]

export const job_interviews_options = ['Presencial', 'Teléfónica', 'Video Llamada', 'Chat']

export const application_status_colors: { [key: string]: string } = {
  'En revisión': '#f89a0f',
  Descartado: '#d83d1d',
  Entrevistas: '#6a2365',
  Evaluación: '#e14a77',
  'Pre-seleccionado': '#1dafe6',
  Negociación: '#236a61',
  Contratado: '#3ba84e',
}

export const contactQueryType = {
  development_query: 'Emprendimiento',
  subscription: 'Newsletter',
  property_query: 'Propiedad',
  appraisal: 'Tasación',
  contact: 'Contacto',
}

export const contactTypeBreadcrumb = {
  development_query: 'Consulta de un emprendimiento',
  subscription: 'Nuevo suscriptor newsletter',
  property_query: 'Consulta de una propiedad',
  appraisal: 'Solicitud de tasación',
  contact: 'Contacto web',
}
export const support_ticket_priority_colors = {
  Urgente: '#f24726',
  Alta: '#fac710',
  Normal: '#a7d5f9',
  Baja: '#bdbbbb',
}

export const support_ticket_status_colors = {
  'No iniciado': '#ADADAD',
  'En curso': '#da0063',
  'En espera': '#F89A0F',
  Cerrado: '#8fd14f',
}

export const support_ticket_areas = ['Técnico', 'Comercial', 'Facturación']

export const uploadButtonLabel = 'Subir archivo'

export const inquiries_options = ['Referido', 'Teléfono', 'Email', 'Email Marketing', 'Otro']

export const external_websites_inquiries_options = ['ArgenProp', 'ZonaProp', 'MercadoLibre', 'Otro']

export const multimedia_video_options = ['YouTube', 'Vimeo']

export const multimedia_360_tours_options = ['Matterport', 'Hauzd', 'Kuula', 'RoundMe', '360 MagicTour']

export const mapStyles = [
  {
    stylers: [
      {
        saturation: -0,
      },
    ],
  },
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: 0,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'true',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c5d3ca',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -35,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: -25,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c5ced3',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]
