import { AlertContext } from '@components/AlertContextWrapper'
import * as yup from 'yup'
import {
  useCreateInvestorNewsMutation,
  useGetInvestorNewsByIdQuery,
  useUpdateInvestorNewsMutation,
} from '@endpoints/investorEndpoint'
import { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { UserContext } from '@components/Auth'
import { InvestorNews, NewsValidationErrors } from '@types'
import moment from 'moment'
import { navigate } from 'gatsby'
import { onlyUnique, pendingToast, updateErrorToast, updateSuccessToast } from '@utils'

interface UseInvestorNews {
  id?: number
  clientId?: number
  type?: 'General' | 'Grupal' | 'Individual'
}

interface ImageGallery {
  id?: string
  file?: File | string
  text_alt?: string
}

export const useInvestorNews = ({ id, clientId, type = 'General' }: UseInvestorNews) => {
  const { userData } = useContext(UserContext)
  const loggedUserName = `${userData?.data?.first_name} ${userData?.data?.last_name}`
  const [validationErrors, setValidationErrors] = useState<NewsValidationErrors>()
  const { toast } = useContext(AlertContext)

  const {
    data: queriedNews,
    isFetching: isFetchingNews,
    isLoading: isLoadingNews,
    isError: isErrorNews,
  } = useGetInvestorNewsByIdQuery({ id: id!, clientId: clientId! }, { skip: !clientId || !id })
  const [updateInvestorsNews, { isLoading: isUpdating }] = useUpdateInvestorNewsMutation()
  const [createInvestorNews, { isLoading: isCreating }] = useCreateInvestorNewsMutation()

  const [investorNews, setInvestorNews] = useState<Partial<InvestorNews>>()
  const [files, setFiles] = useState<File[] | null>()

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setInvestorNews((prevData) => ({
      ...prevData,
      date_published: moment(e.target.value).format('YYYY-MM-DDThh:mm'),
    }))
  }

  const handleImageInput = (files: File[] | null) => {
    if (files?.length) {
      setInvestorNews((prevData) => ({
        ...prevData,
        image: files[0],
      }))
      // !fieldsChanged.includes('Portada') && setFieldsChanged((prevValues) => [...prevValues, 'Portada'])
    }
  }

  const handleAuthorSelectChange = (_e: SyntheticEvent | null, newValue: string | null) => {
    const value = newValue ?? ''
    setInvestorNews((prevData) => ({
      ...prevData,
      author_choices: ['unspecified', 'other', 'user'].includes(value) ? value : 'user',
      author:
        value === 'unspecified'
          ? '' //
          : value === 'other'
          ? ''
          : value === 'user'
          ? loggedUserName
          : value, // prevUser
    }))
    // !fieldsChanged.includes('Autor') && setFieldsChanged((prevValues) => [...prevValues, 'Autor'])
  }
  const handleFileInputChange = (newFiles: File[] | null) => {
    setFiles((prevFiles) => [...(prevFiles ?? []), ...(newFiles ?? [])])
    setInvestorNews((prevData) => ({
      ...prevData,
      files_data: [...(prevData?.files_data ?? []), ...(newFiles?.map((nf) => ({ file_name: nf.name })) ?? [])],
    }))
  }

  const deleteFile = (index: number) =>
    setInvestorNews((prevData) => {
      let newFiles = [...(prevData?.files_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        files_data: newFiles,
      }
    })

  const deleteGalleryImage = (index: number) =>
    setInvestorNews((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        images_data: newFiles,
      }
    })

  const handleGalleryAltChange = (e: ChangeEvent<HTMLInputElement>, index: number) =>
    setInvestorNews((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: e.target.value }
      return {
        ...prevData,
        images_data: newFiles,
      }
    })

  const addDevelopment = (development: { id: number; name: string }) => {
    if (investorNews?.developments?.some(({ id }) => development.id === id)) return
    setInvestorNews((prevData) => ({
      ...prevData,
      developments: [...(prevData?.developments ?? []), { id: development.id, name: development.name }],
    }))
  }

  const removeDevelopment = (developmentId: number) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      developments: prevData?.developments?.filter(({ id }) => id !== developmentId),
    }))
  }

  const addInvestor = (userId: number) => {
    if (investorNews?.users?.includes(userId)) return
    setInvestorNews((prevData) => ({
      ...prevData,
      users: [...(prevData?.users ?? []), userId],
    }))
  }

  const toggleInvestors = (users: number[]) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      users: users.every((user) => prevData?.users?.includes(user))
        ? prevData?.users?.filter((id) => !users.includes(id))
        : [...(prevData?.users ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeInvestor = (userId: number) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      users: prevData?.users?.filter((id) => id !== userId),
    }))
  }

  const togglePrerelease = () => {
    setInvestorNews((prevData) => ({
      ...prevData,
      prerelease: !investorNews?.prerelease,
    }))
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist?.()
    setInvestorNews((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleChangeInvestors = (
    selectedItems: {
      id: number
      label: string
    }[],
  ) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      users: selectedItems.map((os) => os.id),
    }))
  }

  const handleChangeGalleryFiles = (filteredFiles: File[] | undefined) =>
    setInvestorNews((prevData) => ({
      ...prevData,
      images_data: [
        ...(prevData?.images_data ?? []),
        ...(filteredFiles?.map((newFile, fileIndex) => ({
          file: newFile,
          text_alt: '',
          order: (prevData?.images_data?.length ?? 0) + fileIndex,
        })) ?? []),
      ],
    }))

  const handleReorderGallery = (e: React.DragEvent<HTMLElement>, index: number) =>
    setInvestorNews((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      const reOrder = (array: Array<any>, from: number, to: number) => array.splice(to, 0, array.splice(from, 1)[0])

      reOrder(newFiles, Number(e.dataTransfer.getData('index')), index)
      return {
        ...prevData,
        images_data: newFiles.map((file, fileIndex) => ({ ...file, order: fileIndex })),
      }
    })

  const removeKeyFromArray = (objectArray: any[], key: string) => {
    return objectArray.map((object) => {
      let newObject = { ...object }
      delete newObject[key as keyof typeof newObject]
      return newObject
    })
  }

  const removeKey = (object: any, key: string) => {
    let newObject = { ...object }
    delete newObject[key as keyof typeof newObject]
    return newObject
  }

  const uploadGallery = (result: InvestorNews, gallery: ImageGallery[], index: number, length: number) => {
    toast.promise(
      updateInvestorsNews({
        id: result.id!,
        data: {
          ...removeKey(result, 'image'), //
          files: [gallery[0].file as File],
          files_data: removeKeyFromArray(result.files_data, 'file'),
          images_data: [
            ...removeKeyFromArray(result.images_data, 'file'),
            { file_name: (gallery[0].file as File).name, text_alt: gallery[0].text_alt },
          ],
        },
      })
        .unwrap()
        .then((successResult) => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(successResult, nextGallery, index + 1, length)
        })
        .catch(() => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(result, nextGallery, index + 1, length)
        }),
      {
        pending: `Guardando imagen ${index} de ${length} ...`,
        success: `Imagen ${index} de ${length} guardada con éxito`,
        error: `No se pudo guardar la imagen "${index} de ${length} `,
      },
    )
  }

  const saveNews = (is_active?: boolean, goToPreview?: boolean) => {
    return new Promise((resolve, reject) => {
      const data = {
        ...investorNews,
        client: id ? investorNews?.client : clientId,
        ownerships: investorNews?.prerelease ? [] : investorNews?.ownerships,
        users: investorNews?.prerelease ? [] : investorNews?.users,
        files_data: removeKeyFromArray(investorNews?.files_data ?? [], 'file'),
        is_active: is_active ?? investorNews?.is_active,
      }

      if (!(data.image instanceof File)) delete data.image

      if (!investorNews?.author?.length && investorNews?.author_choices !== 'unspecified') {
        toast.error('Seleccione un autor de la novedad')
        setValidationErrors({ author: 'Ingrese el autor de la novedad' })
        return
      }

      if (files) {
        Object.assign(data, {
          files: files ?? [],
        })
      }

      const userSchema = yup.object({
        title: yup.string().required('Ingrese un título'),
        headline: yup.string().required('Ingrese un subtítulo'),
      })

      const { title } = data

      const postOrPatch = () => {
        const toastId = pendingToast(toast, `${id ? 'Guardando' : 'Creando'} novedad "${title}" ...`)
        ;(id
          ? updateInvestorsNews({
              id,
              data: {
                ...data,
                images_data: removeKeyFromArray(
                  data.images_data?.filter((id) => typeof id.file === 'string') ?? [],
                  'file',
                ),
              },
            })
          : createInvestorNews({
              data: {
                ...data,
                images_data: removeKeyFromArray(
                  data.images_data?.filter((id) => typeof id.file === 'string') ?? [],
                  'file',
                ),
              },
            })
        )
          .unwrap()
          .then((result: InvestorNews) => {
            const imagesToUpload = investorNews?.images_data?.filter((id) => id.file instanceof File)
            if (imagesToUpload?.length) {
              uploadGallery(result, imagesToUpload, 1, imagesToUpload.length)
            }
            updateSuccessToast(toast, toastId, `Novedad "${title}" ${id ? 'editada' : 'creada'} con éxito`)
            goToPreview && navigate(`/mediainvestor/novedades/previsualizar/${result.id}`)
            resolve(result)
          })
          .catch((error) => {
            updateErrorToast(
              toast,
              toastId,
              error.status === 403
                ? error?.data?.detail
                : `No se pudo ${id ? 'editar' : 'crear'} la novedad "${title}"`,
            )
            reject(error)
          })
      }

      userSchema
        .validate(
          {
            title: data.title,
            headline: data.headline,
            author: data.author,
          },
          {
            abortEarly: false,
          },
        )

        .then(() => postOrPatch())
        .catch((error: yup.ValidationError) => {
          const errorObject: NewsValidationErrors = {}
          error.inner.forEach((error) => {
            toast.error(error.message)
            Object.assign(errorObject, { [error.path ?? '']: error.message })
          })
          setValidationErrors(errorObject)
        })
    })
  }

  const emptyInvestorNews = {
    ownerships: [],
    date_published: moment().format('YYYY-MM-DDThh:mm'),
    title: '',
    headline: '',
    content: '',
    footer: '',
    is_active: false,
    prerelease: false,
    files: [],
    files_data: [],
    images_data: [],
    users: [],
    client: clientId,
    author: loggedUserName,
    author_choices: 'user',
    type,
    extra_info: {
      visualizedSteps: [0],
    },
    developments: [],
  }

  const markVisualizedStep = (step: number) => {
    if (!investorNews?.extra_info?.visualizedSteps?.includes(step))
      setInvestorNews((prevData) => ({
        ...prevData,
        extra_info: {
          visualizedSteps: [...(prevData?.extra_info?.visualizedSteps ?? []), step],
        },
      }))
  }

  useEffect(() => {
    setInvestorNews(queriedNews ?? emptyInvestorNews)
  }, [queriedNews])

  const isError = isErrorNews
  // || isErrorTags

  const isBusy = isUpdating || isCreating

  return {
    investorNews, //
    isLoadingNews,
    isError,
    handleDateChange,
    handleImageInput,
    handleAuthorSelectChange,
    handleFileInputChange,
    deleteFile,
    deleteGalleryImage,
    files,
    togglePrerelease,
    handleChange,
    handleChangeInvestors,
    handleChangeGalleryFiles,
    handleReorderGallery,
    handleGalleryAltChange,
    validationErrors,
    saveNews,
    isBusy,
    markVisualizedStep,
    addDevelopment,
    removeDevelopment,
    addInvestor,
    removeInvestor,
    isFetchingNews,
    toggleInvestors,
  }
}
