import { getAuth } from 'firebase/auth';
import { initializeApp } from "firebase/app";

const app = initializeApp({
    apiKey: process.env.GATSBY_FIREBASE_SERVICE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
})
export const auth = getAuth(app)

export const translateFirebaseError = (error: string): string => {
    switch (error) {
        case '':
            return ''
        case 'auth/invalid-email':
            return 'Email inválido'
        case "auth/user-not-found":
            return 'Usuario no registrado'
        case "auth/wrong-password":
            return 'Contraseña incorrecta'
        case 'auth/popup-closed-by-user':
            return ''
        case "auth/cancelled-popup-request":
            return ''
        case "auth/weak-password":
            return 'Contraseña débil'
        case "auth/email-already-in-use":
            return 'El email ya se encuentra en uso'
        case "auth/passwords-dont-match":
            return 'Las contraseñas no coinciden'
        case "auth/missing-email":
            return 'Ingrese el email'
    }
    return 'Ha ocurrido un error'
}
