import { useAppSelector } from '@redux/reduxHooks'

export const useSession = () => {
  const { client, user } = useAppSelector((state) => state.user)

  const hasBlogNewsCreatePermission = client?.permission_summary?.BLOG?.['blog-news']?.POST
  const hasBlogNewsUpdatePermission = client?.permission_summary?.BLOG?.['blog-news']?.PATCH
  const hasBlogNewsDeletePermission = client?.permission_summary?.BLOG?.['blog-news']?.DELETE
  const hasBlogNewsReadPermission = client?.permission_summary?.BLOG?.['blog-news']?.GET

  const hasBlogNewsPublishPermission = client?.permission_summary?.BLOG?.['blog-news']?.PUBLISH
  const hasBlogTagsCreatePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.POST
  const hasBlogTagsUpdatePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.PATCH
  const hasBlogTagsDeletePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.DELETE
  const hasBlogTagsReadPermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.GET

  const hasInvestorNewsCreatePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.POST
  const hasInvestorNewsUpdatePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.PATCH
  const hasInvestorNewsDeletePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.DELETE
  const hasInvestorNewsReadPermission = client?.permission_summary?.INVESTOR?.['investors-news']?.GET
  const hasInvestorNewsPublishPermission = client?.permission_summary?.INVESTOR?.['investors-news']?.PUBLISH

  const hasCustomLoginReadPermission = client?.permission_summary?.CORE?.['customlogin']?.GET

  const isInvestor = client?.permission_summary?.INVESTOR?.['is-investor']?.GET
  const isOwner = client?.permission_summary?.OWNER?.['is-owner']?.GET

  const hasInvestorsCreatePermission = client?.permission_summary?.INVESTOR?.['investors']?.POST
  const hasInvestorsUpdatePermission = client?.permission_summary?.INVESTOR?.['investors']?.PATCH
  const hasInvestorsDeletePermission = client?.permission_summary?.INVESTOR?.['investors']?.DELETE
  const hasInvestorsReadPermission = client?.permission_summary?.INVESTOR?.['investors']?.GET

  const hasOwnersCreatePermission = client?.permission_summary?.OWNER?.['owners']?.POST
  const hasOwnersUpdatePermission = client?.permission_summary?.OWNER?.['owners']?.PATCH
  const hasOwnersDeletePermission = client?.permission_summary?.OWNER?.['owners']?.DELETE
  const hasOwnersReadPermission = client?.permission_summary?.OWNER?.['owners']?.GET

  const hasOwnerNewsCreatePermission = client?.permission_summary?.OWNER?.['owners-news']?.POST
  const hasOwnerNewsUpdatePermission = client?.permission_summary?.OWNER?.['owners-news']?.PATCH
  const hasOwnerNewsDeletePermission = client?.permission_summary?.OWNER?.['owners-news']?.DELETE
  const hasOwnerNewsReadPermission = client?.permission_summary?.OWNER?.['owners-news']?.GET
  const hasOwnerNewsPublishPermission = client?.permission_summary?.OWNER?.['owners-news']?.PUBLISH

  const hasOwnerAppraisalsCreatePermission = client?.permission_summary?.OWNER?.['owners-appraisals']?.POST
  const hasOwnerAppraisalsUpdatePermission = client?.permission_summary?.OWNER?.['owners-appraisals']?.PATCH
  const hasOwnerAppraisalsDeletePermission = client?.permission_summary?.OWNER?.['owners-appraisals']?.DELETE
  const hasOwnerAppraisalsReadPermission = client?.permission_summary?.OWNER?.['owners-appraisals']?.GET
  const hasOwnerAppraisalsPublishPermission = client?.permission_summary?.OWNER?.['owners-appraisals']?.PUBLISH

  const hasOwnerReportsCreatePermission = client?.permission_summary?.OWNER?.['owners-reports']?.POST
  const hasOwnerReportsUpdatePermission = client?.permission_summary?.OWNER?.['owners-reports']?.PATCH
  const hasOwnerReportsDeletePermission = client?.permission_summary?.OWNER?.['owners-reports']?.DELETE
  const hasOwnerReportsReadPermission = client?.permission_summary?.OWNER?.['owners-reports']?.GET
  const hasOwnerReportsPublishPermission = client?.permission_summary?.OWNER?.['owners-reports']?.PUBLISH

  const hasOwnerVisitsCreatePermission = client?.permission_summary?.OWNER?.['owners-visits']?.POST
  const hasOwnerVisitsReadPermission = client?.permission_summary?.OWNER?.['owners-visits']?.GET
  const hasOwnerVisitsUpdatePermission = client?.permission_summary?.OWNER?.['owners-visits']?.PATCH
  const hasOwnerVisitsDeletePermission = client?.permission_summary?.OWNER?.['owners-visits']?.DELETE

  const hasJobsApplicationsCreatePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.POST
  const hasJobsApplicationsUpdatePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.PATCH
  const hasJobsApplicationsDeletePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.DELETE
  const hasJobsApplicationsReadPermission = client?.permission_summary?.JOBS?.['jobs-applications']?.GET

  const hasJobsPositionsCreatePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.POST
  const hasJobsPositionsUpdatePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.PATCH
  const hasJobsPositionsDeletePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.DELETE
  const hasJobsPositionsReadPermission = client?.permission_summary?.JOBS?.['jobs-positions']?.GET
  const hasJobsPositionsPublishPermission = client?.permission_summary?.JOBS?.['jobs-positions']?.PUBLISH

  const hasTicketsCreatePermission = client?.permission_summary?.CORE?.['support-tickets']?.POST

  const hasUsersPermission = client?.permission_summary?.CORE?.['users']?.ADMIN

  const hasPushActionsCreatePermission = client?.permission_summary?.PUSH?.['push-actions']?.POST
  const hasPushActionsUpdatePermission = client?.permission_summary?.PUSH?.['push-actions']?.PATCH
  const hasPushActionsDeletePermission = client?.permission_summary?.PUSH?.['push-actions']?.DELETE
  const hasPushActionsReadPermission = client?.permission_summary?.PUSH?.['push-actions']?.GET
  const hasPushActionsPublishPermission = client?.permission_summary?.PUSH?.['push-actions']?.PUBLISH

  const hasTrackerAnalyticsReadPermission = client?.permission_summary?.TRACKER?.['analytics']?.GET
  const hasTrackerAnalyticsCreatePermission = client?.permission_summary?.TRACKER?.['analytics-property']?.POST
  const hasTrackerReportsReadPermission = client?.permission_summary?.TRACKER?.['analytics-reportes']?.GET
  const hasTrackerRealtimeReportsReadPermission =
    client?.permission_summary?.TRACKER?.['analytics-reportes-realtime']?.GET

  return {
    client,
    user,
    hasBlogNewsCreatePermission,
    hasBlogNewsUpdatePermission,
    hasBlogNewsDeletePermission,
    hasBlogNewsReadPermission,
    hasBlogNewsPublishPermission,
    hasBlogTagsCreatePermission,
    hasBlogTagsUpdatePermission,
    hasBlogTagsDeletePermission,
    hasBlogTagsReadPermission,
    hasInvestorNewsCreatePermission,
    hasInvestorNewsUpdatePermission,
    hasInvestorNewsDeletePermission,
    hasInvestorNewsReadPermission,
    hasInvestorNewsPublishPermission,
    hasCustomLoginReadPermission,
    isInvestor,
    isOwner,
    hasInvestorsCreatePermission,
    hasInvestorsUpdatePermission,
    hasInvestorsDeletePermission,
    hasInvestorsReadPermission,
    hasOwnerNewsCreatePermission,
    hasOwnerNewsUpdatePermission,
    hasOwnerNewsDeletePermission,
    hasOwnerNewsReadPermission,
    hasOwnerAppraisalsCreatePermission,
    hasOwnerAppraisalsUpdatePermission,
    hasOwnerAppraisalsDeletePermission,
    hasOwnerAppraisalsReadPermission,
    hasOwnerReportsCreatePermission,
    hasOwnerReportsUpdatePermission,
    hasOwnerReportsDeletePermission,
    hasOwnerReportsReadPermission,
    hasOwnerNewsPublishPermission,
    hasOwnerAppraisalsPublishPermission,
    hasOwnerReportsPublishPermission,
    hasOwnerVisitsCreatePermission,
    hasOwnerVisitsReadPermission,
    hasOwnerVisitsUpdatePermission,
    hasOwnerVisitsDeletePermission,
    hasOwnersCreatePermission,
    hasOwnersUpdatePermission,
    hasOwnersDeletePermission,
    hasOwnersReadPermission,
    hasJobsApplicationsCreatePermission,
    hasJobsApplicationsUpdatePermission,
    hasJobsApplicationsDeletePermission,
    hasJobsApplicationsReadPermission,
    hasJobsPositionsCreatePermission,
    hasJobsPositionsUpdatePermission,
    hasJobsPositionsDeletePermission,
    hasJobsPositionsReadPermission,
    hasJobsPositionsPublishPermission,
    hasTicketsCreatePermission,
    hasUsersPermission,
    hasPushActionsCreatePermission,
    hasPushActionsUpdatePermission,
    hasPushActionsDeletePermission,
    hasPushActionsReadPermission,
    hasPushActionsPublishPermission,
    hasTrackerAnalyticsReadPermission,
    hasTrackerAnalyticsCreatePermission,
    hasTrackerReportsReadPermission,
    hasTrackerRealtimeReportsReadPermission,
  }
}
