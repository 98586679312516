import { createSlice } from '@reduxjs/toolkit'
import { Client, User } from '@types'

interface State {
  idToken?: string
  color?: string
  gradientComponents?: boolean
  customPalette?: boolean
  client?: Client
  user?: Partial<User>
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    idToken: 'testToken123', //
    color: '#0B6BCB',
    gradientComponents: false,
    customPalette: false,
  } as State,
  reducers: {
    updateIdToken: {
      reducer(state, action) {
        state.idToken = action.payload
      },
      prepare(idToken) {
        return {
          payload: idToken,
          meta: '',
          error: '',
        }
      },
    },
    changeUserColor: {
      reducer(state, action) {
        state.color = action.payload
      },
      prepare(color) {
        return {
          payload: color,
          meta: '',
          error: '',
        }
      },
    },
    toggleGradientComponents: {
      reducer(state) {
        state.gradientComponents = !state.gradientComponents
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    toggleCustomPalette: {
      reducer(state) {
        state.customPalette = !state.customPalette
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
    setClient: {
      reducer(state, action) {
        state.client = action.payload
      },
      prepare(client) {
        return {
          payload: client,
          meta: '',
          error: '',
        }
      },
    },
    setUser: {
      reducer(state, action) {
        state.user = action.payload
      },
      prepare(user) {
        return {
          payload: user,
          meta: '',
          error: '',
        }
      },
    },
  },
})

export const {
  updateIdToken, //
  changeUserColor,
  toggleGradientComponents,
  toggleCustomPalette,
  setClient,
  setUser,
} = userSlice.actions
