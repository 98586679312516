import React, { createContext, useEffect } from 'react'
import { auth } from '@firebaseUtils/firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { navigate } from 'gatsby'
import { useGetByUidMutation } from '@redux/apis/mediacoreApi'
import { useAppDispatch } from '@redux/reduxHooks'
import { setUser, updateIdToken } from '@slices/userSlice'

const isBrowser = typeof window !== 'undefined'
interface Props {
  children: JSX.Element
}

export const UserContext = createContext<any>({ userData: {} })

const Auth = ({ children }: Props) => {
  const location = isBrowser ? window.location : null
  const dispatch = useAppDispatch()
  const [getByUid, userData] = useGetByUidMutation()

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        if (!location?.pathname.includes('/sesion/inicio/')) navigate('/sesion/inicio/')
        return
      }
      const idToken = await auth.currentUser?.getIdToken(true)
      dispatch(updateIdToken(idToken))
      const uid = auth.currentUser?.uid ?? ''
      const userData = await getByUid({ uid }).unwrap()
      dispatch(setUser(userData))
      // TODO: Catch this error
      if (!userData) {
        signOut(auth)
        return
      }
      if (location?.pathname.includes('/sesion/inicio/')) navigate('/')
    })
  }, [])

  return <UserContext.Provider value={{ userData, getByUid }}>{children}</UserContext.Provider>
}

export default Auth
