import { google_icons } from '@constants'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  icon: typeof google_icons[number] | string
  tooltip?: string
  category?: 'outlined'
}
/**
 * @name Icon
 * @category Components
 * @description An icon from Google Material Icons font (Filled)
 * @param icon The icon ligature
 * @param tooltip Tooltip (optional)
 * @example
 * <Icon icon="check_circle_outline" />
 * @link https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons
 */
export const Icon = ({ tooltip, icon, className, category, ...rest }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & Props) => {
  return (
    <OverlayTrigger
      show={tooltip ? undefined : false}
      placement="top"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <i
        {...rest}
        className={`material-icons${category ? `-${category}` : ''} ${className ?? ''}`}
      >
        {icon}
      </i>
    </OverlayTrigger>
  )
}
