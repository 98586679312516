import { UserContext } from '@components/Auth'
import { ClientContext } from '@components/ClientContextWrapper'
import {
  useCreateNotificationSettingMutation,
  useGetNotificationsSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@endpoints/notificationsEndpoint '
import { Module, NotificationSetting } from '@types'
import { pendingToast, updateErrorToast, updateSuccessToast } from '@utils'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const UseNotifications = () => {
  const { selectedClientDetails } = useContext(ClientContext)
  const { userData } = useContext(UserContext)

  const [updateNotification] = useUpdateNotificationSettingsMutation()
  const [createNotification] = useCreateNotificationSettingMutation()

  const { data: queriedNotificationSetting, isLoading: isLoadingNotificationSetting } =
    useGetNotificationsSettingsQuery(
      { clientId: selectedClientDetails?.id, user: userData?.data?.id },
      { skip: userData.status !== 'fulfilled' || !selectedClientDetails?.id },
    )

  const userNotificationSettings = queriedNotificationSetting?.[0]

  const [notification, setNotification] = useState<NotificationSetting['data']>()

  useEffect(() => {
    const updatedNotifications: NotificationSetting['data'] = selectedClientDetails?.modules?.map((module: Module) => ({
      module: module.code,
      in_app: true,
      email: true,
    }))
    userNotificationSettings
      ? setNotification(userNotificationSettings?.data ?? [])
      : setNotification(updatedNotifications)
  }, [queriedNotificationSetting, selectedClientDetails])

  const handleChangeAndSave = (module: string, notification_type: string) => {
    const updateNotificationArray = (notifications: NotificationSetting['data']) => {
      return notifications.map((notification) =>
        notification.module === module
          ? {
              ...notification,
              [notification_type]: !notification[notification_type as keyof typeof notification],
            }
          : notification,
      )
    }

    setNotification((prevData) => updateNotificationArray(prevData ?? []))

    const toastId = pendingToast(toast, 'Guardando configuración...')
    ;(userNotificationSettings
      ? updateNotification({
          id: userNotificationSettings?.id!,
          client: selectedClientDetails.id,
          user: userData.data.id,
          data: updateNotificationArray(notification ?? []),
        })
      : createNotification({
          client: selectedClientDetails.id,
          user: userData.data.id,
          data: updateNotificationArray(notification ?? []),
        })
    )
      .unwrap()
      .then(() => updateSuccessToast(toast, toastId, 'Configuración guardada con éxito'))
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo guardar la configuración`,
        )
      })
  }

  return { notification, handleChangeAndSave, isLoadingNotificationSetting }
}
