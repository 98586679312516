import {
  useCreateOwnerNewsMutation,
  useGetOwnerNewsByIdQuery,
  useUpdateOwnerNewsMutation,
} from '@endpoints/ownersEndpoint'
import { useGetTokkoPropertyTableQuery } from '@endpoints/tablesEndpoint'
import { OwnerNews, TokkoProperties } from '@types'
import { onlyUnique, realAddressComponents, removeKeyFromArray } from '@utils'
import { navigate } from 'gatsby'
import moment, { Moment } from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { Id, toast } from 'react-toastify'

interface Props {
  id?: number
  clientId?: number
  handleShowForm: ({ show, type }: { show: boolean; type?: 'Novedad' | 'Tasación' | 'Reporte' | undefined }) => void
}

export const useOwnerNews = ({ id, clientId, handleShowForm }: Props) => {
  const {
    data: queriedOwnerNews,
    isLoading: isLoadingNews,
    isError: isErrorNews,
  } = useGetOwnerNewsByIdQuery({ id: id!, clientId: clientId! }, { skip: !id || !clientId })

  const [updateOwnerNews, { isLoading: isUpdatingOwnerNews }] = useUpdateOwnerNewsMutation()
  const [createOwnerNews, { isLoading: isCreatingOwnerNews }] = useCreateOwnerNewsMutation()

  const isBusyOwnerNews = isUpdatingOwnerNews || isCreatingOwnerNews

  const blankOwnerNews: Partial<OwnerNews> = {
    date_published: moment().format('YYYY-MM-DDTHH:mm'),
    title: '',
    headline: '',
    content: '',
    footer: '',
    is_active: false,
    featured: false,
    extra_info: {
      visualizedSteps: [0],
    },
  }

  const [ownerNews, setOwnerNews] = useState(blankOwnerNews)
  const [files, setFiles] = useState<File[] | null>()

  const {
    data: allProperties,
    isLoading: isLoadingProperties,
    isError: isErrorProperties,
  } = useGetTokkoPropertyTableQuery(
    { clientId: clientId!, owners: true, ids: ownerNews?.tokko_property ?? [] },
    { skip: !clientId },
  )

  const loading = isLoadingNews || isLoadingProperties
  const error = isErrorNews || isErrorProperties

  useEffect(() => {
    setOwnerNews(
      id && queriedOwnerNews
        ? {
            ...queriedOwnerNews,
            extra_info: {
              ...queriedOwnerNews.extra_info,
              visualizedSteps: queriedOwnerNews.extra_info.visualizedSteps.map((step: number) => step - 1),
            },
          }
        : blankOwnerNews,
    )
  }, [id, queriedOwnerNews])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setOwnerNews((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleContentChange = (contentHtml: string) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      content: contentHtml,
    }))
  }

  const handleDateChange = (date: Moment | null) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      date_published: date?.format('YYYY-MM-DDTHH:mm') ?? '',
    }))
  }

  const handleFileInput = (newFiles: File[] | null) => {
    setFiles((prevFiles) => [...(prevFiles ?? []), ...(newFiles ?? [])])
    setOwnerNews((prevData) => ({
      ...prevData,
      files_data: [...(prevData?.files_data ?? []), ...(newFiles?.map((nf) => ({ file_name: nf.name })) ?? [])],
    }))
  }
  const handleImageInput = (files: File[] | null) => {
    if (files?.length) {
      setOwnerNews((prevData) => ({
        ...prevData,
        image: files[0],
      }))
    }
  }
  const deleteImage = () => {
    setOwnerNews((prevData) => ({
      ...prevData,
      image: '',
    }))
  }

  const removeFile = (index: number) => {
    setOwnerNews((prevData) => {
      let newFiles = [...(prevData?.files_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        files_data: newFiles,
      }
    })
  }

  const handleModalVisibility = (show: boolean) => {
    handleShowForm({ show })
  }

  const saveOwnerNews = (is_active: boolean, goToPreview?: boolean) => {
    const data = {
      ...ownerNews,
      client: id ? ownerNews?.client : clientId,
      files_data: removeKeyFromArray(ownerNews?.files_data ?? [], 'file'),
      is_active,
      tokko_property: !ownerNews.available_to_all ? ownerNews.tokko_property ?? [] : [],
      extra_info: {
        ...ownerNews.extra_info,
        real_address:
          allProperties?.results
            ?.filter((property) => ownerNews.tokko_property?.includes(property.id))
            ?.map((property) => realAddressComponents(property.real_address)?.address)
            ?.join(', ') ?? '',
      },
    }

    if (!(data.image instanceof File)) delete data.image

    if (files) {
      Object.assign(data, {
        files: files ?? [],
      })
    }

    if (typeof is_active === 'boolean') data.is_active = is_active

    const pendingToast = () => {
      return toast.loading(`${id ? 'Guardando' : 'Creando'} novedad "${data.title}" ...`)
    }

    const successToast = (toastId: Id) => {
      toast.update(toastId, {
        render: `Novedad "${data.title}" ${id ? 'editada' : 'creada'} con éxito`,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        autoClose: 3000,
      })
    }

    const errorToast = (toastId: Id, errorMsg?: string) => {
      toast.update(toastId, {
        render: errorMsg ?? `No se pudo ${id ? 'editar' : 'crear'} la novedad "${data.title}"`,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 3000,
      })
    }

    const toastId = pendingToast()
    ;(id
      ? updateOwnerNews({
          id,
          data,
        })
      : createOwnerNews({
          data,
        })
    )
      .unwrap()
      .then((savedNews: OwnerNews) => {
        const basePath = '/mediaowner/comunicaciones/'
        successToast(toastId)
        handleModalVisibility(false)
        goToPreview && navigate(`${basePath}novedades/previsualizar/${savedNews.id}/`)
      })
      .catch((error) => {
        errorToast(toastId, error.status === 403 ? error?.data?.detail : undefined)
      })
  }

  const handleChangeProperty = (property: { id: number; owners: number[] }) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      tokko_property: [...(ownerNews.tokko_property ?? []), property.id],
      users: property.owners,
    }))
  }

  const deleteProperty = (property: TokkoProperties) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      tokko_property: [...(ownerNews?.tokko_property?.filter((propId) => propId !== property.id) ?? [])],
    }))
  }

  const handleSelectAllProperties = () => {
    setOwnerNews((prevData) => ({
      ...prevData,
      available_to_all: !ownerNews.available_to_all,
      tokko_property: !ownerNews.available_to_all ? [] : ownerNews?.tokko_property,
    }))
  }

  const markVisualizedStep = (step: number) => {
    if (!ownerNews?.extra_info?.visualizedSteps?.includes(step))
      setOwnerNews((prevData) => ({
        ...prevData,
        extra_info: {
          ...prevData.extra_info,
          visualizedSteps: [...prevData?.extra_info?.visualizedSteps, step],
        },
      }))
  }
  const isStepVisualized = (step: number) => !!ownerNews?.extra_info?.visualizedSteps?.includes(step)
  const stepsCompleted = (() => {
    let isCompleted = [
      isStepVisualized(0),
      !!(isStepVisualized(id ? 0 : 1) && !!ownerNews && !!ownerNews.title && !!ownerNews.headline),
      !!(isStepVisualized(id ? 1 : 2) && (ownerNews?.available_to_all || !!ownerNews?.tokko_property?.length)),
      isStepVisualized(id ? 2 : 3),
    ]
    if (id) {
      isCompleted.shift()
    }
    return isCompleted
  })()

  const addOwner = (userId: number) => {
    if (ownerNews?.users?.includes(userId)) return
    setOwnerNews((prevData) => ({
      ...prevData,
      users: [...(prevData?.users ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      users: users.every((user) => prevData?.users?.includes(user))
        ? prevData?.users?.filter((id) => !users.includes(id))
        : [...(prevData?.users ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      users: prevData?.users?.filter((id) => id !== userId),
    }))
  }

  return {
    ownerNews,
    setOwnerNews,
    loading,
    error,
    isBusyOwnerNews,
    handleChange,
    handleContentChange,
    handleDateChange,
    handleFileInput,
    files,
    handleImageInput,
    deleteImage,
    removeFile,
    saveOwnerNews,
    markVisualizedStep,
    stepsCompleted,
    handleChangeProperty,
    handleSelectAllProperties,
    allProperties,
    isLoadingProperties,
    isErrorProperties,
    deleteProperty,
    addOwner,
    removeOwner,
    toggleOwners,
  }
}
