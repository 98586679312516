import React from 'react'
import SpinnerCenter from '@components/SpinnerCenter'
import { useSession } from '@hooks/useSession'

interface Props {
  loading?: boolean
  children: JSX.Element
  error?: boolean
  errorMessage?: string
  size?: 'sm'
}

const SpinnerWrapper = ({
  loading,
  children,
  error,
  errorMessage = 'Error cargando el contenido',
  size,
  ...rest
}: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { client } = useSession()
  return loading || !client?.id ? (
    <div {...rest}>
      <SpinnerCenter size={size} />
    </div>
  ) : error ? (
    <h5 {...rest}>{errorMessage}</h5>
  ) : (
    children
  )
}

export default SpinnerWrapper
