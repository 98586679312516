import CircularProgress from '@mui/joy/CircularProgress'
import React from 'react'

interface Props {
  show: boolean
}

export const MainSpinner = ({ show }: Props) => {
  return (
    <div
      style={{
        transition: '0.5s',
        zIndex: show ? '9999' : '-1',
        opacity: show ? 1 : 0,
        backgroundColor: 'var(--mui-palette-background-default)',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </div>
  )
}
