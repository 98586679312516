import { Link, navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import { SideBarItemType } from '@types'
import { authRoles, routes } from '@constants'
import { UserContext } from '@components/Auth'
import { ClientContext } from '@components/ClientContextWrapper'
import { usePrefetchUsers } from '@endpoints/usersEndpoint'
import { usePrefetchInvestor } from '@endpoints/investorEndpoint'
import { usePrefetchBlog } from '@endpoints/newsEndpoint'
import { useGetHasSomePermissionQuery, usePrefetch } from '@apis/mediacoreApi'
import { usePrefetchOwnerEndpoint } from '@endpoints/ownersEndpoint'
import { usePrefetchAppraisals } from '@endpoints/appraisalsEndpoint'
import { usePrefetchReports } from '@endpoints/reportsEndpoint'
import SpinnerWrapper from './SpinnerWrapper'
import Drawer from '@mui/joy/Drawer'
import Typography from '@mui/joy/Typography'
import Box from '@mui/joy/Box'
import ModalClose from '@mui/joy/ModalClose'
import { List, ListItem, ListItemButton, ListItemContent } from '@mui/joy'
import Stack from '@mui/joy/Stack'
import { globalStyles } from '@styles/styles'
import { useSession } from '@hooks/useSession'
import { ArrowRight } from '@mui/icons-material'
interface Props {
  showMobileMenu: boolean
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

interface SideBarProps {
  item: SideBarItemType
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const styles = {
  sidebarItem: {
    listItem: {
      marginBottom: '0.5rem',
    },
    listItemButton: {
      borderRadius: globalStyles.borderRadius,
      fontSize: '14px',
    },
    closedList: {
      transition: 'all 0.15s ease 0s',
      transform: 'scaleY(0)',
      maxHeight: '0px',
      marginBottom: '0',
    },
    openList: {
      transition: 'all 0.15s ease 0s',
      transform: 'scaleY(1)',
      marginBottom: '2rem',
    },
  },
  sideBar: {
    box: {
      paddingX: 4,
      paddingY: 1,
    },
  },
  drawer: {
    title: {
      width: 307.83,
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    },
    modalClose: {
      position: 'relative',
      right: 0,
      top: 0,
    },
    agencyTitle: {
      width: 307.83,
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
      borderTop: globalStyles.border,
    },
  },
}

const isBrowser = typeof window !== 'undefined'

const SideBarItem = ({ item, setShowMobileMenu }: SideBarProps) => {
  const [expandSubItems, setExpandSubItems] = useState(item.title === 'Inversor' || item.title === 'Propietario')
  return (
    <>
      <ListItem sx={styles.sidebarItem.listItem}>
        <ListItemButton
          variant="plain"
          sx={styles.sidebarItem.listItemButton}
          onClick={() => {
            if (item.link) {
              navigate(item.link)
              setShowMobileMenu(false)
              return
            }
            !item.lockedItem && setExpandSubItems((value) => !value)
            item.prefetchFunctions?.()
          }}
        >
          <ListItemContent>{item.title}</ListItemContent>
          {/* <Navbar.Text>
          <i
            style={item.lockedItem ? { fontSize: '1rem', opacity: '50%' } : { opacity: '50%', fontSize: '1.5rem' }}
            className="material-icons mx-1"
          >
            {item.lockedItem ? 'lock_outline' : 'expand_more'}
          </i>
        </Navbar.Text> */}
        </ListItemButton>
      </ListItem>
      {!!item.subItems?.length && (
        <List
          className={`px-3`}
          style={!expandSubItems || item.lockedItem ? styles.sidebarItem.closedList : styles.sidebarItem.openList}
        >
          {item.subItems
            ?.filter((subItem) => !subItem.hideCondition)
            .map((subItem) => (
              <ListItem key={`${item.title}-${subItem.title}`}>
                <Link
                  to={subItem.link}
                  style={{ cursor: 'pointer', textDecoration: 'none', display: 'block' }}
                  onClick={() => {
                    setShowMobileMenu(false)
                  }}
                >
                  <ArrowRight />
                  {subItem.title}
                </Link>
              </ListItem>
            ))}
        </List>
      )}
    </>
  )
}

const SideNavBar = ({ showMobileMenu, setShowMobileMenu }: Props) => {
  const { userData } = useContext(UserContext)
  const prefetchUsers = usePrefetchUsers('getUsers')
  const prefetchDevelopments = usePrefetchInvestor('getDevelopments')
  const prefetchPermissions = usePrefetch('getPermissions')
  const prefetchBlogTags = usePrefetchBlog('getTags')
  const prefetchAppraisals = usePrefetchAppraisals('getAppraisals')
  const prefetchReports = usePrefetchReports('getReports')
  const prefetchOwnerNews = usePrefetchOwnerEndpoint('getOwnerNews')
  const { selectedClient, selectedClientDetails } = useContext(ClientContext)
  const notAuthenticated = userData.status !== 'fulfilled'

  const { data: hasSomePermission, isLoading: isLoadingAvailableModules } = useGetHasSomePermissionQuery(
    {
      client: selectedClientDetails?.id,
    },
    { skip: notAuthenticated || !selectedClientDetails?.id },
  )

  const available_modules = hasSomePermission?.available_modules ?? []

  const { isInvestor, isOwner } = useSession()

  const isProspect = selectedClientDetails?.status === 'Prospecto'

  const sidebarItems = [
    {
      title: 'Inicio',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      active: isBrowser && location?.pathname.includes('/'),
      link: '/',
    },
    {
      title: 'MediaTracker',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      icon: 'insert_chart_outlined',
      active: isBrowser && location?.pathname.includes('/mediatracker'),
      lockedItem: !available_modules?.includes('TRACKER'),
      link: routes.tracker.analytics,
    },
    {
      title: 'MediaBlog',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      icon: 'edit_note',
      active: isBrowser && location?.pathname.includes('/mediablog'),
      link: '/mediablog/novedades',
      prefetchFunctions: () => {
        if (isBrowser && !location?.pathname.includes('/mediablog')) {
          // Avoid refetching while filling form
          prefetchBlogTags({ clientId: Number(selectedClient) }, { ifOlderThan: 60 })
        }
      },
      lockedItem: !available_modules?.includes('BLOG'),
    },
    {
      title: 'MediaPush',
      icon: 'ondemand_video',
      active: isBrowser && location?.pathname.includes('/mediapush'),
      prefetchFunctions: () => {},
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      lockedItem: !available_modules?.includes('PUSH'),
      link: '/mediapush/destaques',
    },
    {
      title: 'MediaInvestor',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      icon: 'currency_exchange',
      lockedItem: !available_modules?.includes('INVESTOR'),
      active: isBrowser && location?.pathname.includes('/mediainvestor'),
      prefetchFunctions: () => {
        if (isBrowser && !location?.pathname.includes('/mediainvestor')) {
          // Avoid refetching while filling form
          prefetchUsers(undefined, { ifOlderThan: 60 })
          prefetchPermissions(undefined, { ifOlderThan: 60 })
          prefetchDevelopments({ clientId: Number(selectedClient) }, { ifOlderThan: 60 })
        }
      },
      link: '/mediainvestor/novedades',
    },
    {
      title: 'MediaOwner',
      icon: 'apartment',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      lockedItem: !available_modules?.includes('OWNER'),
      link: '/mediaowner/comunicaciones',
      active: isBrowser && location?.pathname.includes('/mediaowner'),
      prefetchFunctions: () => {
        if (isBrowser && !location?.pathname.includes('/mediaowner')) {
          prefetchAppraisals({ clientId: Number(selectedClient) }, { ifOlderThan: 60 })
          prefetchReports({ clientId: Number(selectedClient) }, { ifOlderThan: 60 })
          prefetchOwnerNews({ clientId: Number(selectedClient) }, { ifOlderThan: 60 })
        }
      },
    },
    {
      title: 'MediaJobs',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      icon: 'person_search',
      lockedItem: !available_modules?.includes('JOBS'),
      link: '/mediajobs/posiciones',
      active: isBrowser && location?.pathname.includes('/mediajobs'),
    },
    {
      title: 'Documentos',
      icon: 'description',
      showItem:
        !isInvestor &&
        !isOwner &&
        isProspect &&
        selectedClientDetails?.files_data?.filter(
          (item: { label: string; order: number }) => item.order === 1 || item.order === 2,
        )?.length > 0,
      active: isBrowser && location?.pathname.includes('/documentos'),
      subItems: selectedClientDetails?.files_data
        ?.filter((item: { label: string; order: number }) => item.order === 1 || item.order === 2)
        ?.map((item: { label: string; order: number }) => ({
          title: item.label,
          link: item.order === 1 ? '/presupuesto_tentativo' : '/propuesta_comercial',
        })),
    },
  ]

  const sideBar = (
    <SpinnerWrapper
      className="my-5"
      loading={isLoadingAvailableModules}
    >
      <Box sx={styles.sideBar.box}>
        <List>
          {selectedClient &&
            sidebarItems
              .filter((sidebarItem) => sidebarItem.showItem)
              .map((sidebarItem) => (
                <SideBarItem
                  item={sidebarItem}
                  key={sidebarItem.title}
                  setShowMobileMenu={setShowMobileMenu}
                />
              ))}
        </List>
      </Box>
    </SpinnerWrapper>
  )

  const agencyLinks = [
    {
      title: 'Grupos y permisos',
      link: '/configuracion/grupos_y_permisos',
    },
    {
      title: 'Componentes MediaSite',
      link: '/configuracion/componentes_mediasite',
    },
    {
      title: 'Registros DNS',
      link: '/mediasite/dns',
    },
    {
      title: 'Templates',
      link: '/mediasite/templates',
    },
    {
      title: 'Categorias de templates',
      link: '/mediasite/templates/categorias',
    },
    {
      title: 'Tipografías',
      link: '/mediasite/fuentes',
    },
  ]

  return (
    <Drawer
      anchor="left"
      open={showMobileMenu}
      onClose={() => setShowMobileMenu(false)}
      slotProps={{
        content: {
          sx: {
            width: styles.drawer.title.width,
          },
        },
      }}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={styles.drawer.title}
      >
        <Typography level="h3">MediaAgency</Typography>
        <ModalClose
          size="lg"
          sx={styles.drawer.modalClose}
          onClick={() => setShowMobileMenu(false)}
        />
      </Stack>
      {sideBar}
      {authRoles.ADMIN.includes(userData?.data?.role.name) && (
        <>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            sx={styles.drawer.agencyTitle}
          >
            <Typography level="h3">Accesos Agencia</Typography>
          </Stack>
          <Box sx={styles.sideBar.box}>
            <List>
              {agencyLinks.map(({ title, link }) => (
                <ListItem
                  key={title}
                  sx={styles.sidebarItem.listItem}
                >
                  <ListItemButton
                    variant="plain"
                    sx={styles.sidebarItem.listItemButton}
                    onClick={() => {
                      if (link) {
                        navigate(link)
                        setShowMobileMenu(false)
                        return
                      }
                    }}
                  >
                    <ListItemContent>{title}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </Drawer>
  )
}

export default SideNavBar

// TODO: Add a mobile version of the app?
