import React, { useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

/*
 * Simple editor component that takes placeholder text as a prop
 */
/*

 * PropType validation
 */
interface Props {
  placeholder?: string
  content: string
  defaultValue?: string
  handleContentChange?: any
  readOnly?: boolean
  noToolbar?: boolean
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block', 'link'],

    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ['image', 'video'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'align',
  'background',
  'bold',
  'blockquote',
  'bullet',
  'color',
  'code',
  'code-block',
  'clean',
  'direction',
  'font',
  'header',
  'italic',
  'indent',
  'image',
  'list',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'video',
]

const RichTextEditor = ({
  content,
  placeholder,
  handleContentChange,
  defaultValue,
  readOnly = false,
  noToolbar,
}: Props) => {
  const ref = useRef<ReactQuill>(null)
  return (
    <div
      onClick={() => {
        if (document.activeElement === document.body) {
          ref.current?.focus()
        }
      }}
    >
      <ReactQuill
        className={readOnly ? 'no-border-children' : undefined}
        ref={ref}
        readOnly={readOnly}
        theme="snow"
        onChange={handleContentChange}
        value={content}
        modules={{ ...modules, toolbar: noToolbar ? false : modules.toolbar }}
        formats={formats}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default RichTextEditor
