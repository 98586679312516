import React, { CSSProperties, memo, useEffect, useState } from 'react'
import { Button, Col, Form, ProgressBar, Row, Spinner, Stack } from 'react-bootstrap'
import Title from '@components/Title'
import { navigate } from 'gatsby'
import {
  AccordionGroup,
  AspectRatio,
  Divider,
  Grid,
  Button as JoyButton,
  Stack as JoyStack,
  Stepper as JoyStepper,
  ModalClose,
  Step,
  StepIndicator,
  Typography,
  accordionClasses,
  stepClasses,
  stepIndicatorClasses,
} from '@mui/joy'
import { Check, DeleteOutlined, OpenWithOutlined } from '@mui/icons-material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { globalStyles } from '@styles/styles'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { JoyStyles } from '@types'

export interface Step {
  completed: boolean
  content: JSX.Element
  title: string
  subtitle?: string
  isLoading?: boolean
  customPageTitle?: string
  noTitle?: boolean
  hidden?: boolean
}

interface StepProps {
  step: number
  title: string
}

interface Props {
  steps: Step[]
  children?: JSX.Element
  onVisualizeStep?: (step: number) => void
  onClickCapture?: (e: React.MouseEvent<HTMLButtonElement>) => void
  backButtonTarget?: string
  customBackButton?: JSX.Element
  hiddenProggressBar?: boolean
  style?: 'default' | 'joy'
  buttons?: React.ReactNode[]
  title?: string
  nextDisabled?: boolean
}

const Stepper = ({
  customBackButton,
  steps: hiddenIncludedSteps,
  children,
  onVisualizeStep,
  onClickCapture,
  backButtonTarget,
  hiddenProggressBar,
  buttons,
  style = 'default',
  title,
  nextDisabled,
}: Props) => {
  const steps = hiddenIncludedSteps.filter(({ hidden }) => !hidden)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (onVisualizeStep) onVisualizeStep(activeStep ?? 0)
  }, [activeStep])

  const StepCheckBox = ({ step, title }: StepProps) => (
    <div
      className="py-3 border-bottom"
      onClick={() => setActiveStep(step)}
      key={`step-${step}`}
    >
      {steps[step].isLoading ? (
        <div style={{ cursor: 'pointer' }}>
          <Spinner
            animation="border"
            size="sm"
            className="spinner"
          />
          <label className={`form-check ${!steps[step].completed ? 'opacity-50' : ''}`}>{title}</label>
        </div>
      ) : (
        <Form.Check
          label={title}
          type="checkbox"
          style={{ cursor: 'pointer' }}
          checked={steps[step].completed}
          disabled={!steps[step].completed}
          onChange={() => null}
        />
      )}
    </div>
  )

  const completedPercentage = Math.round((steps.filter((step) => step.completed).length / steps.length) * 100)

  const styles: JoyStyles = {
    titleBar: {
      height: 1.5 * globalStyles.navBarHeight,
      padding: 3,
    },
    content: {
      paddingX: 3,
      paddingTop: 5,
      overflowY: 'auto',
      height: `calc(100vh - ${(3.5 + (steps.length > 1 ? 1.5 : 0)) * globalStyles.navBarHeight}px)`,
    },
    buttons: {
      paddingX: 3,
      paddingY: '0.5rem',
      position: 'fixed',
      bottom: 0,
      width: '-webkit-fill-available', // TODO: Check browser compatibility
      borderTop: globalStyles.border,
      height: globalStyles.navBarHeight,
      justifyContent: 'space-between',
    },
  }

  return style === 'joy' ? (
    <>
      {title && <StepperTitle title={title} />}
      <CustomStepper
        steps={steps.map(({ completed }) => completed)}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        nextDisabled={nextDisabled}
      />
      <JoyStack
        id="titleBar"
        sx={styles.titleBar}
      >
        <Typography
          level="h2"
          color="primary"
        >
          {`${activeStep + 1}- ` +
            (steps[activeStep].customPageTitle ? steps[activeStep].customPageTitle ?? '' : steps[activeStep].title)}
        </Typography>
        {steps[activeStep].subtitle && <Typography level="body-md-light">{steps[activeStep].subtitle}</Typography>}
      </JoyStack>
      <JoyStack
        id="content"
        sx={styles.content}
      >
        {steps[activeStep].content}
      </JoyStack>
      <JoyStack
        id="buttons"
        direction="row"
        sx={styles.buttons}
      >
        <JoyStack
          direction="row"
          gap={2}
          sx={{ visibility: steps.length > 1 ? 'visible' : 'hidden' }}
        >
          <BackButton
            disabled={!activeStep}
            setActiveStep={setActiveStep}
          />
          <Divider orientation="vertical" />
          <NextButton
            disabled={activeStep > steps.length - 2 || nextDisabled}
            setActiveStep={setActiveStep}
          />
        </JoyStack>
        <JoyStack
          direction="row"
          gap={2}
        >
          {buttons}
        </JoyStack>
      </JoyStack>
    </>
  ) : (
    <Row className="stepper">
      <Col
        xs={6}
        md={7}
        lg={8}
        xl={9}
        className="px-5"
      >
        {!steps[activeStep].noTitle && (
          <Title
            title={
              steps[activeStep].customPageTitle ? steps[activeStep].customPageTitle ?? '' : steps[activeStep].title
            }
          ></Title>
        )}
        <Row>{steps[activeStep].content}</Row>
        <Row onClickCapture={onClickCapture}>
          <div
            className={`py-4 my-5 d-flex justify-content-${
              customBackButton || backButtonTarget ? 'between' : 'end'
            } border-top`}
          >
            {customBackButton ??
              (!!backButtonTarget && (
                <Button
                  variant="link"
                  name="btnBack"
                  style={{ textDecoration: 'none' }}
                  onClick={() => navigate(backButtonTarget)}
                >
                  {'< Volver'}
                </Button>
              ))}
            <Stack
              direction="horizontal"
              gap={3}
            >
              <Button
                hidden={!activeStep}
                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
              >
                Anterior
              </Button>
              <Button
                hidden={activeStep > steps.length - 2}
                onClick={() => setActiveStep((prevStep) => prevStep + 1)}
              >
                Siguiente
              </Button>
            </Stack>
          </div>
        </Row>
      </Col>
      <Col
        onClickCapture={onClickCapture}
        xs={6}
        md={5}
        lg={4}
        xl={3}
        className="p-3 steplist-col"
      >
        <div className="p-5 stepList">
          {steps.map((step, index) => (
            <StepCheckBox
              key={`stepCheckbox-${index}`}
              step={index}
              title={step.title}
            />
          ))}

          {!hiddenProggressBar && (
            <>
              <div className="d-flex mt-5 progressBar">
                <Form.Label className="mb-0">Progreso</Form.Label>
                <Form.Text className="ms-auto">{completedPercentage}%</Form.Text>
              </div>
              <ProgressBar now={completedPercentage}></ProgressBar>
              <Form.Text>Carga de datos</Form.Text>
            </>
          )}
          {children}
        </div>
      </Col>
    </Row>
  )
}

interface CustomStepperProps {
  steps: boolean[]
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  nextDisabled?: boolean
}

const CustomStepper = memo(
  ({ steps, activeStep, setActiveStep, nextDisabled }: CustomStepperProps) => (
    <JoyStepper
      size="lg"
      sx={{
        display: steps.length > 1 ? 'flex' : 'none',
        padding: 3,
        borderBottom: globalStyles.border,
        height: 1.5 * globalStyles.navBarHeight,
        '--Step-connectorInset': 0,
        '--Step-gap': 0,
        '--Step-connectorThickness': '2px',
        '--StepIndicator-size': '2.1rem',
        [`& .${stepClasses.active}`]: {
          '--StepIndicator-size': '2.9rem',
          [`& .${stepIndicatorClasses.root}`]: {
            backgroundColor: 'var(--joy-palette-primary-solidBg)',
          },
        },
        [`& .${stepClasses.completed}`]: {
          '&::after': { backgroundColor: 'var(--joy-palette-primary-solidBg)' },
          [`& .${stepIndicatorClasses.root}`]: {
            backgroundColor: 'var(--joy-palette-primary-solidBg)',
          },
        },
      }}
    >
      {steps?.map((completed, index) => (
        <Step
          active={activeStep == index}
          completed={completed}
          key={`step-${index}`}
          indicator={
            <CustomStepIndicator
              completed={completed}
              index={index}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              nextDisabled={nextDisabled}
            />
          }
        ></Step>
      ))}
    </JoyStepper>
  ),
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.steps) === JSON.stringify(nextProps.steps) &&
    prevProps.activeStep === nextProps.activeStep &&
    prevProps.nextDisabled === nextProps.nextDisabled,
)

interface StepIndicatorProps {
  completed: boolean
  index: number
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  nextDisabled?: boolean
}

const CustomStepIndicator = memo(
  ({ completed, index, activeStep, setActiveStep, nextDisabled }: StepIndicatorProps) => (
    <StepIndicator
      variant="solid"
      onClick={() => !nextDisabled && setActiveStep(index)}
      sx={{ cursor: 'pointer' }}
    >
      {completed ? (
        <Check fontSize={activeStep == index ? 'medium' : 'small'} />
      ) : (
        <Typography level={activeStep == index ? 'title-lg-light' : 'body-md-light'}>{index + 1}</Typography>
      )}
    </StepIndicator>
  ),
  (prevProps, nextProps) =>
    prevProps.completed === nextProps.completed &&
    prevProps.activeStep === nextProps.activeStep &&
    prevProps.nextDisabled === nextProps.nextDisabled &&
    prevProps.index === nextProps.index,
)

interface ButtonProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  disabled?: boolean
}

const BackButton = memo(({ setActiveStep, disabled }: ButtonProps) => (
  <JoyButton
    startDecorator={<KeyboardBackspaceIcon />}
    variant="plain"
    size="lg"
    disabled={disabled}
    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
  >
    Anterior
  </JoyButton>
))

const NextButton = memo(({ setActiveStep, disabled }: ButtonProps) => (
  <JoyButton
    endDecorator={<KeyboardBackspaceIcon sx={{ transform: 'rotate(180deg)' }} />}
    variant="plain"
    size="lg"
    disabled={disabled}
    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
  >
    Siguiente
  </JoyButton>
))

interface CardProps {
  title: string
  subtitle: string
  content: React.ReactElement
  onClick: () => void
  selected?: boolean
  disabled?: boolean
}

interface CardSelectProps {
  title?: string
  cards: CardProps[]
}

export const CardSelect = ({ cards, title }: CardSelectProps) => {
  const styles: JoyStyles = {
    container: {
      padding: 1,
      border: globalStyles.border,
      borderRadius: globalStyles.card.borderRadius,
    },
    gridItem: {
      display: 'flex',
    },
  }

  return (
    <JoyStack gap={2}>
      {title && (
        <Typography
          level="h3"
          color="neutral"
        >
          {title}
        </Typography>
      )}
      <Grid
        id="container"
        container
        spacing={2}
        sx={styles.container}
      >
        {cards.map((card, index) => (
          <Grid
            xs={12}
            sm={6}
            md={4}
            key={`card-${index}`}
            sx={styles.gridItem}
          >
            <JoyButton
              variant="outlined"
              color="neutral"
              onClick={card.onClick}
              sx={{
                height: '100%',
                backgroundColor: card.selected ? 'white' : '',
                opacity: card.selected === undefined || card.selected ? '100%' : '40%',
              }}
              disabled={card.disabled}
            >
              <JoyStack
                sx={{
                  height: '100%',
                  padding: 1,
                  justifyItems: 'start',
                  alignItems: 'center',
                }}
                gap={1}
              >
                {card.content}
                <Typography
                  color="primary"
                  level="h4"
                  sx={{ textAlign: 'center' }}
                >
                  {card.title}
                </Typography>
                <Typography
                  level="title-sm"
                  sx={{ textAlign: 'center' }}
                >
                  {card.subtitle}
                </Typography>
              </JoyStack>
            </JoyButton>
          </Grid>
        ))}
      </Grid>
    </JoyStack>
  )
}

export const ProductCard = ({ cards, title }: CardSelectProps) => {
  const styles = {
    gridItem: {
      display: 'flex',
    },
  }

  return (
    <JoyStack gap={2}>
      {title && (
        <Typography
          level="h3"
          color="neutral"
        >
          {title}
        </Typography>
      )}
      <Grid
        id="container"
        container
        spacing={2}
      >
        {cards.map((card, index) => (
          <Grid
            xs={12}
            sm={6}
            md={3}
            key={`card-${index}`}
            sx={styles.gridItem}
          >
            <JoyButton
              variant="outlined"
              color="neutral"
              onClick={card.onClick}
              sx={{
                // height: '100%',
                backgroundColor: card.selected ? 'white' : '',
                opacity: card.selected === undefined || card.selected ? '100%' : '40%',
                padding: 2,
              }}
              disabled={card.disabled}
            >
              <JoyStack
                sx={{
                  height: '100%',
                  padding: 1,
                  justifyItems: 'start',
                  alignItems: 'left',
                }}
                gap={1}
              >
                {card.content}
                <Typography
                  level="title-lg-bold"
                  sx={{ textAlign: 'left', color: 'black', mt: 1 }}
                >
                  {card.title}
                </Typography>
                <Typography
                  level="title-sm"
                  sx={{ textAlign: 'left' }}
                >
                  {card.subtitle}
                </Typography>
              </JoyStack>
            </JoyButton>
          </Grid>
        ))}
      </Grid>
    </JoyStack>
  )
}

interface StepperAccordionSectionItem {
  title: string
  content: React.ReactElement
  hidden?: boolean
  isInvalid?: boolean
}

export interface StepperAccordionSection {
  title: string
  items: StepperAccordionSectionItem[]
  hidden?: boolean
}
interface StepperAccordionProps {
  sections: StepperAccordionSection[]
}

export const StepperAccordion = ({ sections }: StepperAccordionProps) => {
  // const [expandedIndex, _setExpandedIndex] = useState<number | false>(0)

  // const isExpandedAccordionInvalid =
  //   expandedIndex !== false && sections[expandedIndex]?.items.some(({ isInvalid }) => isInvalid)

  const styles: JoyStyles = {
    accordionGroup: {
      [`& .${accordionClasses.root}`]: {
        paddingY: 1,
      },
      [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
        // border: `1px solid ${
        //   isExpandedAccordionInvalid
        //     ? 'var(--joy-palette-danger-outlinedColor)'
        //     : 'var(--joy-palette-primary-plainColor)'
        // }`,
        borderRadius: globalStyles.card.borderRadius,
      },
    },
    accordionDetailsContent: { paddingInline: 0, paddingTop: 3 },
    accordionSummary: { paddingX: 3 },
    accordionItemContent: { padding: 3 },
  }

  return (
    <AccordionGroup
      size="lg"
      sx={styles.accordionGroup}
    >
      {sections
        .filter(({ items, hidden }) => !hidden && items.some(({ hidden }) => !hidden))
        .map(({ title, items }, index) => (
          <Accordion
            key={index}
            sx={
              items.some(({ isInvalid }) => isInvalid)
                ? { border: `1px solid var(--joy-palette-danger-outlinedColor)` }
                : {}
            }
            // expanded={expandedIndex === index}
            // onChange={() => setExpandedIndex((prev) => (prev === index ? false : index))}
          >
            <AccordionSummary sx={styles.accordionSummary}>
              <Typography
                level="h3"
                variant="plain"
              >
                {title}
              </Typography>
            </AccordionSummary>
            {/* {expandedIndex === index && ( */}
              <AccordionDetails slotProps={{ content: { sx: styles.accordionDetailsContent } }}>
                {items
                  .filter(({ hidden }) => !hidden)
                  .map((item, index) => (
                    <JoyStack key={index}>
                      <DividerTypography
                        title={item.title}
                        isInvalid={item.isInvalid}
                        color={item.isInvalid ? 'var(--joy-palette-danger-outlinedColor)' : undefined}
                      />
                      <JoyStack sx={styles.accordionItemContent}>{item.content}</JoyStack>
                    </JoyStack>
                  ))}
              </AccordionDetails>
            {/* )} */}
          </Accordion>
        ))}
    </AccordionGroup>
  )
}

const DividerTypography = memo(
  ({ title, color, isInvalid }: { title: string; color?: CSSProperties['color']; isInvalid?: boolean }) => (
    <Divider
      sx={{
        '--Divider-childPosition': `3%`,
        color: color ?? 'var(--joy-palette-primary-plainColor)',
        '&::before, &::after': {
          background: color ?? 'var(--joy-palette-primary-plainColor)',
        },
      }}
    >
      <Typography
        color={isInvalid ? 'danger' : 'primary'}
        level="h4"
      >
        {title}
      </Typography>
    </Divider>
  ),
)

interface GalleryItemProps {
  file: File | string | null
  onDelete?: () => void
}

export const GalleryItem = ({ file, onDelete }: GalleryItemProps) => (
  <AspectRatio
    objectFit="contain"
    sx={{ position: 'relative', backgroundColor: globalStyles.secondaryTextColor }}
  >
    <img
      draggable={false}
      src={typeof file === 'string' ? file : file instanceof File ? URL.createObjectURL(file) : ''}
      style={{ cursor: 'grabbing' }}
    />
    <OpenWithOutlined
      color="disabled"
      sx={{
        position: 'absolute',
        top: '8px',
        left: '8px',
        cursor: 'grabbing',
      }}
    />
    <JoyButton
      variant="plain"
      sx={{
        position: 'absolute',
        top: '5px',
        right: '6px',
        cursor: 'pointer',
      }}
      onClick={() => onDelete?.()}
    >
      <DeleteOutlined color="disabled" />
    </JoyButton>
  </AspectRatio>
)

export default Stepper

interface StepperTitleProps {
  title: string
}

const StepperTitle = memo(({ title }: StepperTitleProps) => (
  <JoyStack
    justifyContent="space-between"
    direction="row"
    alignItems="center"
    sx={{
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    }}
  >
    <Typography level="h3">{title}</Typography>
    <ModalClose
      size="lg"
      sx={{
        position: 'relative',
        right: 0,
        top: 0,
      }}
    />
  </JoyStack>
))
